import ClinicSessionTime from "./ClinicSessionTime";

const splitTheSessions = (sessions) => {
    return sessions.reduce((splitSessions, session, index) => {
        splitSessions[index <= 2 ? "shown" : "inMore"].push(session);
        return splitSessions;
    }, { shown: [], inMore: [] });
}

const ClinicListItemSessions = ({ sessions }) => {
    const { shown, inMore } = sessions.length <= 3 ? { shown: sessions, inMore: [] } : splitTheSessions(sessions);

    return (
        <>
            <ul className="list-unstyled mb-0">
                {shown.map((session) => (
                    <li key={session.clinic_session_id}>
                        <ClinicSessionTime session={session} />
                    </li>
                ))}
            </ul>
            {inMore.length > 0 && <div className="text-info">+ {inMore.length} more</div>}
        </>
    );
}

export default ClinicListItemSessions;