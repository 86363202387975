export default{
    // General
    "signup.label.backToLogin" : "Return to login",
    "signup.label.title"       : "Create new account",
    "signup.action.signup"     : "Sign up",

    // Sign up form
    "signup.signupForm.label.firstName"       : "First name",
    "signup.signupForm.label.lastName"        : "Last name",
    "signup.signupForm.label.email"           : "Email Address",
    "signup.signupForm.label.password"        : "Password",
    "signup.signupForm.label.passwordConfirm" : "Confirm password",

    "signup.signupForm.validation.firstName.required"    : "First name required",
    "signup.signupForm.validation.lastName.required"     : "Last name required",
    "signup.signupForm.validation.email.required"        : "Email required",
    "signup.signupForm.validation.email.email"           : "Invalid email",
    "signup.signupForm.validation.password.required"     : "Password required",
    "signup.signupForm.validation.password.space"        : "Password cannot have spaces",
    "signup.signupForm.validation.password.number"       : "Must contain 1 number",
    "signup.signupForm.validation.password.letter"       : "Must contain one letter",
    "signup.signupForm.validation.password.uppercase"    : "Must contain one uppercase",
    "signup.signupForm.validation.password.lenght"       : "Must be at least 6 characters long",
    "signup.signupForm.validation.passwordConfirm.match" : "Passwords must match",

    // Error
    "signup.error.title.server" : "Oops, an error has occurred !",
    "signup.error.server"       : "There was a problem. Please refresh the page and try again.",
    "signup.error.title.3010"   : "Email has already been used.",
    "signup.error.3010"         : "An account already exists with this email address.",
    "signup.error.action"       : "Back to sign up",

    // Loading
    "signup.loading.title"       : "Creation in progress!",
    "signup.loading.description" : "Once completed, you will receive a confirmation email.",

    // Confirmed
    "signup.confirmed.title"       : "Confirm your email",
    "signup.confirmed.description" : "We have sent an email, please confirm your account to log in.",
    "signup.confirmed.notReceived" : "You did not receive an email?",
    "signup.confirmed.resend"      : "Send Email",
}