import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import { OrganizationContext } from './OrganizationContext';
import withContext from '../helpers/withContexts';

/** @type {React.Context<Omit<MemberTypesContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const MemberTypesContext = createContext();
MemberTypesContext.displayName = 'MemberTypesContext';

class MemberTypesContextProvider extends React.Component{

    state = {
        memberTypes: false,
    }

    /**
     * Get all the Member Types within an organization
     * @param {string} organizationId The organization id we want to get the Member Types from
     * @returns {Promise<Array>}
     */
    getMemberTypes = (organizationId = this.props.OrganizationContext.federation.organisation_id) => {
        if(this.state.memberTypes){
            return Promise.resolve(this.state.memberTypes)
        }

        return API_SPORDLE.get(queryString.stringifyUrl({ url: `member-types`, query: { organisation_id: organizationId } }))
            .then((response) => {
                if(response.data.status){
                    this.setState(() => ({
                        memberTypes: response.data.member_types,
                    }))
                    return response.data.member_types;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get Member Type information
     * @param {string} memberTypeId
     * @returns {Promise}
     */
    getMemberType = (memberTypeId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `member-types/${memberTypeId}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.member_types[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <MemberTypesContext.Provider value={{ ...this }}>
                {this.props.children}
            </MemberTypesContext.Provider>
        )
    }
}

export default withContext(OrganizationContext)(MemberTypesContextProvider)
