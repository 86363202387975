import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';

import * as Sentry from "@sentry/react";

/** @type {React.Context<Omit<OrganizationContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>> & OrganizationContextProvider['state']} */
export const OrganizationContext = createContext();
OrganizationContext.displayName = 'OrganizationContext';

export const sessionStorageOrganizationId = 'organizationId';

// TODO: HARDCODED
const partnerIds = [
    // there are more branches but we only want to show these
    '1eb5d98e-ac53-658c-b920-06ba8f7ba4e0', // BC
    '1eb5d995-f3f1-6716-9826-06ba8f7ba4e0', // ALBERTA
    '1eb5d99a-2465-6a9c-af93-06ba8f7ba4e0', // SASKACHEWAN
    '1eb5d99c-6249-6140-b74f-06ba8f7ba4e0', // MANITOBA
    '1eb5d99f-1bd7-6ef2-a019-06ba8f7ba4e0', // NORTHWESTERN ONTARIO
    '1eb5d9a1-560f-671c-a1d8-06ba8f7ba4e0', // OHF
    '1eb5d9a3-8b8e-6134-aa88-06ba8f7ba4e0', // EASTERN ONTARIO
    '1eb5d9a5-7603-6b28-ab99-06ba8f7ba4e0', // QUEBEC
    '1eb5d9a7-b30a-615a-aa54-06ba8f7ba4e0', // NEW BRUNSWICK
    '1eb5d9ad-2c91-658c-8d16-06ba8f7ba4e0', // P.E.I
    '1eb5d9a9-df1c-6656-b377-06ba8f7ba4e0', // NOVA SCOTIA
    '1eb5d9af-210c-61ba-937a-06ba8f7ba4e0', // NEWFOUNDLAND
    '1eb5d9b1-6ec3-6852-b872-06ba8f7ba4e0', // NORTH
]

class OrganizationContextProvider extends React.Component{
    // static contextType = IdentityRolesContext;

    /*
    State will look like this:
    {
        abbreviation: "RE"
        active: "1"
        address: ""
        city: ""
        country_id: null
        created_at: "2020-11-23 09:46:44"
        fax: null
        identification_number: "",
        logo: {
            attachement_id: '123123',
            full_path: 'https://123123.com'
        },
        organisation_category: {
            organisation_category_id: "1eb19495-611e-65b8-9eec-e454e8be9b90",
            name: "Association / Club"
        }
        name: "Association / Club"
        organisation_category_id: "1eb19495-611e-65b8-9eec-e454e8be9b90"
        organisation_category_id: "5"
        organisation_email: null
        organisation_id: "1eb2d9ab-49d4-6fb8-866b-0ec110310a18"
        organisation_id_parent: "1eb1f975-74e6-6376-9b74-e454e80c8447"
        organisation_name: "Région Estrie"
        origin: "ADMIN"
        phone: ""
        province_id: ""
        short_url: "t3"
        updated_at: "2020-12-14 10:17:54"
        visible_on_website: "0"
        website: ""
        with_sub_organisation: "1"
        zip_code: ""
    }
    */

    // state = {
    //     "organisation_id": "1eb5d92c-30a2-6e40-b31f-a8a1592cf2a9",
    //     "organisation_name": "HOCKEY CANADA",
    //     "abbreviation": "HC",
    //     "identification_number": null,
    //     "organisation_code": "2",
    //     "short_url": null,
    //     "with_sub_organisation": "1",
    //     "visible_on_website": "0",
    //     "organisation_email": "info@hockeycanada.ca",
    //     "unit_number": "#201",
    //     "street_number": "151",
    //     "street": "Canada Olympic Road Southwest",
    //     "origin_address": null,
    //     "map_url": "https://maps.google.com/?q=151+Canada+Olympic+Rd+SW,+Calgary,+AB+T3H+4E5,+Canada&ftid=0x53716ea449f870db:0x5b3032c8108b1d4e",
    //     "place_id": null,
    //     "country_code": "CA",
    //     "province_code": "AB",
    //     "city": "Calgary",
    //     "zip_code": "T3H 4E5",
    //     "website": "https://www.hockeycanada.ca/",
    //     "phone": "+18887772192",
    //     "fax": null,
    //     "active": "1",
    //     "created_at": "2021-01-23T11:24:22Z",
    //     "updated_at": "2021-04-09T19:28:23Z",
    //     "external_id": "2",
    //     "breadcrumbs": [],
    //     "organisation_category": {
    //         "organisation_category_id": "1eb5d97a-ffc7-6bbe-a381-06ba8f7ba4e0",
    //         "name": "NSO",
    //         "i18n": {
    //             "en": {
    //                 "name": "NSO"
    //             },
    //             "fr": {
    //                 "name": "Fédération"
    //             }
    //         }
    //     },
    //     "organisation_parent": null,
    //     "logo": {
    //         "attachement_id": "8b0d8150-5f16-11eb-a431-0205722c772a",
    //         "full_path": "https://hcrstorage.s3.ca-central-1.amazonaws.com/UAT/HC%402x.31e033b6.png",
    //         "file_position": null
    //     },
    //     "postal_codes": [],
    //     "venues": [],
    //     "i18n": {
    //         "fr": {
    //             "name": "HOCKEY CANADA",
    //             "description": "",
    //             "active": "1"
    //         },
    //         "en": {
    //             "name": "HOCKEY CANADA",
    //             "description": "",
    //             "active": "1"
    //         }
    //     }
    // }

    state = {
    }

    federationId = '1eb5d92c-30a2-6e40-b31f-a8a1592cf2a9';

    /**
     * @type {?Array}
     * @description Cached organization tree
     */
    orgTree = null;

    /**
     * @type {?Array}
     * @description Cached organization branch
     */
    orgBranch = null;

    /**
     * @type {?Array}
     * @description Cached organization branch
     */
    orgRegion = null;

    /**
     * @type {?Array}
     * @description Cached organization categories
     */
    orgCategories = null;

    componentDidUpdate(){
        Sentry.setContext('organization', this.state.organisation_id ? {
            organizationId: this.state.organisation_id,
            organizationName: this.state.organisation_name,
        } : null);
        Sentry.setTag('organizationId', this.state.organisation_id);
    }

    // Temporary, needed for org search
    setFederation = () => {
        return this.getOrganization('1eb5d92c-30a2-6e40-b31f-a8a1592cf2a9')
            .then((fedData) => {
                return this.getOrganizationSettings('1eb5d92c-30a2-6e40-b31f-a8a1592cf2a9')
                    .then((settings) => {
                        this.setState((prevState) => ({ ...fedData, ...prevState, federation: { ...fedData, ...settings } }));
                        return fedData.organisation_id;
                    }, (error) => {
                        console.error(error.message)
                    })
            }, (error) => console.error(error.message))
    }

    /**
     * Set the new organization
     * @param {orgId} orgId
     * @returns {Promise}
     */
    setCurrentOrg = (orgId) => {
        return this.getOrganization(orgId)
            .then((orgData) => {
                return Promise.all([
                    this.getOrganizationSettings(orgData.organisation_id)
                        .catch((e) => {
                            console.error(e);
                            return [];
                        }),
                    this.getPlatformSettings({ code: "gender_selection" })
                        .catch((e) => {
                            console.error(e);
                            return [];
                        }),
                ])
                    .then(([ settings, platformSettings ]) => {
                        const index = settings.findIndex((orgSetting) => orgSetting.code === 'gender_selection');

                        if(index != -1){
                            const parsedData = JSON.parse(platformSettings[0].response);

                            settings[index].response = settings[index].response.map((response) => ({
                                value: response,
                                i18n: parsedData[response].i18n,
                            }))
                        }

                        this.setState((prevState) => ({ ...prevState, ...orgData, settings: (settings || []) }));
                        return orgData.organisation_id;
                    }, (error) => {
                        console.error(error.message)
                    })
            }, (error) => console.error(error.message))
    }

    /**
     * Get organisation tree with sub-organisation
     * @returns {Promise}
     */
    getOrganizationsTree = (orgId) => {
        return API_SPORDLE.get(`organisations/${orgId}/tree`)
            .then((response) => {
                if(response.data.status){
                    return response.data.organisations;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Get organization info
     * @param {string} organizationId
     * @returns {Promise}
     */
    getOrganization = (organizationId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `organisations/${organizationId}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.organisation[0];
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Get organization children
     * @param {string} organizationId
     * @returns {Promise}
     */
    getOrganizationChildren = (organizationId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `organisations/${organizationId}/children` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.organisations;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Get organization branch
     * @param {string} organizationId
     * @returns {Promise}
     */
    getOrganizationBranch = (organizationId = this.state.organisation_id, fromApi = false) => {
        // If we have the orgTree in cache AND we don't to update the orgTree from the api
        if(this.orgBranch?.organisation_id === this.state.organisation_id && !fromApi){
            return Promise.resolve(this.orgBranch);
        }

        return API_SPORDLE.get(queryString.stringifyUrl({ url: `organisations/${organizationId || this.state.organisation_id}/branch` }))
            .then((response) => {
                if(response.data.status){
                    return this.orgBranch = response.data.organisations;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Get organization branch
     * @param {string} organizationId
     * @returns {Promise}
     */
    getOrganizationRegion = (organizationId, fromApi = false) => {
        // If we have the orgTree in cache AND we don't to update the orgTree from the api
        if(this.orgRegion && !fromApi){
            return Promise.resolve(this.orgRegion);
        }

        return API_SPORDLE.get(queryString.stringifyUrl({ url: `organisations/${organizationId || this.state.organisation_id}/region` }))
            .then((response) => {
                if(response.data.status){
                    return this.orgRegion = response.data.organisations;
                }
                throw response.data.errors[0]
            }, serverError)
    }


    /**
     * Gets the Organization's Settings
     * @param {string} organizationId ID of the Organization we want to get the Settings from
     * @returns {Promise}
     */
    getOrganizationSettings = (organizationId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `organisations/${organizationId}/settings` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.settings;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Gets the organisations allowing for registration
     * @param {object} queryParams
     * @param {string} queryParams.organisation_id Federation. Required.
     * @param {string} queryParams.province_code Province code. Required.
     * @param {string} [queryParams.postal_code]
     * @param {organisation_category_id} [queryParams.organisation_category_id] Can be multi -> separated by commas
     * @returns {Promise.<object[]>}
     * @throws {object}
     */
    getOrgRegistrations = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `organisations/find`,
            query: queryParams,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.organisations;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Get organization info
     * @param {string} organizationId
     * @returns {Promise}
     */
    getOrganizationCategories = (organizationId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `organisations/${organizationId}/category`, query: queryParams }))
            .then((response) => {
                if(response.data.status){
                    this.orgCategories = response.data.categories;
                    return response.data.categories;
                }
                this.orgCategories = null;
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get all organizations for a category
     * @param {string} organizationId
     * @param {Array} categories
     * @returns {Promise<Array>}
     */
    getCategoryOrganizations = (organizationId, categories) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `organisations/${organizationId}/categories`, query: {
            organisation_category_id: categories,
        } }, {
            arrayFormat: 'comma',
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.organisations;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Does a partial update of the org
     * @param {string} [organizationId]
     * @param {object} values
     * @param {string} values.orgNameEn
     * @param {string} values.orgNameFr
     * @param {string} values.abbreviation
     * @param {string} values.email
     * @param {string} values.phone The phone number with the extension in it. ex: +16135550106#123
     * @param {string} values.website
     * @param {string} values.address
     * @param {string} values.address2
     * @param {string} values.city
     * @param {string} values.zipCode
     * @param {string} values.province
     * @param {string} values.country
     * @param {string} values.identification_number
     * @param {1|0} values.withSubOrganisation
     *
     * @returns {Promise}
     */
    updateOrganizationPartial = (values, organizationId = this.state.organisation_id) => {
        /**
         * Data for the api
         */
        const patchData = new URLSearchParams();
        /**
         * Data to update the context's state
         */
        const formattedData = {};

        // Building the data partial data for the api call
        for(const key in values){
            if(Object.hasOwnProperty.call(values, key)){
                switch (key){
                    case 'name':
                        formattedData.organisation_name = values[key] || '';
                        patchData.append('organisation_name', values[key] || '');
                        break;
                    case 'abbreviation':
                        formattedData.abbreviation = values[key] || '';
                        patchData.append('abbreviation', values[key] || '');
                        break;
                    case 'email':
                        formattedData.organisation_email = values[key] || '';
                        patchData.append('organisation_email', values[key] || '');
                        break;
                    case 'phone':
                        formattedData.phone = values[key] || '';
                        patchData.append('phone', values[key] || '');
                        break;
                    case 'website':
                        formattedData.website = values[key] || '';
                        patchData.append('website', values[key] || '');
                        break;
                    case 'streetNumber':
                        formattedData.street_number = values[key] || '';
                        patchData.append('street_number', values[key] || '');
                        break;
                    case 'street':
                        formattedData.street = values[key] || '';
                        patchData.append('street', values[key] || '');
                        break;
                    case 'address2':
                        formattedData.unit_number = values[key] || '';
                        patchData.append('unit_number', values[key] || '');
                        break;
                    case 'city':
                        formattedData.city = values[key] || '';
                        patchData.append('city', values[key] || '');
                        break;
                    case 'zipCode':
                        formattedData.zip_code = values[key] || '';
                        patchData.append('zip_code', values[key] || '');
                        break;
                    case 'province':
                        formattedData.province_code = values[key] || '';
                        patchData.append('province_code', values[key] || '');
                        break;
                    case 'country':
                        formattedData.country_code = values[key] || '';
                        patchData.append('country_code', values[key] || '');
                        break;
                    case 'mapsUrl':
                        formattedData.map_url = values[key] || '';
                        patchData.append('map_url', values[key] || '');
                        break;
                    case 'identification_number':
                        formattedData.identification_number = values[key] || '';
                        patchData.append('identification_number', values[key] || '');
                        break;
                    case 'withSubOrganisation':
                        formattedData.with_sub_organisation = (!!values[key] >>> 0).toString();// Will always results in 0 or 1 as string
                        patchData.append('with_sub_organisation', !!values[key] >>> 0);// Will always results in 0 or 1 as number
                        break;
                    default:
                        if(key.includes('i18n')){
                            if(typeof values[key] === 'object'){
                                formattedData.i18n = values[key];
                            }else{
                                patchData.append(key, values[key] || '');// Will always results in 0 or 1 as number
                            }
                        }
                        break;
                }
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/organisations/${organizationId}` }), patchData)
            .then((response) => {
                if(response.data.status){
                    this.setState((prevState) => ({ ...prevState, ...formattedData }));
                    return true;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    getMessage = (msgId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/messages/${msgId}`,
        }))
            .then((response) => {
                if(response.data.status){
                    return (response.data.messages || [])[0]
                }
                throw response.data.errors[0]
            })

    }

    /**
     * [GET] Get Member Fields
     * @param {Object} query See the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Fields/6bdcf031f2c4d533180bcfaf23afddbd|documentation}
     * @returns {Promise<Array>}
     */
    getMemberFields = () => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/member-fields`,
            query: { active: 1 },
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.member_fields;
                }
                throw response.data.errors[0]
            }, serverError)

    }


    /**
     * [GET] - Get the document types
     * @param {string} organizationId ID of the organization
     * @returns {Promise}
     */
    getDocumentTypes = (organizationId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/document-types`,
            query: {
                organisation_id: organizationId,
            },
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.document_types
                }
                throw response.data.errors[0]
            })
    }

    getPartners = (fedId) => {
        if(this.state.partners){
            return Promise.resolve(this.state.partners);
        }

        return this.getOrganizationCategories(fedId)
            .then(async(categories) => {
                // find branch
                const branchCategory = categories.find((_cat) => _cat.default_name === 'Member branches')
                const branchOrgs = await this.getCategoryOrganizations(fedId, [ branchCategory.category_id ]);
                const partners = branchOrgs.filter((org) => partnerIds.includes(org.organisation_id));

                this.setState((prev) => ({ ...prev, partners }));
                return partners;
            })
    }

    getPlatformSettings(query){
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `/utils/settings`, query: query }))
            .then((response) => {
                if(response.data.status){
                    return response.data.settings;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Determines if the french language is activated for this organization
     * @returns {boolean}
     */
    hasFrench = () => this.state.settings?.languages?.fr === 'on';

    /**
     * Determines if the english language is activated for this organization
     * @returns {boolean}
     */
    hasEnglish = () => this.state.settings?.languages?.en === 'on';

    render(){
        return (
            <OrganizationContext.Provider value={{
                ...this.state,
                ...this,
            }}
            >
                {this.props.children}
            </OrganizationContext.Provider>
        );
    }
}

export default OrganizationContextProvider;
