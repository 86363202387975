import { LOCALES } from "../locales";

// Global
import global from "./en-CA/global"
import views from "./en-CA/views"
import components from "./en-CA/components";

// Auth
import login from "./en-CA/authentication/login";
import forgotPassword from "./en-CA/authentication/forgotPassword";
import recovery from "./en-CA/authentication/recovery";
import signup from "./en-CA/authentication/signup";
import confirmAccount from "./en-CA/authentication/confirm-account";

// Dashboard
import home from "./en-CA/dashboard/home";

export default{
    [LOCALES.ENGLISH] : {
        ...global,
        ...components,

        ...login,
        ...signup,
        ...confirmAccount,
        ...forgotPassword,
        ...recovery,
        ...home,
        ...views,
    },
}