import { stringifyUrl } from "query-string";
import API_ACCOUNT from "../API-Account";
import { serverError } from "../CancellableAPI";
import { JSONParse } from "../../helpers/jsonHelper";

/**
 * Get the clients
 */
export function getClients(query){
    return API_ACCOUNT.get(stringifyUrl({
        url: `/clients`,
        query,
    }))
        .then((response) => {
            if(response.data.status){
                return formatGetClients(response.data);
            }
            throw response.data.errors;
        }, serverError)
}

export const formatGetClients = (data) => {
    // Lite Format
    const clients = data.clients || [];
    const api_providers = data.api_providers || {};

    // Build Resources
    return clients.map((client) => {
        if(api_providers[client.api_provider_id]){
            client.api_provider = { ...api_providers[client.api_provider_id] };
            client.api_provider.api_provider_id = client.api_provider_id;
            client.api_provider.metadata = JSONParse(api_providers[client.api_provider_id].metadata, null);
        }

        client.metadata = JSONParse(client.metadata, null);

        // Resources ID Cleanup
        delete client.api_provider_id;

        return client;
    });
}

/**
 * POST a client
 */
export function postClient(values){
    const params = new URLSearchParams(values);

    return API_ACCOUNT.post(`/clients`, params)
        .then((response) => {
            if(response.data.status){
                return true;
            }
            throw response.data.errors;
        }, serverError)
}

/**
 * PATCH a client
 */
export function patchClient(clientId, values){
    const params = new URLSearchParams(values);

    return API_ACCOUNT.patch(`/clients/${clientId}`, params)
        .then((response) => {
            if(response.data.status){
                return true;
            }
            throw response.data.errors;
        }, serverError)
}

/**
 * DELETE a client
 */
export function deleteClient(clientId){
    return API_ACCOUNT.delete(`/clients/${clientId}`)
        .then((response) => {
            if(response.data.status){
                return true;
            }
            throw response.data.errors;
        }, serverError)
}
