export default{
    "home.noData.hi"            : "Hello",
    "home.noData.description.1" : "Welcome to the tournament registration platform!",
    "home.noData.description.2" : "You can get started with selecting a HCR team via the tournaments tab",
    "home.noData.action"        : "Register my team",

    "home.teams.title"                      : "My teams",
    "home.teams.seeAll"                     : "See all",
    "home.teams.button.register"            : "Register",
    "home.teams.link.avaialbleRegistration" : "Registration available",

    "home.notification.title.notifications" : "Notifications",
    "home.notification.title.registrations" : "Inscriptions",
    "home.notification.readyToPay"          : "Registration is ready to pay",
    "home.notification.confirmed"           : "Registration is confirmed",
    "home.notification.canceled"            : "Registration is canceled",
    "home.notification.sent"                : "Registration is sent",
    "home.notification.price"               : "Price",
    "home.notification.cancel"              : "To cancel a registration, please contact the tournament directly",
    "home.notification.markAsRead"          : "Mark as read",
    "home.notification.markedAsRead"        : "Notification marked as read!",
}