import { useEffect, useRef } from 'react';
import { stringBuilder } from "@spordle/helpers";
import gsap from "gsap";

/**
* @param {React.ComponentType} [tag='div'] The desired htmlElement of the container.
* @param {string} [className]
* @param {string} [timeline] gsap timeline used for custom animations (default is a basic fadeIn animation)
* @param {Object} [intersecOptions={rootMargin: '-100px'}] IntersectionObserver's options https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
*/
const Scrollspy = ({
    tag: Tag = 'div',
    className,
    timeline,
    intersecOptions = { rootMargin: '-100px' },
    ...attributes
}) => {
    const intersectRef = useRef();

    useEffect(() => {
        let tl = null;
        // if needed, we can always send a custom timeline in props
        if(timeline){
            tl = timeline
        }else{
            // default fadeIn animation
            tl = gsap.timeline({ paused: true })
                .from(intersectRef.current, { duration: 1, y: -10, opacity: 0, ease: 'power4' });
        }

        const observer = new IntersectionObserver(([ entry ]) => {
            if(entry.isIntersecting){
                tl.play()
            }else{
                tl.reverse()
            }
        }, intersecOptions);

        if(intersectRef.current)observer.observe(intersectRef.current)
        return () => {
            if(intersectRef.current)
                observer.disconnect()
        }
    }, []);

    return (
        <Tag ref={intersectRef} {...attributes} className={stringBuilder(className)} />
    );
};

export default Scrollspy;