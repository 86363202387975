import { generatePath, matchPath } from "react-router-dom";
import homeRoutes from "../routes/homeRoutes";

/**
 * Gets the i18n path route
 * @param {object|string} path
 * @param {string} defaultPath
 * @returns {string}
 */
export function getI18nRoutePath(path, defaultPath){
    if(typeof path === "string"){
        return path;
    }

    if(!defaultPath){
        throw new Error('You must define a defaultPath. Received: ' + defaultPath);
    }

    const finalI18nPaths = defaultPath.split('/').filter((p) => !!p);

    for(const langKey in path){
        if(Object.hasOwnProperty.call(path, langKey)){
            const i18nPath = path[langKey];
            const i18nPaths = i18nPath.split('/').filter((p) => !!p);
            if(i18nPaths.length !== finalI18nPaths.length){ // Must have matching length
                throw new Error('All i18n path routes must have the same url segment amount i.e. /segment1/segment2/segment3 etc.');
            }

            for(let index = 0; index < i18nPaths.length; index++){
                const path = i18nPaths[index];
                if(!finalI18nPaths[index].includes(path))
                    finalI18nPaths[index] += `|${path}`;
            }
        }
    }

    return finalI18nPaths.reduce((finalPath, i18nPath) => {
        if(i18nPath.split('|').length <= 1){ // Has only one possible value
            return finalPath += `/${i18nPath}`;
        }
        return finalPath += `/(${i18nPath})`;
    }, '');
}

/**
 * Get a i18n link
 * @param {object|string} path
 * @param {string} lang
 * @param {string} defaultPath
 * @param {object} params
 * @returns {string}
 */
export function getI18nLink(path, lang, defaultPath, params){
    if(typeof path === "string"){
        return generatePath(path, params);
    }
    return generatePath(path[lang] || defaultPath, params);
}

/**
 * Get the domain for the oposite domain
 * @param {string} targetlang
 * @returns {string} The new domain
 */
export function getLangSwitcherLink(targetlang){
    let baseUrl = '';
    if(process.env.REACT_APP_ENVIRONMENT === 'prod'){ // This url changes only in prod
        switch (targetlang){
            case 'fr':
                baseUrl = 'https://inscription.hockeycanada.ca';
                break;
            case 'en':
            default:
                baseUrl = 'https://register.hockeycanada.ca';
                break;
        }
    }else{
        baseUrl = window.location.origin;
    }

    for(let index = 0; index < homeRoutes.length; index++){
        const route = homeRoutes[index];
        if(!route.redirect){
            const match = matchPath(
                location.pathname,
                {
                    ...route,
                    path: getI18nRoutePath(route.path, route.defaultPath),
                },
            );
            if(match){ // Is the matching route
                return baseUrl + getI18nLink(route.path, targetlang, route.defaultPath, match.params);
            }
        }
    }

    return baseUrl;
}