import { useSpordleTable } from "@spordle/datatables";
import Translate from "@spordle/intl-elements";
import "react-datetime/css/react-datetime.css";
import {
    CustomInput, Label
} from "reactstrap";

const ClinicListFilterStatusFormat = (props) => {
    const spordleTable = useSpordleTable();

    const isDisabled = () => spordleTable.state.loadingState === 'loading' || spordleTable.state.loadingState === 'lazy' || props.fieldsDisabled

    return (
        <>
            <fieldset className="mt-3 border-bottom pb-3 mb-3">
                <div className="d-flex">
                    <div className="font-weight-bold mb-1"><Translate id='clinics.filters.status' /></div>
                    {spordleTable.getFilters().status?.length > 0 && // if status is filtered
                        <button onClick={() => spordleTable.filterChange('status', [])} className="ml-auto reset-btn text-muted" type="button" id={spordleTable.generateId('filters', 'status', 'clear')}>
                            <Translate id='misc.clear' /><i className="mdi mdi-close align-middle ml-1" />
                        </button>
                    }
                </div>
                <div className="mr-4 mr-md-0 mb-1">
                    <Label for={spordleTable.generateId('filters', 'status', 'openPublic')} check>
                        <CustomInput
                            key={spordleTable.getFilters().status}
                            defaultChecked={spordleTable.getFilters().status.includes('OPEN_PUBLIC_SHOW_LIST')}
                            disabled={isDisabled()}
                            id={spordleTable.generateId('filters', 'status', 'openPublic')}
                            name="status" value='OPEN_PUBLIC_SHOW_LIST' type='checkbox'
                            label={<Translate id='clinics.status.OPEN_PUBLIC_SHOW_LIST' />}
                            onChange={(e) => {
                                const newStatus = e.target.checked ? [ ...spordleTable.getFilters().status, 'OPEN_PUBLIC_SHOW_LIST' ] : spordleTable.getFilters().status.filter((status) => status !== 'OPEN_PUBLIC_SHOW_LIST')
                                spordleTable.filterChange('status', newStatus)
                            }}
                        />
                    </Label>
                </div>
                <div className="mr-4 mr-md-0 mb-1">
                    <Label for={spordleTable.generateId('filters', 'status', 'soldOut')} check>
                        <CustomInput
                            key={spordleTable.getFilters().status}
                            defaultChecked={spordleTable.getFilters().status.includes('OPEN_PUBLIC_SIGNUP')}
                            disabled={isDisabled()}
                            id={spordleTable.generateId('filters', 'status', 'soldOut')}
                            name="status" value='OPEN_PUBLIC_SIGNUP' type='checkbox'
                            label={<Translate id='clinics.status.OPEN_PUBLIC_SIGNUP' />}
                            onChange={(e) => {
                                const newStatus = e.target.checked ? [ ...spordleTable.getFilters().status, 'OPEN_PUBLIC_SIGNUP' ] : spordleTable.getFilters().status.filter((status) => status !== 'OPEN_PUBLIC_SIGNUP')
                                spordleTable.filterChange('status', newStatus)
                            }}
                        />
                    </Label>
                </div>
            </fieldset>
            <fieldset className="mt-3 border-bottom pb-3">
                <div className="d-flex">
                    <div className="font-weight-bold mb-2"><Translate id='clinics.filters.sessionFormat' /></div>
                    {spordleTable.getFilters().sessionFormat?.length > 0 && // if sessionFormat is filtered
                        <button onClick={() => spordleTable.filterChange('sessionFormat', [])} className="ml-auto reset-btn text-muted" type="button" id={spordleTable.generateId('filters', 'sessionFormat', 'clear')}>
                            <Translate id='misc.clear' /><i className="mdi mdi-close align-middle ml-1" />
                        </button>
                    }
                </div>
                <div className="mr-4 mr-md-0 mb-1">
                    <Label for={spordleTable.generateId('filters', 'sessionFormat', 'onlineLive')} check>
                        <CustomInput
                            key={spordleTable.getFilters().sessionFormat}
                            defaultChecked={spordleTable.getFilters().sessionFormat.includes('ONLINE_LIVE')}
                            disabled={isDisabled()}
                            id={spordleTable.generateId('filters', 'sessionFormat', 'onlineLive')}
                            name="sessionFormat" value='ONLINE_LIVE' type='checkbox'
                            label={<><i className='mdi mdi-access-point' /> <Translate id='clinics.location.ONLINE_LIVE' /></>}
                            onChange={(e) => {
                                const newFormat = e.target.checked ? [ ...spordleTable.getFilters().sessionFormat, 'ONLINE_LIVE' ] : spordleTable.getFilters().sessionFormat.filter((format) => format !== 'ONLINE_LIVE')
                                spordleTable.filterChange('sessionFormat', newFormat)
                            }}
                        />
                    </Label>
                </div>
                <div className="mr-4 mr-md-0 mb-1">
                    <Label for={spordleTable.generateId('filters', 'sessionFormat', 'online')} check>
                        <CustomInput
                            key={spordleTable.getFilters().sessionFormat}
                            defaultChecked={spordleTable.getFilters().sessionFormat.includes('ONLINE')}
                            disabled={isDisabled()}
                            id={spordleTable.generateId('filters', 'sessionFormat', 'online')}
                            name="sessionFormat" value='ONLINE' type='checkbox'
                            label={<><i className='mdi mdi-play' /> <Translate id='clinics.location.ONLINE' /></>}
                            onChange={(e) => {
                                const newFormat = e.target.checked ? [ ...spordleTable.getFilters().sessionFormat, 'ONLINE' ] : spordleTable.getFilters().sessionFormat.filter((format) => format !== 'ONLINE')
                                spordleTable.filterChange('sessionFormat', newFormat)
                            }}
                        />
                    </Label>
                </div>
                <div>
                    <Label for={spordleTable.generateId('filters', 'sessionFormat', 'onSite')} check>
                        <CustomInput
                            key={spordleTable.getFilters().sessionFormat}
                            defaultChecked={spordleTable.getFilters().sessionFormat.includes('ONSITE')}
                            disabled={isDisabled()}
                            id={spordleTable.generateId('filters', 'sessionFormat', 'onSite')}
                            name="sessionFormat" value='ONSITE' type='checkbox'
                            label={<><i className='mdi mdi-map-marker' /> <Translate id='clinics.location.ONSITE' /></>}
                            onChange={(e) => {
                                const newFormat = e.target.checked ? [ ...spordleTable.getFilters().sessionFormat, 'ONSITE' ] : spordleTable.getFilters().sessionFormat.filter((format) => format !== 'ONSITE')
                                spordleTable.filterChange('sessionFormat', newFormat)
                            }}
                        />
                    </Label>
                </div>
            </fieldset>
        </>
    )
}
export default ClinicListFilterStatusFormat;