import SpordleTableProvider, { SpordleTableView } from "@spordle/datatables";
import Translate from "@spordle/intl-elements";
import moment from "moment";
import { useContext, useState } from "react";
import Skeleton from "react-loading-skeleton";
import ReactTooltip from "react-tooltip";
import {
    Card, CardBody,
    CardFooter, CardHeader, Col,
    Container, Row
} from "reactstrap";
import { AxiosIsCancelled } from "../../api/CancellableAPI";
import SearchImage from '../../assets/images/SearchImage.js';
import SassVars from "../../assets/scss/custom/components/_header.module.scss";
import { useQuery } from "../../components/customHooks/useQuery";
import SelectOrganisation from "../../components/SelectOrganisation";
import Sticky from "../../components/Sticky";
import { ClinicsContext } from '../../contexts/ClinicsContext';
import { I18nContext } from "../../contexts/I18nContext";
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { PeriodsContext } from '../../contexts/PeriodsContext';
import { getLocalStorageItem, setLocalStorageItem } from "../../helpers/browserStorage";
import { displayI18n } from "../../helpers/i18nHelper";
import ClinicListFilter from './components/ClinicListFilter';
import ClinicListItem from './components/ClinicListItem';
import {  destroyHCRSlots, initializeAdSlots } from "../../components/advertisement/adsHelper.js";
import { useEffect } from "react";
import AdSlot from "../../components/advertisement/AdSlot.jsx";

const ClinicList = (props) => {
    const [ adIsReady, setAdIsReady ] = useState(false);
    const query = useQuery();

    const clinicsContext = useContext(ClinicsContext);
    const organizationContext = useContext(OrganizationContext);
    const periodsContext = useContext(PeriodsContext);
    const i18nContext = useContext(I18nContext)

    const [ initialPeriodId, setInitialPeriodId ] = useState('');
    const [ periods, setPeriods ] = useState(false);

    const [ initialBranchOrgId, setInitialBranchOrgId ] = useState('');
    const [ branchOrgs, setBranchOrgs ] = useState(false);
    const [ orgCategories, setOrgCategories ] = useState(false);

    // Pagination plugin exists ()
    const getInitFilters = () => {
        const orgQuery = query.get("org");
        const previousFilters = getLocalStorageItem('clinicsFilters');

        if(orgQuery){
            props.history.replace(props.location.pathname);
        }

        if(previousFilters){
            const localFilters = JSON.parse(previousFilters);

            if(orgQuery){
                localFilters.organizationId = orgQuery;
            }

            return {
                ...localFilters,
                startDate: localFilters.startDate ? moment(localFilters.startDate) : '',
                endDate: localFilters.endDate ? moment(localFilters.endDate) : '',
            }
        }


        return {
            organizationId: orgQuery || '',
            subOrganizationId: '',
            clinicReference: '',
            category: '',
            city: '',
            qualification: '',
            periodId: '',
            startDate: '',
            endDate: '',
            status: [],
            sessionFormat: [],
        }
    }

    const resetFilters = (spordleTable) => {
        // nothing triggers a call here because the period select (with an unknown default value) will change on its own and trigger a call
        spordleTable.filterChange('category', '', false)
        spordleTable.filterChange('qualification', '', false)
        // spordleTable.filterChange('periodId', initialPeriodId, false)
        spordleTable.filterChange('startDate', '', false)
        spordleTable.filterChange('endDate', '', false)
        spordleTable.filterChange('city', '', false)
        spordleTable.filterChange('status', [], false)
        spordleTable.filterChange('sessionFormat', [], false)
    }

    const shouldResetClinicReference = (spordleTable) => {
        return spordleTable.getFilters().category !== '' ||
        spordleTable.getFilters().qualification !== '' ||
        spordleTable.getFilters().city !== '' ||
        spordleTable.getFilters().startDate !== '' ||
        spordleTable.getFilters().endDate !== '' ||
        spordleTable.getFilters().status.length !== 0 ||
        spordleTable.getFilters().sessionFormat.length !== 0
    }

    const SkeletonCard = () => {
        return (
            <Card>
                <CardHeader>
                    <div style={{ height: 40 }} />
                </CardHeader>
                <CardBody>
                    <div className="mb-3">
                        <Skeleton className="d-block mb-1" height={15} width={75} />
                        <Skeleton className="d-block" height={20} width={120} />
                    </div>
                    <div className="mb-3">
                        <Skeleton className="mr-1" height={20} width={20} />
                        <Skeleton height={20} width={20} />
                    </div>
                    <Skeleton className="d-block mb-1" height={15} width={100} />
                    <Skeleton className="d-block mb-3" height={20} width={140} />
                    <Skeleton className="d-block mb-1" height={15} width={100} />
                    <Skeleton className="d-block mb-1" height={20} width={130} />
                </CardBody>
                <CardFooter className="d-flex bg-white justify-content-between">
                    <Skeleton height={20} width={120} />
                    <Skeleton height={20} width={40} />
                </CardFooter>
            </Card>
        )
    }

    useEffect(() => {
        initializeAdSlots(i18nContext.getGenericLocale());
        setAdIsReady(true);

        return () => {
            destroyHCRSlots();
        }
    }, []);

    return (
        <main>
            <ReactTooltip id="clinicItemName" />
            {organizationContext.state.organisation_id && organizationContext.state.federation?.organisation_id &&
                <SpordleTableProvider
                    tableHover bordered striped
                    clickable
                    id='clinicSearch'
                    viewMode='GRID'
                    dataIndex='clinic_id'
                    defaultAdditionalData={{
                        branchOrgs: Array.isArray(branchOrgs) ? branchOrgs : [],
                    }}
                    gridConfig={{
                        row: {
                            form: true,
                        },
                        col: {
                            sm: 12,
                            md: 6,
                            xl: 4,
                            className: 'mb-3 clinic-list-item-col',
                        },
                        gridRender: (clinic) => (
                            clinic.clinic_id == "AD" ?
                            <ClinicAdCard />
                            :
                            <ClinicListItem
                                key={clinic.clinic_id}
                                clinic={clinic}
                            />
                        ),
                    }}
                    emptyLayout={
                        <div className='text-center'>
                            <SearchImage width={250} />
                            <div>
                                <div className="h2"><Translate id='clinics.table.emptyLayout.noResult' /></div>
                                <div><Translate id='clinics.table.emptyLayout.noResultTip' /></div>
                            </div>
                        </div>
                    }
                    desktopWhen='md'
                    pagination={12} // makes 4 rows in lg (3 per row), and 6 rows in md (2 per row)
                    paginationMessage='clinics.table.paginationMessage'
                    initFilter={{
                        ...getInitFilters(),
                    }}
                    loadingMessage={
                        <Row form>
                            <Col sm={12} lg={6} xl={4} className='mb-3'>
                                <SkeletonCard />
                            </Col>
                            <Col sm={12} lg={6} xl={4} className='mb-3'>
                                <SkeletonCard />
                            </Col>
                            <Col sm={12} lg={6} xl={4} className='mb-3'>
                                <SkeletonCard />
                            </Col>
                        </Row>
                    }
                    loadData={(from, data, spordleTable) => {
                        switch (from){
                            case 'CDM':
                                // get the periods & branches here so we can initialize the org and period filters
                                Promise.all([
                                    // get all the categories
                                    organizationContext.getOrganizationCategories(organizationContext.state.federation.organisation_id, { show_clinic: 1 }),

                                    // get the periods
                                    periodsContext.getOrganizationPeriods(organizationContext.state.federation.organisation_id),
                                ])
                                    .then(async(promises) => {
                                        // find branch
                                        const _branchCategory = promises[0].find((_cat) => _cat.default_name === 'Member branches')

                                        // No need to filter categories, we send show_clinic to the API,
                                        // it will return only the ones we want to display
                                        if(_branchCategory){
                                            // get branch orgs
                                            const _branchOrgs = await organizationContext.getCategoryOrganizations(organizationContext.state.federation.organisation_id, [ _branchCategory.category_id ])

                                            // sort the branches by alphabetical order
                                            _branchOrgs.sort((orgA, orgB) => {
                                                const valueA = displayI18n('name', orgA.i18n, orgA.organisation_name, i18nContext.getGenericLocale())
                                                const valueB = displayI18n('name', orgB.i18n, orgB.organisation_name, i18nContext.getGenericLocale())
                                                return new Intl.Collator(i18nContext.getGenericLocale(), { sensitivity: 'base' }).compare(valueA, valueB);
                                            });

                                            const branchFilter = _branchOrgs.find((bO) => bO.organisation_id === data.filters.organizationId)?.organisation_id;
                                            const initialOrgId = branchFilter || _branchOrgs[0].organisation_id;

                                            setInitialBranchOrgId(branchFilter || _branchOrgs[0].organisation_id);
                                            setBranchOrgs(_branchOrgs);
                                            setOrgCategories(promises[0]);

                                            // Set additionnal data because gridItems do not rerender when parent rerenders
                                            // therefore the set state of branch orgs as a prop do not work
                                            spordleTable.setAdditionalData({ branchOrgs: _branchOrgs });


                                            spordleTable.filterChange('organizationId', initialOrgId, false);
                                        }

                                        // PERIODS ///////////////
                                        // Find the initial period
                                        const initialPeriodId = promises[1].find((p) => p.active == 1 && p.current == 1)?.period_id;
                                        setInitialPeriodId(initialPeriodId);
                                        spordleTable.filterChange('periodId', initialPeriodId || 'ALL', true);
                                        setPeriods(promises[1]);

                                    })
                                    .catch((e) => {
                                        if(!AxiosIsCancelled(e.message)){
                                            console.error(e.message);
                                        }
                                    })
                                break;
                            case 'FILTER':
                                spordleTable.setLoading();

                                setLocalStorageItem('clinicsFilters', JSON.stringify(data.filters));
                                if(data.filters.clinicReference){
                                    return clinicsContext.getClinics({
                                        clinic_reference: data.filters.clinicReference || '',
                                        organisation_id: data.filters.subOrganizationId || data.filters.organizationId || organizationContext.state.organisation_id,
                                        period_id: data.filters.periodId === 'ALL' ? '' : data.filters.periodId,
                                    });
                                }
                                return clinicsContext.getClinics({
                                    clinic_reference: '',
                                    city: data.filters.city,
                                    organisation_id: data.filters.subOrganizationId || data.filters.organizationId || organizationContext.state.organisation_id,
                                    qualification_category_id: data.filters.category,
                                    qualification_id: data.filters.qualification,
                                    period_id: data.filters.periodId === 'ALL' ? '' : data.filters.periodId,
                                    'start_date.gte': moment.isMoment(data.filters.startDate) ? moment(data.filters.startDate).toISOString() : null,
                                    'end_date.lte': moment.isMoment(data.filters.endDate) ? moment(data.filters.endDate).toISOString() : null,
                                    active_status: data.filters.status.length > 0 ? data.filters.status : [ "OPEN_PUBLIC_SHOW_LIST", "OPEN_PUBLIC_SIGNUP" ],
                                    session_format: data.filters.sessionFormat,
                                })
                                    .then((clinics) => {
                                        return clinics.reduce((tableClinics, clinic) => {
                                            tableClinics.push(clinic);

                                            if(tableClinics.length == 2) {
                                                tableClinics.push({
                                                    clinic_id: 'AD',
                                                })
                                            }

                                            return tableClinics;
                                        }, []);
                                    });
                            default:
                                break;
                        }
                    }}
                >
                    {(spordleTable) => (
                        <>
                            <Sticky top={SassVars.navbarHeight} media="md">
                                <div className="hero bg-primary" style={{ backgroundImage: "url(https://spordle-frontend-assets.s3.ca-central-1.amazonaws.com/hcrsite/clinicHero_desktop.jpg)" }}>
                                    <Container>
                                        <div className="d-lg-flex">
                                            <div className="hero-content clinicList-hero-content mb-2">
                                                <img className="hero-logo" src={"https://spordle-frontend-assets.s3.ca-central-1.amazonaws.com/hcrsite/HC@2x.png"} alt="Hockey Canada" />
                                                <h1 className="hero-text">
                                                    <span className="hero-text-small font-title-small"><Translate id="clinics.hero.find.1" /> </span>
                                                    <span className="hero-text-big font-title"><Translate id="clinics.hero.find.2" /></span>
                                                </h1>
                                            </div>
                                            <div className="flex-grow-1 register-search">
                                                <SelectOrganisation
                                                    branchOrgs={branchOrgs}
                                                    initialBranchOrgId={initialBranchOrgId}
                                                    orgCategories={orgCategories}
                                                    resetFilters={() => resetFilters(spordleTable)}
                                                />
                                            </div>
                                        </div>
                                    </Container>
                                </div>
                            </Sticky>
                            <Container className="pb-5 pt-4 container-lg">
                                <AdSlot
                                    adIsReady={adIsReady}
                                    adId={i18nContext.getGenericLocale() == "fr" ? "1699036793050" : "1699036745568"}
                                    className="d-flex justify-content-center mb-4"
                                    minWidth={320}
                                    minHeight={50}
                                />
                                <Row>
                                    <Col lg="3">
                                        <ClinicListFilter
                                            periods={periods}
                                            initialPeriodId={initialPeriodId}
                                            resetFilters={resetFilters}
                                            shouldResetClinicReference={shouldResetClinicReference}
                                        />
                                    </Col>
                                    <Col lg="9">
                                        <SpordleTableView />
                                    </Col>
                                </Row>
                                <AdSlot
                                    adIsReady={adIsReady}
                                    adId={i18nContext.getGenericLocale() == "fr" ? "1699036889181" : "1699036854795"}
                                    className="d-flex justify-content-center mt-5"
                                    minWidth={320}
                                    minHeight={50}
                                />
                            </Container>
                        </>
                    )}
                </SpordleTableProvider>
            }
        </main>
    );
}

const ClinicAdCard = () => {
    const { getGenericLocale } = useContext(I18nContext);

    useEffect(() => {
        const googletag = window.googletag;

        if(googletag) {
            googletag.pubads().refresh();
        }
    }, []);

    return (
        <div style={{ minWidth: 300 }} className="card card-shadow rounded h-100 d-flex justify-content-center align-items-center py-3">
            <AdSlot className="d-flex justify-content-center" adIsReady={true} adId={getGenericLocale() == "fr" ? "1699036608579" : "1699036561080"} />
        </div>
    )
}

export default ClinicList;