export default{
    "register.title.find"         : "Trouver une",
    "register.title.organisation" : "Organisation",

    "register.search.result.exactMatch"         : "Correspondance parfaite",
    "register.search.result.distance"           : "~{distance}km de distance",
    "register.search.result.distanceNoInfo"     : "Aucune information sur la distance",
    "register.search.result.registrationClosed" : "Contactez-nous",
    "register.search.result.registrationOpen"   : "Inscription ouverte",
    "register.search.result.minAge"             : "{age} ans et plus",
    "register.search.result.maxAge"             : "{age} ans et moins",
    "register.search.result.minMaxAge"          : "{minAge} à {maxAge} ans",

    "register.search.result.empty.title"    : "Aucune organisation trouvée",
    "register.search.result.empty.subtitle" : "Nous n'avons pas trouvé d'organisation avec les paramètres envoyés.",

    "register.search.postalCode.placeholder"   : "Code Postal",
    "register.search.provinceCode.placeholder" : "Choisir votre province",
    "register.search.result.init.title"        : "Recherche",
    "register.search.result.init.subtitle"     : "Utilisez la recherche de haut de page pour trouver votre organisation.",

    "register.search.result.helper" : "Vous pouvez contacter les organisations ci-dessous si vous avez besoin d'aide pour sélectionner la bonne organisation. Prenez-note que les inscriptions devront être confirmées par l'organisation avant d'être officielle.",
}