import { useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Badge
} from "reactstrap";
import { displayI18n, DisplayI18n } from '../../../helpers/i18nHelper';
import Translate, { CurrencyFormat } from '@spordle/intl-elements';
import { useTooltip } from '../../../helpers/useTooltip';
import { I18nContext } from '../../../contexts/I18nContext';
import StatusBadge from '../../../components/badges/StatusBadge';
import ClinicListItemSessions from "./ClinicListItemSessions";
import ClinicSessionTime from './ClinicSessionTime';
import { AuthContext } from '../../../contexts/AuthContext';
import { useMemo } from 'react';
import { useSpordleTable } from '@spordle/datatables';

const ClinicListItem = ({ clinic }) => {
    const refProps = useTooltip();
    const { pageUrl } = useContext(AuthContext);
    const { getGenericLocale } = useContext(I18nContext);
    const { getFilters, getAdditionalData } = useSpordleTable();
    const branchId = getFilters().organizationId;
    const branchOrgs = getAdditionalData()?.branchOrgs || [];

    const selectedBranch = useMemo(() => {
        return branchOrgs.find(({ organisation_id }) => organisation_id === branchId);
    }, [ branchOrgs.length, branchId ]);

    // first session in time
    const firstSession = clinic.clinic_sessions.sort((a, b) => {
        return new Date(a.start_date) - new Date(b.start_date);
    })[0];

    const hasMultipleSessions = () => {
        return clinic.clinic_sessions.length > 1
    }

    /**
     * Returns an array of unique session formats for the clinic sessions (if the clinic has 3 ONSITE sessions, the ONSITE format will be returned once)
     * @returns {Array}
     */
    const getArrayUniqueSessionFormat = () => (
        clinic.clinic_sessions
            .map((session) => session.session_format)
            .filter((format, index, self) => self.indexOf(format) === index)
            .sort((a, b) => {
                if(a == "ONLINE_LIVE")
                    return b == "ONLINE" ? -1 : b == "ONSITE" ? 1 : 0;
                if(a == "ONLINE")
                    return b == "ONLINE_LIVE" ? 1 : b == "ONSITE" ? -1 : 0;
                if(a == "ONSITE"){
                    return b.includes("ONLINE") ? 1 : 0;
                }
                return 0;
            })
    )

    const clinicState = clinic.status.find((status) => status.clinic_state?.clinic_state_id === clinic.active_clinic_state_id)?.clinic_state;

    const getClinicLocation = (myFirstSession) => {
        if(myFirstSession.location_name) {
            return myFirstSession.location_name;
        } else if(myFirstSession.city && myFirstSession.province_code) {
            return myFirstSession.city + ', ' + myFirstSession.province_code;
        } else if(myFirstSession.province_code) {
            return myFirstSession.province_code;
        }

        return "";
    }

    const clinicUrl = useMemo(() => {
        try {
            // The short name should be the same as the org's Page short name.
            // Might differ on test environments.
            return new URL(`${selectedBranch?.short_name}/clinics/${clinic.clinic_id}`, pageUrl).href;
        } catch(e) {
            console.error(e);
            return "";
        }
    }, [ selectedBranch?.short_name, clinic.clinic_id ]);

    return (
        <div {...refProps} data-tip={displayI18n("name", clinic.i18n, clinic.name, getGenericLocale())} data-for="clinicItemName" className="clinic-list-item">
            <Card className="clinic-list-item-inner">
                <CardHeader className={`text-white bg-gradient-clinic-${isNaN(parseInt(clinic.qualification.qualification_category.color)) ? 0 : clinic.qualification.qualification_category.color}`}>
                    <div className="small">#{clinic.clinic_reference}</div>
                    <div className="h5 text-md-truncate mt-n1 mb-0">
                        <DisplayI18n field='name' defaultValue={clinic.name} i18n={clinic.i18n} />
                    </div>
                    {/* <ClinicCategoryBuilder className="h5 text-md-truncate mt-n1 mb-0" hideColor qualification={clinic.qualification} /> */}
                </CardHeader>
                <CardBody>
                    {/* stretched-link to make the whole card clickable and acting as a link */}
                    <a
                        href={`${clinicUrl}`}
                        id={`clinic-${clinic.clinic_id}`}
                        className="register-result-stretched-link mdi mdi-launch"
                        rel="noopener noreferrer"
                        target="_blank"
                    />
                    {firstSession &&
                        <>
                            {hasMultipleSessions() ?
                                <>
                                    <div className="small text-muted"><Translate id='clinics.dates' /></div>
                                    <ClinicListItemSessions sessions={clinic.clinic_sessions} />
                                </>
                                :
                                <>
                                    <div className="small text-muted"><Translate id='clinics.date' /></div>
                                    <ClinicSessionTime session={firstSession} />
                                </>
                            }

                        <div className="mt-2">
                                {hasMultipleSessions() ?
                                    getArrayUniqueSessionFormat().map((format) => (
                                        <span key={format}>
                                            {format === 'ONLINE_LIVE' ?
                                                <><i className="mdi mdi-access-point mr-2" />{getArrayUniqueSessionFormat().length === 1 ? <Translate id={'clinics.location.' + firstSession.session_format} /> : null}</>
                                                : format === 'ONSITE' ?
                                                    <><i className="mdi mdi-map-marker mr-2" />{getArrayUniqueSessionFormat().length === 1 ? (firstSession ? getClinicLocation(firstSession) : <Translate id={'clinics.location.' + firstSession.session_format} />) : null}</>
                                                    :
                                                    <><i className="mdi mdi-play mr-2" />{getArrayUniqueSessionFormat().length === 1 ? <Translate id={'clinics.location.' + firstSession.session_format} /> : null}</>
                                                }
                                        </span>
                                    ))
                                    :
                                    <div>
                                        {firstSession.session_format === 'ONLINE_LIVE' ?
                                            <><i className="mdi mdi-access-point mr-1" /><Translate id={'clinics.location.' + firstSession.session_format} /></>
                                            : firstSession.session_format === 'ONSITE' ?
                                                <><i className="mdi mdi-map-marker mr-1" />{firstSession ? getClinicLocation(firstSession) : <Translate id={'clinics.location.' + firstSession.session_format} />}</>
                                                :
                                                <><i className="mdi mdi-play mr-1" /><Translate id={'clinics.location.' + firstSession.session_format} /></>}
                                    </div>

                                }
                            </div>
                        </>
                    }
                    <div className={firstSession ? "mt-2" : void 0}>
                        <div className="text-muted small"><Translate id='clinics.hostOrganization' /></div>
                        <div className="font-medium text-truncate"><DisplayI18n field='name' defaultValue={clinic.organisation.organisation_name} i18n={clinic.organisation.i18n} /></div>
                    </div>
                    <div className="mt-2">
                        <div className="text-muted small"><Translate id='clinics.category' /></div>
                        <span className="font-medium"><DisplayI18n field='name' defaultValue={clinic.qualification?.qualification_category?.name} i18n={clinic.qualification?.qualification_category?.i18n} /></span>
                    </div>
                    <div className="mt-2">
                        <div className="text-muted small"><Translate id='clinics.qualification' /></div>
                        <span className="font-medium">
                            <DisplayI18n field='name' defaultValue={clinic.qualification.name} i18n={clinic.qualification.i18n} />
                            {clinic.qualification.level &&
                                <DisplayI18n field='name' defaultValue={clinic.qualification.level.name} i18n={clinic.qualification.level.i18n} />
                            }
                        </span>
                    </div>
                </CardBody>
                <CardFooter className="bg-white">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            {/* <HeartIcon id={`favorite_${clinic.clinic_id}`} checked={false} /> */}
                            {clinic.sold_out == 1 ?
                                clinic.manage_waiting_list == 1 ?
                                    <div>
                                        <Badge color='danger'><Translate id='clinics.soldOutWaitingList' /></Badge>
                                    </div>
                                    :
                                    <div>
                                        <Badge color='danger'><Translate id='clinics.soldOut' /></Badge>
                                    </div>
                                :
                                <StatusBadge status={clinicState} color={clinicState?.color_code} />
                            }
                        </div>
                        <small>
                            <CurrencyFormat value={(clinic.total_amount || 0) / 100} />
                        </small>
                    </div>
                </CardFooter>
            </Card>
        </div>
    )
}
export default ClinicListItem;

ClinicListItem.propTypes = {
    clinic: PropTypes.object.isRequired,
};