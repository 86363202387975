export default{
    "clinics.detail.cart.existingOrNew.header"         : "Inscrire un participant",
    "clinics.detail.cart.existingOrNew.existing.title" : "Inscrire un participant existant",
    "clinics.detail.cart.existingOrNew.existing.text"  : "A déjà participé en tant que joueur, entraîneur, officiel ou bénévole.",
    "clinics.detail.cart.existingOrNew.new.title"      : "Inscrire un nouveau participant",
    "clinics.detail.cart.existingOrNew.new.text"       : "N'a jamais participé dans une organisation de hockey.",
    "clinics.detail.cart.existingOrNew.new.warning"    : "Avant de {create} et remplir un {form}, assurez-vous que le membre n'a jamais participé en tant que joueur, entraîneur, officiel ou bénévole dans une organisation de hockey.",
    "clinics.detail.cart.existingOrNew.new.create"     : "Créer un nouveau membre",
    "clinics.detail.cart.existingOrNew.new.form"       : "un formulaire d'inscription de nouveau membre",

    "clinics.detail.cart.participant.title"                          : "Sélection d'un participant",
    "clinics.detail.cart.participant.sessions"                       : "Sessions",
    "clinics.detail.cart.participant.category"                       : "Catégorie",
    "clinics.detail.cart.participant.qualification"                  : "Qualification",
    "clinics.detail.cart.participant.level"                          : "Niveau",
    "clinics.detail.cart.participant.noLevel"                        : "Aucun niveau",
    "clinics.detail.cart.participant.prerequisitesRequired"          : "Prérequis",
    "clinics.detail.cart.participant.showAll"                        : "Afficher tout",
    "clinics.detail.cart.participant.show"                           : "Afficher",
    "clinics.detail.cart.participant.prerequisites.qualification"    : "Prérequis de la qualification",
    "clinics.detail.cart.participant.prerequisites.organization"     : "Prérequis de l'organisation",
    "clinics.detail.cart.participant.all.prerequisites"              : "Prérequis",
    "clinics.detail.cart.participant.prerequisites.group"            : "Groupe #{index}",
    "clinics.detail.cart.participant.prerequisites.none"             : "Aucun prérequis",
    "clinics.detail.cart.participant.prerequisites.restrictions.all" : "Tous obligatoires",
    "clinics.detail.cart.participant.prerequisites.restrictions.one" : "Un seul obligatoire",
    "clinics.detail.cart.participant.tax"                            : "taxe",
    "clinics.detail.cart.participant.priceOneParticipant"            : "Prix pour un participant",
    "clinics.detail.cart.participant.selectParticipant"              : "Choisissez un participant",
    "clinics.detail.cart.participant.search.select"                  : "Choisir un participant",
    "clinics.detail.cart.participant.notInHcr"                       : "Ce participant n'est pas inscrit à HCR",
    "clinics.detail.cart.participant.registerToHcr"                  : "S'inscrire à HCR",
    "clinics.detail.cart.participant.prerequisitesInvalid"           : "Ce participant n'a pas les prérequis",
    "clinics.detail.cart.participant.wrongOrganisation"              : "Ce participant n'appartient pas à cette organisation",
    "clinics.detail.cart.participant.viewClinics"                    : "Voir les clinics de {ahm}",
    "clinics.detail.cart.participant.search"                         : "Rechercher un participant HCR",
    "clinics.detail.cart.participant.registerParticipant"            : "Inscrire un participant",
    "clinics.detail.cart.participant.hcr"                            : "Rechercher avec # HCR",
    "clinics.detail.cart.participant.firstName.regex"                : "Le prénom doit être d'un bon format",
    "clinics.detail.cart.participant.lastName.regex"                 : "Le nom doit être d'un bon format",
    "clinics.detail.cart.participant.date.minAge"                    : "Un membre doit avoir au moins 4 ans pour participer. Cependant, vous pouvez toujours créer ce membre.",
    "clinics.detail.cart.participant.date.notValid"                  : "La date de naissance doit respecter les restrictions du stage.",
    "clinics.detail.cart.participant.date.min"                       : "Le stage est pour les participants de {age} ans et plus.",
    "clinics.detail.cart.participant.date.max"                       : "Le stage est pour les participants de {age} ans et moins.",
    "clinics.detail.cart.participant.date.minMax"                    : "Le stage est pour les participants entre {minAge} ans et {maxAge} ans.",
    "clinics.detail.cart.participant.editAge"                        : "Cliquez ici si vous souhaitez changer la date de naissance",
    "clinics.detail.cart.participant.date.minDate"                   : "La date de naissance doit être avant aujourd'hui.",
    "clinics.detail.cart.participant.date.placeholder"               : "AAAA-MM-JJ",
    "clinics.detail.cart.participant.alreadyInAccount"               : "Ce membre est déjà dans votre compte!",
    "clinics.detail.cart.participant.alreadyInOtherAccount"          : "Ce membre est déjà dans un autre compte!",
    "clinics.detail.cart.participant.noResults"                      : "Nous n'avons pas pu trouver un profil correspondant.",
    "clinics.detail.cart.participant.resultsCreateMode"              : "Nous avons trouvé {numberOfResults, plural, one {un profil correspondant} other {des profils correspondants}}. Est-ce vous?",
    "clinics.detail.cart.participant.noProfile"                      : "Nous n'avons pas pu trouver un profil correspondant, souhaitez-vous créer un nouveau participant?",
    "clinics.detail.cart.participant.noProfileSimple"                : "Nous n'avons pas pu trouver un profil correspondant. Veuillez utiliser les filtres ci-dessus pour changer votre recherche.",
    "clinics.detail.cart.participant.notMine"                        : "{numberOfResults, plural, one {Ce profil n'est pas le mien} other {Ces profils ne sont pas les miens}}, continuer avec la création du participant.",
    "clinics.detail.cart.participant.createParticipant"              : "Créer un nouveau participant",
    "clinics.detail.cart.participant.next"                           : "Commencer l'inscription",
    "clinics.detail.cart.participant.noParticipant"                  : "Vous n'avez pas de participant dans votre compte Spordle",

    "clinics.detail.cart.participant.error.2000"        : "Cliquez ici pour modifier le prénom et le nom de ce membre",
    "clinics.detail.cart.participant.startRegistration" : "Commencer l'inscription",

    "clinics.detail.cart.participant.organization"          : "Organisation",
    "clinics.detail.cart.participant.organization.required" : "L'organisation est requise",

    "clinics.detail.cart.participant.attachments.required"         : "Ce type de document est requis",
    "clinics.detail.cart.participant.attachments.residency"        : "Preuve de résidence",
    "clinics.detail.cart.participant.attachments.birthCertificate" : "Certificat de naissance",

    // warning messages
    "clinics.detail.cart.participant.warnings.clinic_inactive"                                       : "Le stage est inactif",
    "clinics.detail.cart.participant.warnings.clinic_must_have_period"                               : "Le stage doit être lié à une période",
    "clinics.detail.cart.participant.warnings.clinic_period_doesnt_exist_or_inactive"                : "La période de le stage est inexistante ou invalide",
    "clinics.detail.cart.participant.warnings.invalid_clinic_status"                                 : "Le statut du stage est invalid",
    "clinics.detail.cart.participant.warnings.clinic_already_started"                                : "Le stage est déjà commencé",
    "clinics.detail.cart.participant.warnings.mandatory_fees_needed_for_clinic"                      : "Des frais obligatoires sont demandés pour le stage",
    "clinics.detail.cart.participant.warnings.clinic_soldout"                                        : "Aucune place disponible restante pour le stage",
    "clinics.detail.cart.participant.warnings.clinic_must_have_terms_and_conditions"                 : "Le stage doit avoir des termes et conditions",
    "clinics.detail.cart.participant.warnings.member_has_already_clinic_qualification"               : "La qualification de ce stage est déjà obtenu pour ce membre",
    "clinics.detail.cart.participant.warnings.inactive_member"                                       : "Ce membre est inactif",
    "clinics.detail.cart.participant.warnings.temporary_member"                                      : "Ce membre est un membre temporaire",
    "clinics.detail.cart.participant.warnings.clinic_unavailable_in_member_organisation"             : "Ce stage n'est pas disponible pour l'organisation de ce membre",
    "clinics.detail.cart.participant.warnings.member_need_background_check"                          : "Ce membre nécessite une vérification d'antécédents",
    "clinics.detail.cart.participant.warnings.member_need_background_check,member_need_prerequisite" : "Ce membre nécessite une vérification d'antécédents pour s'inscrire",
    "clinics.detail.cart.participant.warnings.member_had_already_purchased_this_clinic"              : "Ce membre a déjà acheté ce stage",
    "clinics.detail.cart.participant.warnings.missing_birthdate"                                     : "Ce membre n'a pas de date de naissance",
    "clinics.detail.cart.participant.warnings.member_doesnt_respect_min_age"                         : "Ce membre ne respecte pas l'âge minimum pour ce stage",
    "clinics.detail.cart.participant.warnings.member_doesnt_respect_max_age"                         : "Ce membre ne respecte pas l'âge maximum pour ce stage",
    "clinics.detail.cart.participant.warnings.need_all_prerequisites"                                : "Ce membre doit posséder tous les prérequis de le stage",
    "clinics.detail.cart.participant.warnings.member_had_an_unwanted_qualification"                  : "Ce membre avait une qualification non-voulue",
    "clinics.detail.cart.participant.warnings.member_need_prerequisite"                              : "Ce membre doit posséder le ou les prérequis nécessaires pour le stage",
    "clinics.detail.cart.participant.warnings.ERROR"                                                 : "Impossible d'inscrire ce membre",
    "clinics.detail.cart.participant.warnings.member_need_member_type"                               : "Ce membre n'a pas le type de membre requis pour ce stage",
}