export default{
    "clinics.detail.cart.questions.title"         : "Custom questions",
    "clinics.detail.cart.questions.subtitle"      : "Custom questions for participant",
    "clinics.detail.cart.questions.of"            : "of",
    "clinics.detail.cart.questions.required"      : "This field is required",
    "clinics.detail.cart.questions.min"           : "Please respect the minimum value",
    "clinics.detail.cart.questions.max"           : "Please respect the maximum value",
    "clinics.detail.cart.questions.label.between" : "Between",
    "clinics.detail.cart.questions.label.and"     : "and",
    "clinics.detail.cart.questions.label.min"     : "Minimum",
    "clinics.detail.cart.questions.label.max"     : "Maximum",
}