import { useContext, useState, useEffect } from "react";
import SpordleTableProvider, { useSpordleTable } from '@spordle/datatables';
import { OrganizationContext } from "../../contexts/OrganizationContext";
import { I18nContext } from "../../contexts/I18nContext";
import { Col, Row } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import RegisterCard from "./components/RegisterCard";
import RegisterContent from "./components/RegisterContent";
import SearchImage from '../../assets/images/SearchImage.js';
import Translate from "@spordle/intl-elements";
import { DisplayI18n } from "../../helpers/i18nHelper";
import AdSlot from "../../components/advertisement/AdSlot.jsx";
import { initializeAdSlots, leaderboardHeaderConfig, leaderboardFooterConfig, defineAdSlots, bigboxConfig, destroyHCRSlots } from "../../components/advertisement/adsHelper.js";
import { useLayoutEffect } from "react";

const EmptyLayout = ({ error, isInit }) => {
    return (
        <div className="text-center">
            <div className="text-dark">
                <SearchImage width={225} />
            </div>
            <div className="h3 font-bold">
                {isInit ? <Translate id="register.search.result.init.title" /> : <Translate id="register.search.result.empty.title" />}
            </div>
            <p className="text-muted">
                {error ?
                    <>
                        <i className="mdi mdi-alert" /> <DisplayI18n field="message" i18n={error.i18n} defaultValue={error.message} />
                    </>
                    :
                    isInit ?
                        <Translate id="register.search.result.init.subtitle" />
                        :
                        <Translate id="register.search.result.empty.subtitle" />

                }
            </p>
        </div>
    )
}

const Register = (props) => {
    const orgContext = useContext(OrganizationContext);
    const i18nContext = useContext(I18nContext);
    const [ isInit, setIsInit ] = useState(true);

    const renderSkeleton = () => {
        const builtSkeleton = [];
        for(let index = 0; index < 12; index++){
            builtSkeleton.push(
                <Col key={index} className="mb-3" md="6" lg="4" xl="3">
                    <div className="register-result h-100">
                        <div className="register-result-header">
                            <div style={{ lineHeight: 0 }} className="d-flex align-items-end register-result-header-inner">
                                <Skeleton className='d-block mr-3 border' height='60px' width='60px' circle />
                                <Skeleton height={22} width={100} />
                            </div>
                        </div>
                        <div className="px-3 pt-3" style={{ lineHeight: 1.1 }}>
                            <Skeleton height={13} count={3} />
                        </div>
                        <div className="border-top mt-auto p-3 d-flex align-items-center">
                            <Skeleton className="border" height={20} width={120} />
                            <Skeleton containerClassName="ml-auto" className="mr-2" height={18} width={30} />
                            <Skeleton height={18} width={30} />
                        </div>
                    </div>
                </Col>,
            )
        }
        return builtSkeleton;
    }

    useEffect(() => {
        initializeAdSlots(i18nContext.getGenericLocale());

        return () => {
            destroyHCRSlots();
        }
    }, []);

    return (
        <main>
            {orgContext.state.organisation_id &&
                <SpordleTableProvider
                    id='searchOrgRegister'
                    pagination={12}
                    viewMode='GRID'
                    dataIndex="organisation_id"
                    searchKeys={[
                        `i18n.${i18nContext.getGenericLocale()}.name`,
                        `organisation_name`,
                        'city',
                        'abbreviation',
                    ]}
                    loadingMessage={<Row form>{renderSkeleton()}</Row>}
                    initFilter={{
                        provinceCode: '',
                        postalCode: '',
                    }}
                    gridConfig={{
                        row: {
                            form: true,
                            tag: "ul",
                            className: "list-unstyled",
                        },
                        col: {
                            tag: "li",
                            className: "register-col",
                            md: 6,
                            xl: 3,
                        },
                        gridRender: (org) => org.organisation_id == "AD" ? <RegisterAdCard /> : <RegisterCard org={org} />,
                    }}
                    errorMessage={({ error }) => <EmptyLayout error={error} />}
                    emptyLayout={() => <EmptyLayout isInit={isInit} />}
                    loadData={(from, { filters }, spordleTable) => {
                        switch (from){
                            case 'FILTER':
                                setIsInit(false);
                                spordleTable.setLoading();

                                return orgContext.getOrgRegistrations({
                                    organisation_id: orgContext.federation.organisation_id,
                                    postal_code: filters.postalCode,
                                    province_code: filters.provinceCode,
                                })
                                    .then((orgs) => {
                                        const filteredOrgs = orgs.reduce((availableOrgs, org) => {
                                            if(org.active == 1) {
                                                availableOrgs.push(org);
                                            }

                                            if(availableOrgs.length === 3) {
                                                availableOrgs.push({ organisation_id: 'AD' })
                                            }

                                            return availableOrgs;
                                        }, []);

                                        return filteredOrgs;
                                    })
                            case 'CDM':
                                return Promise.resolve([]);
                            default:
                                break;
                        }
                    }}
                >
                    <RegisterContent />
                </SpordleTableProvider>
            }
        </main>
    );
}

const RegisterAdCard = () => {
    const { getGenericLocale } = useContext(I18nContext);

    useEffect(() => {
        const googletag = window.googletag;

            if(googletag?.pubads) {
                googletag.pubads().refresh();
            }
    }, [])

    return (
        <div className="card card-shadow py-3 d-flex flex-column h-100" style={{ minWidth: 320 }}>
            <AdSlot className="m-auto d-flex justify-content-center" adIsReady={true} adId={getGenericLocale() == "fr" ? "1699036608579" : "1699036561080"} />
        </div>
    )
}

export default Register;