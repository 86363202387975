import React, { useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
    Nav,
    Navbar,
    NavbarBrand,
    NavItem,
    Container,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    Spinner,
    DropdownItem
} from 'reactstrap'
import queryString, { stringifyUrl } from 'query-string';

// I18N
import { LOCALES } from '../../../i18n';
import Translate from '@spordle/intl-elements';

// Context Providers
import { I18nContext } from '../../../contexts/I18nContext';
import { AuthContext } from '../../../contexts/AuthContext';
import getAccountUrl from '../../../helpers/getExternalUrl';
import LogoHcr from '../../../components/LogoHcr';
import Sticky from '../../../components/Sticky';
import MainPhoto from '../../../components/visual/imgPill/MainPhoto';
import I18nLink from '../../../components/I18nRouting/I18nLink';
import { getLangSwitcherLink } from '../../../helpers/i18nRoutes';
import { stringBuilder } from "@spordle/helpers";
import withContexts from '../../../helpers/withContexts';
import getReferrer from '../../../helpers/getReferer';

class Header extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            burgerIsOpen: false,
        }
        this.burgerBreakpoint = 'lg';

        this.historyListener = props.history.listen(() => {
            if(window.innerWidth < 767){
                this.setState({ burgerIsOpen: false })
            }
        });
    }

    componentWillUnmount(){
        this.historyListener();
    }

    //this block is added in navBar desktop and nav header mobile
    _getAuthBlock = (auth, locale, id) => {
        if(auth.accessToken && auth.userData){
            return (
                <UncontrolledDropdown>
                    <DropdownToggle tag="button" className="reset-btn d-flex align-items-center justify-content-center" id={`user-toggle-${id}`}>
                        <MainPhoto width={40} />
                        {/* <img
                            src={auth.userData?.attributes?.logo || Placeholder}
                            alt={`${auth.userData?.attributes?.name} ${auth.userData?.attributes?.family_name}`}
                            className="rounded-circle object-fit-contain"
                            width="40"
                            height='40'
                        /> */}
                    </DropdownToggle>
                    <DropdownMenu right>
                        <div className="d-flex align-items-center border-bottom p-3 mb-2">
                            <MainPhoto width={60} />
                            {/* <img
                                src={auth.userData?.attributes?.logo || Placeholder}
                                alt={`${auth.userData?.attributes?.name} ${auth.userData?.attributes?.family_name}`}
                                className="rounded-circle object-fit-contain"
                                width='60'
                                height='60'
                            /> */}
                            <div className="ml-2">
                                <div className="mb-0 h4 text-nowrap">{auth.userData?.attributes?.name} {auth.userData?.attributes?.family_name}</div>
                                <p className="mb-0 text-muted">{auth.userData?.attributes?.email}</p>
                            </div>
                        </div>
                        <DropdownItem tag="a" href={getAccountUrl()} id={`to-account-${id}`}>
                            <i className="ti-home mr-1" /><Translate id='header.account' />
                        </DropdownItem>
                        {this.props.AuthContext.identityRoles.length > 0 &&
                            <DropdownItem
                                tag="a" target={'_blank'} href={stringifyUrl({
                                    url: getReferrer('HCR'),
                                    query: { accessToken: auth.accessToken },
                                })}
                                id={`to-SID-header-button`}
                            >
                                <i className="mdi mdi-launch mr-1" /><Translate id='header.spordle.id' />
                            </DropdownItem>
                        }
                        <DropdownItem divider />
                        <DropdownItem tag="button" onClick={() => auth.signOut().catch(console.error)} id={`signout-${id}`}>
                            <i className="mdi mdi-logout mr-1" /><Translate id='header.signout' />
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            )
        }
        if(auth.waitingForLogin){
            return <Spinner size='sm' color='primary' type='grow' />

        }
        return (
            <a
                id={`login-${id}`}
                className="login-btn nav-link font-title px-md-4"
                hrefLang={locale}
                href={queryString.stringifyUrl({
                    url: getAccountUrl(),
                    query: {
                        referrer: window.location.protocol + '//' + window.location.host + window.location.pathname,
                        lang: locale,
                    },
                })}
            >
                <Translate id='header.login' />
            </a>
        )
    }

    render(){
        return (
            <AuthContext.Consumer>
                {(auth) => (
                    <header className="shadow-sm position-relative">
                        <Sticky>
                            <Navbar color="dark" expand={this.burgerBreakpoint}>
                                <Container className="container-lg justify-content-end align-items-center">
                                    <NavbarBrand tag={Link} to='/' className="mr-auto" id='home'>
                                        <LogoHcr />
                                    </NavbarBrand>
                                    <div
                                        onClick={(e) => (this.state.burgerIsOpen && e.target === e.currentTarget) && this.setState({ burgerIsOpen: false })}
                                        className={stringBuilder('navbar-nav-container', { 'is-open': this.state.burgerIsOpen })}
                                    >
                                        <Nav className={`align-items-${this.burgerBreakpoint}-center`} navbar>
                                            <div className={`mobile-nav-header d-${this.burgerBreakpoint}-none`}>
                                                <Link className="reset-a" to='/' id='home-mobile'>
                                                    <img
                                                        src={"https://spordle-frontend-assets.s3.ca-central-1.amazonaws.com/hcrsite/hc@05x.png"}
                                                        alt="Hockey Canada"
                                                        className="mw-100"
                                                    />
                                                </Link>
                                                <div className="ml-auto d-flex">
                                                    <LangSwitcher id='lang-switcher-mobile' className='text-reset hover-opacity mx-3 h5 mb-0 font-title nav-link' />
                                                    <I18nContext.Consumer>
                                                        {(i18n) => this._getAuthBlock(auth, i18n.getGenericLocale(), 'mobile')}
                                                    </I18nContext.Consumer>
                                                    <div className="ml-3">
                                                        <Button
                                                            onClick={() => this.setState((prevState) => ({ burgerIsOpen: !prevState.burgerIsOpen }))}
                                                            aria-label="Toggle navigation" id='burger-menu-toggle'
                                                            className={`custom-icon-burger-btn nav-bar-toggler bg-transparent border-0 text-dark is-open`}
                                                        >
                                                            <i className="custom-icon-burger" />
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                            <NavItem className={`d-${this.burgerBreakpoint}-none`}>
                                                <Link className={`reset-a hover-opacity px-${this.burgerBreakpoint}-4 nav-link font-title`} to='/' id='link-home'>
                                                    <Translate id='header.home' />
                                                </Link>
                                            </NavItem>
                                            <NavItem>
                                                <I18nLink
                                                    className={`reset-a hover-opacity px-${this.burgerBreakpoint}-4 nav-link font-title`}
                                                    id='register-search'
                                                    path={{
                                                        en: '/register',
                                                        fr: '/inscription',
                                                    }}
                                                    defaultPath='/register'
                                                >
                                                    <Translate id='header.play' />
                                                </I18nLink>
                                            </NavItem>
                                            <NavItem>
                                                <I18nLink
                                                    className={`reset-a hover-opacity px-${this.burgerBreakpoint}-4 nav-link font-title`}
                                                    id='clinics-search'
                                                    path={{
                                                        en: '/clinics',
                                                        fr: '/stages',
                                                    }}
                                                    defaultPath='/clinics'
                                                >
                                                    <Translate id='header.clinics' />
                                                </I18nLink>
                                            </NavItem>
                                            <NavItem>
                                                <I18nLink
                                                    className={`reset-a hover-opacity px-${this.burgerBreakpoint}-4 nav-link font-title`}
                                                    id='about'
                                                    path={{
                                                        en: '/about',
                                                        fr: '/a-propos',
                                                    }}
                                                    defaultPath='/about'
                                                >
                                                    <Translate id='header.about' />
                                                </I18nLink>
                                            </NavItem>
                                            <NavItem>
                                                <I18nLink
                                                    className={`reset-a hover-opacity px-${this.burgerBreakpoint}-4 nav-link font-title`}
                                                    id='support'
                                                    path={{
                                                        en: '/support',
                                                        fr: '/soutien',
                                                    }}
                                                    defaultPath='/support'
                                                >
                                                    <Translate id='header.support' />
                                                </I18nLink>
                                            </NavItem>
                                            <NavItem className={`d-none d-${this.burgerBreakpoint}-block`}>
                                                <LangSwitcher id='lang-switcher' className={`text-reset hover-opacity px-${this.burgerBreakpoint}-4 nav-link font-title`} />
                                            </NavItem>
                                            <NavItem className={`d-none d-${this.burgerBreakpoint}-block`}>
                                                <I18nContext.Consumer>
                                                    {(i18n) => this._getAuthBlock(auth, i18n.getGenericLocale(), 'desktop')}
                                                </I18nContext.Consumer>
                                            </NavItem>
                                        </Nav>
                                    </div>
                                    <Button
                                        onClick={() => this.setState((prevState) => ({ burgerIsOpen: !prevState.burgerIsOpen }))}
                                        aria-label="Toggle navigation" id='burger-menu'
                                        className={`custom-icon-burger-btn nav-bar-toggler bg-transparent border-0 d-${this.burgerBreakpoint}-none`}
                                    >
                                        <i className="custom-icon-burger" />
                                    </Button>
                                </Container>
                            </Navbar>
                        </Sticky>
                    </header>
                )}
            </AuthContext.Consumer>
        )
    }
}

const LangSwitcher = (props) => {
    const auth = useContext(AuthContext);
    const { locale, getGenericLocale } = useContext(I18nContext);
    const lang = locale === LOCALES.FRENCH ? 'en' : 'fr';
    return (
        <a
            {...props}
            className={stringBuilder(props.className, 'text-uppercase')}
            hrefLang={lang}
            href={stringifyUrl({
                url: getLangSwitcherLink(getGenericLocale() == 'fr' ? 'en' : 'fr'),
                query: {
                    lang: process.env.REACT_APP_ENVIRONMENT === 'prod' ? undefined : lang,
                    accessToken: auth.accessToken,
                },
            }, {
                skipNull: true,
                skipEmptyString: true,
            })}
        >
            {lang}
        </a>
    );
}

export default withRouter(withContexts(AuthContext)(Header));
