import axios from 'axios';
import { myAccountApis } from '@spordle/myaccount-sdk'

const API_ACCOUNT = axios.create({
    baseURL: myAccountApis.get(process.env.REACT_APP_ENVIRONMENT),
    headers: {
        common: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept-Language': 'fr;en', // All supported languages
            'X-Api-Key': process.env.REACT_APP_ACCOUNT_API_KEY,
        },
        patch: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        put: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    },
    validateStatus: (status) => {
        return status >= 200 && status < 300 || status === 400; // default
    },
});

API_ACCOUNT.interceptors.response.use(null, (error) => {
    switch (error.response.status){
        case 403:
            return Promise.reject(new Error("forbidden"));
        case 401:
            return Promise.reject(new Error("unauthorized"));
        case 418:
            return Promise.reject(new Error("teapot"));
        default:
            break;
    }

    return Promise.reject(error);
})

export default API_ACCOUNT;