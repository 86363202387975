import { useIntl } from 'react-intl';

/**
 * Checks if a translation is available for the current Locale, returns default value or null if not
 * @param {Object} i18n API Object containing I18N variables
 * @param {string} locale Current Locale
 * @param {string} field Name of the Field
 * @returns {string|null}
 */
function displayI18n(field, i18n, defaultValue, locale){
    if(i18n && i18n[locale] && i18n[locale][field]){
        return i18n[locale][field]
    }
    return defaultValue || null

}

/**
 * Display i18n fields from a i18n object
 * @param {object} props The field we want to show in the i18n object. Field can be 2 formats: "name" or "i18n.en.name" if we want to specify a lang (usually from renderCustom)
 * @param {string} props.field The field we want to show in the i18n object. Field can be 2 formats: "name" or "i18n.en.name" if we want to specify a lang (usually from renderCustom)
 * @param {Object} props.i18n i18n object that should contain fr/en objects ex: i18n[en][name]
 * @param {?string} props.defaultValue default value to use if the i18n is not present (usually the fallback field on the entity)
 * @param {string} [props.lang] A specific language we want to display the field in
 */
const DisplayI18n = ({ field, i18n, defaultValue, lang, ...props }) => {
    const intl = useIntl();

    // Detect field format (i18n.en.name)
    const splitValue = field.split('.')
    const myLang = splitValue.length > 1 ? splitValue[splitValue.length - 2] : intl.locale.split('-')[0]

    return (
        displayI18n(splitValue[splitValue.length - 1], i18n, defaultValue, lang ?? myLang)
    );
}

export{ DisplayI18n, displayI18n }