export default{
    // Gateway
    "clinic.detail.cart.gateway.title"                            : "Méthodes de paiements",
    "clinic.detail.cart.gateway.paymentMethod.label"              : "Autre mode de paiement",
    "clinic.detail.cart.gateway.paymentMethod.online"             : "Payer en ligne maintenant",
    "clinic.detail.cart.gateway.paymentMethod.manual"             : "Envoyer le paiement manuellement",
    "clinic.detail.cart.gateway.paymentMethod.empty"              : "Aucun mode de paiement",
    "clinic.detail.cart.gateway.paymentMethod.required"           : "Veuillez choisir un moyen de paiement",
    "clinic.detail.cart.gateway.paymentMethod.option.CREDITCARD"  : "Carte de crédit",
    "clinic.detail.cart.gateway.paymentMethod.option.CHEQUE"      : "Chèque",
    "clinic.detail.cart.gateway.paymentMethod.option.CASH"        : "Espèces",
    "clinic.detail.cart.gateway.paymentMethod.option.INTERAC"     : "Virement interac",
    "clinic.detail.cart.gateway.paymentMethod.warning.CREDITCARD" : "Vous avez sélectionné une méthode de paiement avec SportsPay. Vous allez êtes redirigés vers la passerelle de paiement SportsPay pour traiter votre transaction. Une fois le paiement complété, vous allez être redirigés ici avec un message de confirmation et un lien vers votre facture.",
    "clinic.detail.cart.gateway.paymentMethod.myCard.title"       : "Mes cartes de crédit enregistrées",
    "clinic.detail.cart.gateway.paymentMethod.myCard.otherCard"   : "Utiliser une autre carte",
    "clinic.detail.cart.gateway.billingInfo.title"                : "Informations de facturation",
    "clinic.detail.cart.gateway.billingInfo.label.firstName"      : "Prénom",
    "clinic.detail.cart.gateway.billingInfo.label.lastName"       : "Nom de famille",
    "clinic.detail.cart.gateway.billingInfo.label.email"          : "Adresse électronique",
    "clinic.detail.cart.gateway.billingInfo.label.street"         : "Adresse de rue",
    "clinic.detail.cart.gateway.billingInfo.label.city"           : "Ville",
    "clinic.detail.cart.gateway.billingInfo.label.country"        : "Pays",
    "clinic.detail.cart.gateway.billingInfo.label.province"       : "Province",
    "clinic.detail.cart.gateway.billingInfo.label.zip"            : "Zip / code postal",
    "clinic.detail.cart.gateway.cardInfo.title"                   : "Information de paiement",
    "clinic.detail.cart.gateway.cardInfo.label.name"              : "Nom sur la carte",
    "clinic.detail.cart.gateway.cardInfo.label.cardNumber"        : "Numéro de carte",
    "clinic.detail.cart.gateway.cardInfo.label.expirationDate"    : "Date d'expiration",
    "clinic.detail.cart.gateway.cardInfo.helper.expirationDate"   : "MM/AA",
    "clinic.detail.cart.gateway.cardInfo.label.cvv"               : "CVV",
    "clinic.detail.cart.gateway.cardInfo.helper.cvv"              : "Trouver le CVV",
    "clinic.detail.cart.gateway.cardInfo.helper.findCvv"          : "Le CVV se retrouve habituellement en arrière de la carte et est de 3 à 4 chiffres.",
    "clinic.detail.cart.gateway.pay"                              : "Payer",
    "clinic.detail.cart.gateway.country.CA"                       : "Canada",
    "clinic.detail.cart.gateway.country.empty"                    : "Aucun pays",
    "clinic.detail.cart.gateway.provinces.CA.AB"                  : "Alberta",
    "clinic.detail.cart.gateway.provinces.CA.BC"                  : "Colombie britannique",
    "clinic.detail.cart.gateway.provinces.CA.MB"                  : "Manitoba",
    "clinic.detail.cart.gateway.provinces.CA.NB"                  : "Nouveau-Brunswick",
    "clinic.detail.cart.gateway.provinces.CA.NL"                  : "Terre-Neuve-et-Labrador",
    "clinic.detail.cart.gateway.provinces.CA.NS"                  : "Nouvelle-Écosse",
    "clinic.detail.cart.gateway.provinces.CA.NU"                  : "Nunavut",
    "clinic.detail.cart.gateway.provinces.CA.NT"                  : "Territoires du nord-ouest",
    "clinic.detail.cart.gateway.provinces.CA.ON"                  : "Ontario",
    "clinic.detail.cart.gateway.provinces.CA.PE"                  : "Île-du-Prince-Édouard",
    "clinic.detail.cart.gateway.provinces.CA.QC"                  : "Québec",
    "clinic.detail.cart.gateway.provinces.CA.SK"                  : "Saskatchewan",
    "clinic.detail.cart.gateway.provinces.CA.YT"                  : "Yukon",
    "clinic.detail.cart.gateway.provinces.empty"                  : "Aucune province",
    "clinic.detail.cart.gateway.paysafe.processing.error.generic" : "L'erreur semble être dû à la configuration de cette transaction. Veuillez contacter notre support SVP.",
    "clinic.detail.cart.gateway.paysafe.processing.error.3002"    : "Vous avez soumis un numéro de carte ou une marque non valide ou une combinaison de numéro de carte et de marque avec votre demande. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3004"    : "Le code postal / postal doit être fourni pour une demande de chèque AVS. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3005"    : "Vous avez soumis une valeur CVV incorrecte avec votre demande. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3006"    : "Vous avez soumis un numéro de carte de crédit expiré avec votre demande. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3007"    : "Votre demande a échoué à la vérification AVS. Notez que le montant a toujours été réservé sur la carte du client et sera libéré dans 3 à 5 jours ouvrables. Veuillez vous assurer que l'adresse de facturation est exacte avant de réessayerthe transaction.",
    "clinic.detail.cart.gateway.paysafe.processing.error.3008"    : "Vous avez soumis un type de carte pour lequel le compte marchand n'est pas configuré. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3009"    : "Votre demande a été refusée par la banque émettrice. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3011"    : "Votre demande a été refusée par la banque émettrice car la carte utilisée est une carte restreinte. Contactez la société de carte de crédit du titulaire de la carte pour une enquête plus approfondie. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3012"    : "Votre demande a été refusée par la banque émettrice car la date d'expiration de la carte de crédit soumise n'est pas valide. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3013"    : "Votre demande a été refusée par la banque émettrice en raison de problèmes avec le compte de carte de crédit. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3014"    : "Votre demande a été refusée - la banque émettrice a renvoyé une réponse inconnue. Contactez la société de carte de crédit du titulaire de la carte pour une enquête plus approfondie. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3015"    : "La banque vous a demandé de traiter la transaction manuellement en appelant la société de carte de crédit du titulaire de la carte. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3016"    : "La banque a demandé que vous récupériez la carte auprès du titulaire de la carte - il peut s'agir d'une carte perdue ou volée. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3017"    : "Vous avez soumis un numéro de carte de crédit non valide avec votre demande. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3018"    : "La banque vous a demandé de réessayer la transaction. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3019"    : "Votre demande a échoué à la vérification du CVV. Veuillez noter que le montant peut encore avoir été réservé sur la carte du client, auquel cas il sera débloqué dans 3 à 5 jours ouvrables. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3020"    : "La banque vous a demandé de réessayer la transaction. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3021"    : "Le numéro de confirmation inclus dans cette demande est introuvable. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3022"    : "La carte a été refusée en raison de fonds insuffisants. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3023"    : "Votre demande a été refusée par la banque émettrice en raison de ses règles propriétaires relatives à l'activité des cartes. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3024"    : "Votre demande a été refusée, car la banque émettrice n'autorise pas la transaction pour cette carte. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3025"    : "La passerelle de traitement externe a signalé des données non valides. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3026"    : "La passerelle de traitement externe a signalé que le type de compte n'est pas valide. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3027"    : "La passerelle de traitement externe a signalé qu'une limite a été dépassée. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3028"    : "La passerelle de traitement externe a signalé une erreur système. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3029"    : "La passerelle de traitement externe a rejeté la transaction. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3030"    : "La passerelle de traitement externe a signalé que la transaction n’était pas autorisée. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3031"    : "Le numéro de confirmation que vous avez soumis avec votre demande fait référence à une transaction qui n'est pas en attente. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3032"    : "Votre demande a été refusée par la banque émettrice ou la passerelle externe car la carte se trouve probablement dans l'une de leurs bases de données négatives. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3035"    : "Votre demande a été refusée en raison du dépassement du nombre de tentatives de code PIN. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3036"    : "Votre demande a été refusée en raison d'un émetteur non valide. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3037"    : "Votre demande a été refusée, car elle n'est pas valide. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3038"    : "Votre demande a été refusée en raison de l'annulation du client. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3039"    : "Votre demande a été refusée en raison d'une valeur d'authentification non valide. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3040"    : "Votre demande a été refusée, car le type de demande n'est pas autorisé sur la carte. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3041"    : "Votre demande a été refusée en raison d'un délai d'expiration. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3042"    : "Votre demande a été refusée en raison d'une erreur cryptographique. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3044"    : "Vous avez envoyé une demande en double. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3045"    : "Vous avez soumis un format de date non valide pour cette demande. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3046"    : "La transaction a été refusée car le montant a été défini sur zéro. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3047"    : "La transaction a été refusée car le montant dépasse la limite plancher. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3048"    : "La transaction a été refusée car le montant est inférieur à la limite plancher. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3049"    : "La banque a demandé que vous récupériez la carte auprès du titulaire de la carte - la carte de crédit est expirée. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3050"    : "La banque vous a demandé de récupérer la carte auprès du titulaire de la carte - une activité frauduleuse est suspectée. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3051"    : "La banque a demandé que vous récupériez la carte auprès du titulaire de la carte - contactez l'acquéreur pour plus d'informations. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3052"    : "La banque a demandé que vous récupériez la carte auprès du titulaire de la carte - la carte de crédit est limitée. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3053"    : "La banque vous a demandé de récupérer la carte auprès du titulaire de la carte - veuillez appeler l'acquéreur. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3054"    : "La transaction a été refusée en raison d'une fraude présumée. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3055"    : "Ce type de transaction n'est pas pris en charge. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3056"    : "Le type de transaction d'origine ne correspond pas. ",
    "clinic.detail.cart.gateway.paysafe.processing.error.3057"    : "Veuillez indiquer au titulaire de la carte d'appeler l'émetteur - ne réessayez pas la transaction.",
    "clinic.detail.cart.gateway.paysafe.processing.error.4002"    : "La transaction a été refusée par notre département de gestion de risque.",
    "clinic.detail.cart.gateway.paysafe.processing.error.4001"    : "Votre requête a été refusée car le numéro de carte associé à cette transaction est dans notre base de données négative.",
    "clinic.detail.cart.gateway.paysafe.processing.error.5031"    : "La transaction a déjà été traitée.",
    "clinic.detail.cart.gateway.paysafe.processing.error.6009"    : "Une erreur s'est produite lors de l'ajout de cette carte à votre compte. Elle est peut-être déjà lié à votre compte. Entrez une nouvelle carte ou <link>utilisez l'existante</link>.",

    // QE error codes
    "clinic.detail.cart.gateway.qe.other" : "Une erreur est survenu lors de la transaction.",
    "clinic.detail.cart.gateway.qe.1000"  : "Certaines données de la transaction étaient manquantes ou non valides, ou le numéro de référence était trop long.",
    "clinic.detail.cart.gateway.qe.1001"  : "Votre identifiant de terminal n'est pas activé ou vous n'êtes pas autorisé à l'utiliser à partir de cette adresse IP.",
    "clinic.detail.cart.gateway.qe.1003"  : "Transaction refusée par le système de suivi de la vitesse de la carte InterPay ™ Card Services. Cette carte a été utilisée plus que la limite prédéfinie pour cet ID de terminal ne le permet.",
    "clinic.detail.cart.gateway.qe.1004"  : "Transaction refusée par le système de vitesse marchande d'InterPay ™ Card Services. Ce marchand a dépassé leurs limites de volume de transaction prédéfinies.",
    "clinic.detail.cart.gateway.qe.1005"  : "Transaction declined by InterPay™ Card Services card limiting system. The amount of money charged to this card has exceeded preset limits. ",
    "clinic.detail.cart.gateway.qe.1006"  : "Transaction refusée par le système de limitation des marchands InterPay ™ Card Services. Le montant d'argent facturé sur cet ID de terminal a dépassé les limites prédéfinies.",
    "clinic.detail.cart.gateway.qe.1008"  : "Cet ID de terminal utilise le système de contre-vérification InterPay ™ Card Services et la carte se trouve dans la table de contrepartie.",
    "clinic.detail.cart.gateway.qe.1010"  : "Le message de demande de transaction contenait un ou plusieurs caractères illégaux. Veuillez vous assurer que vous suivez les spécifications.",
    "clinic.detail.cart.gateway.qe.1011"  : "Un montant vierge ou nul a été envoyé dans une transaction financière. Vérifiez le montant.",
    "clinic.detail.cart.gateway.qe.1012"  : "La carte que vous interrogez ne figure pas dans la table Contra.",
    "clinic.detail.cart.gateway.qe.1014"  : "La carte que vous essayez de supprimer du tableau Contra n'est pas présente dans le tableau.",
    "clinic.detail.cart.gateway.qe.1015"  : "Il n'y a pas de retour de marchandise correspondant à l'annulation de retour de marchandise que vous tentez.",
    "clinic.detail.cart.gateway.qe.1016"  : "Il n'y a pas de pré-autorisation correspondante pour l'achèvement que vous tentez.",
    "clinic.detail.cart.gateway.qe.1017"  : "Il n'y a pas de vente, d'achèvement ou de publication forcée correspondant à l'annulation que vous tentez.",
    "clinic.detail.cart.gateway.qe.1018"  : "Vous essayez de traiter un type de carte pour lequel vous n'êtes pas configuré ou la carte que vous essayez de traiter n'est pas un type valide.",
    "clinic.detail.cart.gateway.qe.1019"  : "Vous essayez d'utiliser un type de transaction pour lequel vous n'êtes pas configuré ou vous essayez d'utiliser un type de transaction qui n'existe pas.",
    "clinic.detail.cart.gateway.qe.1020"  : "Le numéro de carte n'a pas réussi les tests de contrôle des chiffres pour ce type de carte.",
    "clinic.detail.cart.gateway.qe.1021"  : "Un processeur en amont s'est déconnecté.",
    "clinic.detail.cart.gateway.qe.1023"  : "Échec du réseau d'autorisation. La transaction n'a été ni approuvée ni refusée.",
    "clinic.detail.cart.gateway.qe.1024"  : "Échec du réseau d'autorisation. La transaction n'a été ni approuvée ni refusée.",
    "clinic.detail.cart.gateway.qe.1025"  : "Échec du réseau d'autorisation. La transaction n'a été ni approuvée ni refusée.",
    "clinic.detail.cart.gateway.qe.1028"  : "L'ID opérateur que vous avez soumis contenait autre chose que des lettres et des chiffres.",
    "clinic.detail.cart.gateway.qe.1029"  : "Vous avez soumis une transaction Force Post sans numéro d'autorisation.",
    "clinic.detail.cart.gateway.qe.1030"  : "Le numéro de carte que vous avez soumis ne contenait pas le nombre correct de chiffres pour ce type de carte.",
    "clinic.detail.cart.gateway.qe.1031"  : "Le numéro de référence est manquant ou invalide. REF ne peut contenir que des chiffres ou des lettres, pas d'espaces ni de ponctuation.",
    "clinic.detail.cart.gateway.qe.1032"  : "Les données de la carte sont invalides ou incomplètes.",
    "clinic.detail.cart.gateway.qe.1033"  : "Un règlement a été demandé pour un lot vide. N'oubliez pas qu'un lot est une combinaison de TERMID et OPERID.",
    "clinic.detail.cart.gateway.qe.1034"  : "La configuration du terminal n'est pas valide.",
    "clinic.detail.cart.gateway.qe.1035"  : "Le REF de chaque transaction doit être unique dans le lot.",
    "clinic.detail.cart.gateway.qe.1036"  : "Le numéro de facture n'est pas valide.",
    "clinic.detail.cart.gateway.qe.1055"  : "Votre ID de terminal a un montant maximum par limite de transaction, et cette transaction dépasse ce montant.",
    "clinic.detail.cart.gateway.qe.1088"  : "Le réseau d'autorisation s'est déconnecté.",
    "clinic.detail.cart.gateway.qe.1097"  : "La communication avec l'hôte de la banque a échoué pendant la transaction. Réessayez la transaction.",
    "clinic.detail.cart.gateway.qe.1098"  : "L'hôte de la banque a fourni une réponse non valide. Réessayez la transaction.",
    "clinic.detail.cart.gateway.qe.1099"  : "Une panne de réseau s'est produite lors de la communication avec l'émetteur de la carte.",
    "clinic.detail.cart.gateway.qe.9100"  : "La passerelle a généré une erreur interne.",
    "clinic.detail.cart.gateway.qe.9101"  : "La passerelle a généré une erreur interne.",
}