/**
 * Function that catch any json parse error.
 * Prevents crash.
 */
export function JSONParse(value, fallback){
    try{
        return JSON.parse(value) || fallback;
    }catch(e){
        if(process.env.NODE_ENV === 'development')
            console.error(e);
        return fallback;
    }
}

/**
 * Function that catch any json stringify error.
 * Prevents crash.
 */
export function JSONStringify(value, fallback = null){
    try{
        return JSON.stringify(value || fallback);
    }catch(e){
        console.error(e);
        return fallback;
    }
}