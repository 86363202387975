import { useCallback, useEffect, useRef, useState } from "react"

export function useImgIsLoaded(){
    const [ isLoaded, setIsLoaded ] = useState(false);
    const ref = useRef();
    const onLoad = useCallback(() => {
        setIsLoaded(true);
    }, [])

    useEffect(() => {
        if(ref.current && ref.current.complete){
            onLoad();
        }
    }, []);

    return [ ref, isLoaded, onLoad ];
}