/* eslint-disable react/no-array-index-key */
/*eslint indent: "off"*/
import { ErrorBoundary as SentryErrorBoundary, withProfiler as withSentryProfiler } from '@sentry/react';
import { captureGAError, GApageView } from '@spordle/ga4';
import React, { useEffect } from 'react';
import { Route, Router, Switch, useHistory } from "react-router-dom";
import BrowserNotSupported from './BrowserNotSupported';
import ScrollToTop from './components/customHooks/ScrollToTop';
import SpordleToast from '@spordle/toasts';
import AccountsContextProvider from './contexts/AccountsContext';
import AddressTypesContextProvider from './contexts/AddressTypesContext';
import AuthContextProvider from './contexts/AuthContext';
import ClinicsContextProvider from './contexts/ClinicsContext';
import FormsContextProvider from './contexts/FormsContext';
// Context Providers
import I18nContextProvider, { I18nContext } from './contexts/I18nContext';
import MembersContextProvider from './contexts/MembersContext';
import MemberTypesContextProvider from './contexts/MemberTypesContext';
import OrganizationContextProvider from './contexts/OrganizationContext';
import PeriodsContextProvider from './contexts/PeriodsContext';
import QualificationCategoriesContextProvider from './contexts/QualificationCategoriesContext';
import QualificationsContextProvider from './contexts/QualificationsContext';
import ThemeContextProvider from './contexts/ThemeContext';
import UtilsContextProvider, { UtilsContext } from './contexts/UtilsContext';
import PhoneTypesContextProvider from './contexts/PhoneTypesContext';
import FatalError from './FatalError';
import { history } from './helpers';
// Language
import { I18nProvider } from './i18n';
// Routes
import PublicRoute from './routes/PublicRoutes';
import routes from './routes/routes';
import { FormikElementsGlobalConfigs } from '@spordle/formik-elements';

// Force amplify push 10
class App extends React.Component{
    constructor(props){
        super(props);
        this.isSupportedBrowser = new RegExp(process.env.REACT_APP_USERAGENT_REGEX.substring(1, process.env.REACT_APP_USERAGENT_REGEX.length - 1)).test(window.navigator.userAgent);
    }

    render(){
        return (
            <SentryErrorBoundary
                fallback={FatalError} showDialog
                onError={(error) => {
                    captureGAError(error.message);
                }}
            >
                <Router basename="/" history={history}>

                <GA4Routing />
                <I18nContextProvider>
                <ThemeContextProvider>
                <AccountsContextProvider>
                <AuthContextProvider>
                <OrganizationContextProvider>
                <MembersContextProvider>
                <UtilsContextProvider>
                <FormsContextProvider>
                <AddressTypesContextProvider>
                <PeriodsContextProvider>
                <QualificationCategoriesContextProvider>
                <QualificationsContextProvider>
                <MemberTypesContextProvider>
                <PhoneTypesContextProvider>
                <ClinicsContextProvider>
                    <UtilsContext.Consumer>
                        {(utilsContext) => (
                            <FormikElementsGlobalConfigs
                                formikPhoneInput={{
                                    suggestedCountries: [ 'ca', 'us' ],
                                    defaultSelectedCountry: 'ca',
                                }}
                                formikDateTime={{
                                    dateFormat: 'YYYY-MM-DD',
                                }}
                                formikAddress={{
                                    getCountries: utilsContext.getCountries,
                                    getProvinces: utilsContext.getProvinces,
                                    countryRestrictions: [ 'ca', 'us' ],
                                }}
                            >
                                <I18nContext.Consumer>
                                    {({ locale }) => (
                                        <I18nProvider locale={locale}>
                                            <ScrollToTop />
                                            <SpordleToast config={{ position: { top: 70 } }} />
                                            <Switch>
                                                {!this.isSupportedBrowser &&
                                                    <Route component={BrowserNotSupported} />
                                                }
                                                {routes.map((prop, key) => {
                                                    return <PublicRoute path={prop.path} key={key} component={prop.component} />;
                                                })}
                                            </Switch>
                                        </I18nProvider>
                                    )}
                                </I18nContext.Consumer>
                            </FormikElementsGlobalConfigs>
                        )}
                    </UtilsContext.Consumer>
                </ClinicsContextProvider>
                </PhoneTypesContextProvider>
                </MemberTypesContextProvider>
                </QualificationsContextProvider>
                </QualificationCategoriesContextProvider>
                </PeriodsContextProvider>
                </AddressTypesContextProvider>
                </FormsContextProvider>
                </UtilsContextProvider>
                </MembersContextProvider>
                </OrganizationContextProvider>
                </AuthContextProvider>
                </AccountsContextProvider>
                </ThemeContextProvider>
                </I18nContextProvider>
                </Router>
            </SentryErrorBoundary>
        )
    }
}

const GA4Routing = () => {
    const routerHistory = useHistory();

    // Setup GA custom page views using     router
    useEffect(() => {
        const handleGA4RouteChange = (url) => {
            if(window?.gtag){
                window.gtag("event", "page_view", {
                    event_category: 'user_click',
                    page_location: window.location.href,
                    page_path: url,
                    page_title: document.title,
                    send_to: [ 'G-D6Y5SH1JER', 'G-HRWDVC97KX' ],
                });
            }
        };

        handleGA4RouteChange(routerHistory.location.pathname)
        const historyListener = routerHistory.listen((location) => {
            handleGA4RouteChange(location.pathname);
        });
        return historyListener;
    }, []);

    // useEffect(() => {
    //     GApageView(routerHistory.location.pathname);
    //     const historyListener = routerHistory.listen((location) => {
	// 		GApageView(location.pathname);
    //     });
    //     return historyListener;
    // }, [])

    return null;
}

export default withSentryProfiler(App);