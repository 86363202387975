import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Component that attaches scroll to top hanler on router change
// renders nothing, just attaches side effects
const ScrollToTop = () => {
    // this assumes that current router state is accessed via hook
    // but it does not matter, pathname or that anything ever may come from props, context, etc.
    const pathname = useLocation().pathname;

    useEffect(() => {
        try{
            // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
            window.scroll({
                top: 0,
                left: 0,
            });
        }catch(error){
            window.scrollTo(0, 0);
        }
    }, [ pathname ]);

    return null;
};

export default ScrollToTop;