export default{
    "home.hero.title.small"      : "Bienvenue au Registre de",
    "home.hero.title.big"        : "Hockey Canada",
    "home.hero.img.alt"          : "Quatre filles qui jouent au hockey",
    "home.hero.gameBack.img.alt" : "Notre sport est de retour",
    "home.lead.1"                : "Bienvenue au Registre de Hockey Canada, la plateforme nationale de Hockey Canada et la base de données la plus complète dans le monde du hockey pour la gestion des inscriptions et des équipes, le suivi des compétences et bien plus encore. ",
    "home.lead.2"                : "Le Registre constitue le principal répertoire pour toute l’information se rapportant au hockey et aux activités connexes au pays.",
    "home.learnMore"             : "En savoir plus",
    "home.card.play.title"       : "INSCRIPTION AU HOCKEY",
    "home.card.play.text"        : "pour vous inscrire à des essais, au hockey mineur, au hockey féminin, à des événements spéciaux et plus encore.",
    "home.card.clinic.title"     : "INSCRIPTION AUX STAGES",
    "home.card.clinic.text"      : "pour vous inscrire à des cours en ligne ou en personne.",
    "home.card.applyNow.title"   : "DONNEZ MAINTENANT",
    "home.card.applyNow.text"    : "La totalité de votre don servira à aider les jeunes de votre communauté à continuer de jouer. {link} pour faire un don.",
    "home.card.btn"              : "Cliquez ici",
    "home.assistFunds.title"     : "OFFREZ UNE AIDE ET FAITES UN DON",
    "home.assistFunds.text.1"    : "Nous savons à quel point une aide au hockey est importante. Mais saviez-vous qu’une aide à l’extérieur de la patinoire peut être tout aussi précieuse? Depuis 2020, le Fonds d’aide de la Fondation Hockey Canada a subventionné les frais d’inscription de plus de 8 000 jeunes, jusqu’à concurrence de 500 $. Mais cette année, le besoin est encore plus grand. ",
    "home.assistFunds.text.2"    : "Votre appui envers la Fondation Hockey Canada nous aide à partager ce sport avec tout le monde qui partage cette nation. Parce que chaque enfant mérite la chance de jouer. ",
    "home.assistFunds.btn"       : "Faire un don",
    "home.partners.title"        : "Merci à nos partenaires",
}