export default{
    "clinic.detail.cart.waitingListInfo.title"  : "Inscription à la liste d'attente",
    "clinic.detail.cart.waitingListInfo.text"   : "Le stage est actuellement plein, mais une liste d'attente est disponible et vous pouvez vous y inscrire.",
    "clinic.detail.cart.waitingListInfo.text2"  : "Si vous êtes sélectionné pour participer à ce stage, vous recevrez un courriel vous invitant à revenir sur cette page pour compléter votre inscription.",
    "clinic.detail.cart.waitingListInfo.text3"  : "Vous aurez l'option de refuser une invitation si vous changez d'avis.",
    "clinic.detail.cart.waitingListInfo.button" : "Commencer l'inscription",

    "clinic.detail.cart.waitingListConfirmation.title"       : "Confirmation d'inscription à la liste d'attente",
    "clinic.detail.cart.waitingListConfirmation.thanks"      : "Merci de votre inscription à la liste d'attente!",
    "clinic.detail.cart.waitingListConfirmation.number"      : "Numéro de liste d'attente: ",
    "clinic.detail.cart.waitingListConfirmation.emailSentTo" : "Un courriel a été envoyé à",
    "clinic.detail.cart.waitingListConfirmation.keepAnEye"   : "Gardez un oeil sur vos courriels pour rester à jour avec votre inscription, nous vous contacterons en cas de besoin.",

    "clinic.detail.cart.waitingListReturn.title"                : "Finalisation de l'inscription",
    "clinic.detail.cart.waitingListReturn.welcomeBack"          : "Content de vous revoir!",
    "clinic.detail.cart.waitingListReturn.selected"             : "Vous avez été sélectionné pour participer à ce stage.",
    "clinic.detail.cart.waitingListReturn.continueRegistration" : "Cliquez le bouton {confirm} pour continuer votre inscription pour {firstName} {lastName}, ou cliquez sur le bouton {cancel} pour annuler votre inscription à ce stage et à la liste d'attente.",
    "clinic.detail.cart.waitingListReturn.header"               : "Annulation",
    "clinic.detail.cart.waitingListReturn.reason"               : "Raison d'annulation",
    "clinic.detail.cart.waitingListReturn.reason.required"      : "La raison d'annulation est requise",

    "clinic.detail.cart.waitingListCheckoutError.title" : "Liste d'attente",
    "clinic.detail.cart.waitingListCheckoutError.text"  : "Malheureusement, la dernière place du stage a été remplie lorsque vous complétiez votre inscription, nous vous avons inscrit à la liste d'attente.",
}