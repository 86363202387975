export default{
    "header.home"       : "Home",
    "header.account"    : "My Account",
    "header.settings"   : "Settings",
    "header.myProfile"  : "My Profile",
    "header.signout"    : "Sign Out",
    "header.spordle.id" : "Go to Spordle ID",

    "header.play"    : "Register to play",
    "header.clinics" : "Register for clinics",
    "header.about"   : "About HCR",
    "header.support" : "Support",
    "header.login"   : "Login",
}