export default{
    "clinics.detail.cart.existingOrNew.header"         : "Register a participant",
    "clinics.detail.cart.existingOrNew.existing.title" : "Register an existing participant",
    "clinics.detail.cart.existingOrNew.existing.text"  : "Already participated in organized hockey as a player, coach, official or volunteer.",
    "clinics.detail.cart.existingOrNew.new.title"      : "Register a new participant",
    "clinics.detail.cart.existingOrNew.new.text"       : "Never participated in organized hockey.",
    "clinics.detail.cart.existingOrNew.new.warning"    : "Before {create} and filling a {form}, please make sure that the member has never participated as a player, team staff (coach, safety, manager), referee or volunteer in any organized Hockey Organization",
    "clinics.detail.cart.existingOrNew.new.create"     : "creating a new member",
    "clinics.detail.cart.existingOrNew.new.form"       : "new member registration form",

    "clinics.detail.cart.participant.title"                          : "Select participant to register",
    "clinics.detail.cart.participant.category"                       : "Category",
    "clinics.detail.cart.participant.sessions"                       : "Sessions",
    "clinics.detail.cart.participant.qualification"                  : "Qualification",
    "clinics.detail.cart.participant.level"                          : "Level",
    "clinics.detail.cart.participant.noLevel"                        : "No level",
    "clinics.detail.cart.participant.prerequisitesRequired"          : "Prerequisites",
    "clinics.detail.cart.participant.showAll"                        : "Show all",
    "clinics.detail.cart.participant.show"                           : "Show",
    "clinics.detail.cart.participant.prerequisites.qualification"    : "Qualification's prerequisites",
    "clinics.detail.cart.participant.prerequisites.organization"     : "Organization's prerequisites",
    "clinics.detail.cart.participant.all.prerequisites"              : "Prerequisites",
    "clinics.detail.cart.participant.prerequisites.group"            : "Group #{index}",
    "clinics.detail.cart.participant.prerequisites.none"             : "No prerequisites",
    "clinics.detail.cart.participant.prerequisites.restrictions.all" : "All mandatory",
    "clinics.detail.cart.participant.prerequisites.restrictions.one" : "Only one mandatory",
    "clinics.detail.cart.participant.tax"                            : "tax",
    "clinics.detail.cart.participant.priceOneParticipant"            : "Price for one participant",
    "clinics.detail.cart.participant.selectParticipant"              : "Select a participant",
    "clinics.detail.cart.participant.search.select"                  : "Select a participant",
    "clinics.detail.cart.participant.notInHcr"                       : "This participant is not registered to HCR",
    "clinics.detail.cart.participant.registerToHcr"                  : "Register to HCR",
    "clinics.detail.cart.participant.prerequisitesInvalid"           : "This participant does not have the prerequisites",
    "clinics.detail.cart.participant.wrongOrganisation"              : "This participant does not belong to this organisation",
    "clinics.detail.cart.participant.viewClinics"                    : "View {ahm} clinics",
    "clinics.detail.cart.participant.search"                         : "Search HCR Participant",
    "clinics.detail.cart.participant.registerParticipant"            : "Register a participant",
    "clinics.detail.cart.participant.hcr"                            : "Search by HCR #",
    "clinics.detail.cart.participant.firstName.regex"                : "First name must be of a valid format",
    "clinics.detail.cart.participant.lastName.regex"                 : "Last name must be of a valid format",
    "clinics.detail.cart.participant.date.minAge"                    : "A member must be at least 4 years old to participate. However, you may still create this member.",
    "clinics.detail.cart.participant.date.notValid"                  : "The birthdate must respect the clinic's restrictions.",
    "clinics.detail.cart.participant.date.min"                       : "The clinic is for participants that are {age} years of age or older.",
    "clinics.detail.cart.participant.date.max"                       : "The clinic is for participants that are {age} years of age or younger.",
    "clinics.detail.cart.participant.date.minMax"                    : "The clinic is for participants that are between {minAge} years old and {maxAge} years old.",
    "clinics.detail.cart.participant.editAge"                        : "Click here if you wish to change the birthdate",
    "clinics.detail.cart.participant.date.minDate"                   : "Birthdate must be before today.",
    "clinics.detail.cart.participant.date.placeholder"               : "YYYY-MM-DD",
    "clinics.detail.cart.participant.alreadyInAccount"               : "This member is already in your account!",
    "clinics.detail.cart.participant.alreadyInOtherAccount"          : "This member is already in another account!",
    "clinics.detail.cart.participant.noResults"                      : "We could not find your profile.",
    "clinics.detail.cart.participant.resultsCreateMode"              : "We found matching {numberOfResults, plural, one {profile} other {profiles}}. Is this you?",
    "clinics.detail.cart.participant.noProfile"                      : "We could not find your profile, would you like to create a new participant?",
    "clinics.detail.cart.participant.noProfileSimple"                : "We could not find your profile. Please use the search filters above to change your search.",
    "clinics.detail.cart.participant.notMine"                        : "{numberOfResults, plural, one {This profile is not} other {None of these profiles are}} mine, proceed with the participant creation.",
    "clinics.detail.cart.participant.createParticipant"              : "Create a new participant",
    "clinics.detail.cart.participant.next"                           : "Start Registration",
    "clinics.detail.cart.participant.noParticipant"                  : "You have no participant in your Spordle Account",

    "clinics.detail.cart.participant.error.2000"        : "Click here to edit this member's first name and last name",
    "clinics.detail.cart.participant.startRegistration" : "Start registration",

    "clinics.detail.cart.participant.organization"          : "Organization",
    "clinics.detail.cart.participant.organization.required" : "Organization is required",

    "clinics.detail.cart.participant.attachments.required"         : "This document type is required",
    "clinics.detail.cart.participant.attachments.residency"        : "Proof of residency",
    "clinics.detail.cart.participant.attachments.birthCertificate" : "Birth certificate",

    // warning messages
    "clinics.detail.cart.participant.warnings.clinic_inactive"                                       : "The clinic is inactive",
    "clinics.detail.cart.participant.warnings.clinic_must_have_period"                               : "The clinic must be linked to a period",
    "clinics.detail.cart.participant.warnings.clinic_period_doesnt_exist_or_inactive"                : "The clinic's period does not exist or is inactive",
    "clinics.detail.cart.participant.warnings.invalid_clinic_status"                                 : "The clinic's status is inavlid",
    "clinics.detail.cart.participant.warnings.clinic_already_started"                                : "The clinic has already started",
    "clinics.detail.cart.participant.warnings.mandatory_fees_needed_for_clinic"                      : "Mandatory fees are needed for the clinic",
    "clinics.detail.cart.participant.warnings.clinic_soldout"                                        : "The clinic is sold out",
    "clinics.detail.cart.participant.warnings.clinic_must_have_terms_and_conditions"                 : "The clinic must have terms and conditions",
    "clinics.detail.cart.participant.warnings.member_has_already_clinic_qualification"               : "The clinic's qualification is already obtained by this member",
    "clinics.detail.cart.participant.warnings.inactive_member"                                       : "This member is inactive",
    "clinics.detail.cart.participant.warnings.temporary_member"                                      : "This member is a temporary member",
    "clinics.detail.cart.participant.warnings.clinic_unavailable_in_member_organisation"             : "The clinic is not available for this member's organization",
    "clinics.detail.cart.participant.warnings.member_need_background_check"                          : "This member needs a background check",
    "clinics.detail.cart.participant.warnings.member_need_background_check,member_need_prerequisite" : "This member needs a valid background check to register.",
    "clinics.detail.cart.participant.warnings.member_had_already_purchased_this_clinic"              : "This member has already purchased the clinic",
    "clinics.detail.cart.participant.warnings.missing_birthdate"                                     : "This member is missing a birthdate",
    "clinics.detail.cart.participant.warnings.member_doesnt_respect_min_age"                         : "This member does not meet the minimum age requirement for this clinic",
    "clinics.detail.cart.participant.warnings.member_doesnt_respect_max_age"                         : "This member does not meet the maximum age requirement for this clinic",
    "clinics.detail.cart.participant.warnings.need_all_prerequisites"                                : "This member must have all the prerequisites for this clinic",
    "clinics.detail.cart.participant.warnings.member_had_an_unwanted_qualification"                  : "This member had an unwanted qualification",
    "clinics.detail.cart.participant.warnings.member_need_prerequisite"                              : "This member must have the necessary prerequisites for this clinic",
    "clinics.detail.cart.participant.warnings.ERROR"                                                 : "Could not register this member",
    "clinics.detail.cart.participant.warnings.member_need_member_type"                               : "This member does not have the required member type for this clinic",
}
