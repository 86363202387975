import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import CookieWarning from '../components/cookieWarning/CookieWarning';
import { I18nContext } from '../contexts/I18nContext';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { getI18nLink, getI18nRoutePath } from '../helpers/i18nRoutes';
import withContexts from '../helpers/withContexts';
// Routes
import HomeRoutes from '../routes/homeRoutes';
import Footer from './layout-components/footer/footer';
// Components
import Header from './layout-components/header/header';

class Fulllayout extends React.Component{
    constructor(props){
        super(props);
        this.routes = [
            ...HomeRoutes,
        ];
    }

    // getSubDomaine = () => {
    //     return window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
    // }

    // getDemoBadge = () => {
    //     const subdomain = this.getSubDomaine();
    //     switch (subdomain){ // subdomain
    //         case "dev":
    //             return "DEV";
    //         case "hcr-demo":
    //             return `HCR DEMO (${process.env.REACT_APP_BUILD_DATE})`;
    //         case "sid-demo":
    //             return `SID DEMO (${process.env.REACT_APP_BUILD_DATE})`
    //         default:
    //             return "STAGE";
    //     }
    // }

    /*--------------------------------------------------------------------------------*/
    /*Life Cycle Hook, Applies when loading or resizing App                           */
    /*--------------------------------------------------------------------------------*/
    componentDidMount(){
        this.props.OrganizationContext.setFederation()
            .catch(console.error);
    }

    render(){
        /*--------------------------------------------------------------------------------*/
        /* Theme Setting && Layout Options will be Change From Here                       */
        /*--------------------------------------------------------------------------------*/
        return (
            <div
                id="main-wrapper"
                className="bg-light layout"
                // dir={themeValues.activeDir}
                // data-theme={themeValues.activeTheme}
                // data-layout={themeValues.activeThemeLayout}
                // data-sidebartype={themeValues.activeSidebarType}
                // data-sidebar-position={themeValues.activeSidebarPos}
                // data-header-position={themeValues.activeHeaderPos}
                // data-boxed-layout={themeValues.activeLayout}
            >
                <CookieWarning />

                <Header {...this.props} routes={this.routes} />

                {/* <Sidebar
					{...this.props}
					routes={this.routes}
				/> */}

                <div className="layout-content">
                    <I18nContext.Consumer>
                        {({ getGenericLocale }) => (
                            <Switch>
                                {this.routes.map((route, index) => {
                                    const path = getI18nRoutePath(route.path, route.defaultPath);
                                    if(route.redirect){
                                        const to = getI18nLink(route.pathTo, getGenericLocale(), route.defaultPathTo);
                                        // eslint-disable-next-line react/no-array-index-key
                                        return <Redirect from={path} to={to} key={index} exact={(route.exact)} />;
                                    }

                                    return <Route path={path} component={route.component} key={route.name} exact={(route.exact)} />;

                                })}
                            </Switch>
                        )}
                    </I18nContext.Consumer>
                </div>
                <Footer {...this.props} routes={this.routes} />
            </div>
        );
    }
}

export default withContexts(OrganizationContext)(Fulllayout)