export default{
    "home.hero.title.small"      : "Welcome to the Hockey Canada",
    "home.hero.title.big"        : "Registry",
    "home.hero.img.alt"          : "Four girls playing hockey",
    "home.hero.gameBack.img.alt" : "Our game is back",
    "home.lead.1"                : "Welcome to the Hockey Canada Registry, Hockey Canada’s national platform and hockey’s most extensive database for managing participant registration, team management, qualification tracking and much more. ",
    "home.lead.2"                : "The Registry is the primary hub for all hockey-related information and activities throughout Canada.",
    "home.learnMore"             : "Learn More",
    "home.card.play.title"       : "Register to play",
    "home.card.play.text"        : "to register for tryouts, minor and girl’s hockey, special events and more.",
    "home.card.clinic.title"     : "Register for clinics",
    "home.card.clinic.text"      : "to sign up for online and in-person courses.",
    "home.card.applyNow.title"   : "DONATE NOW",
    "home.card.applyNow.text"    : "100% of your contribution goes to keep kids in your community on the ice and in the game. {link} to donate today.",
    "home.card.btn"              : "Click here",
    "home.assistFunds.title"     : "GIVE AN ASSIST AND DONATE",
    "home.assistFunds.text.1"    : "We know how important an assist can be in hockey, but did you know an assist off the ice can be just as vital? Since 2020, the Hockey Canada Foundation Assist Fund has helped more than 8,000 kids get back in the game by providing up to $500 towards registration-fee subsidies. But this year, the need for assists is even greater. ",
    "home.assistFunds.text.2"    : "Your support for the Hockey Canada Foundation helps us share the game with all who share this land, because every child deserves the change to play.",
    "home.assistFunds.btn"       : "Donate",
    "home.partners.title"        : "Thank you to our partners",
}