export default{
    // Loading
    "confirm-account.loading.title"       : "Confirming",
    "confirm-account.loading.description" : "You will be redirected shortly ...",

    // Success
    "confirm-account.success.title" : "Account confirmed",

    // Failed
    "confirm-account.failed.title"  : "Oops, an error has occurred!",
    "confirm-account.failed.action" : "Back to Home",
    "confirm-account.error.server"  : "There was a problem. Please refresh the page and try again.",
    "confirm-account.error.204"     : "There was a problem. Please contact technical support.",
    "confirm-account.error.2000"    : "There was a problem. Please contact technical support.",
}