import clinicCartModalities from "./clinicCartModalities";
import clinicCartParticipants from "./clinicCartParticipants";
import clinicCartPaymentMethod from "./clinicCartGateway";
import clinicCartQuestions from "./clinicCartQuestions";
import clinicCartSummary from "./clinicCartSummary";
import clinicCartWaivers from "./clinicCartWaivers";
import clinicCartItems from "./clinicCartItems";
import clinicCartConfirmation from "./clinicCartConfirmation";
import clinicCartWaitingList from "./clinicCartWaitingList";
import clinicCartMissingFields from "./clinicCartMissingFields";

export default{
    ...clinicCartParticipants,
    ...clinicCartQuestions,
    ...clinicCartWaivers,
    ...clinicCartSummary,
    ...clinicCartModalities,
    ...clinicCartPaymentMethod,
    ...clinicCartItems,
    ...clinicCartConfirmation,
    ...clinicCartWaitingList,
    ...clinicCartMissingFields,

    "clinicCart.ineligibleWarning.title" : "Warning",
    "clinicCart.ineligibleWarning.back"  : "Participant selection",

    "clinicCard.confirmAddress.title" : "Address confirmation",
    "clinicCard.confirmAddress.title.phone" : "Phone confirmation",
}