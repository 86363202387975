import Translate from '@spordle/intl-elements';
import { stringBuilder } from "@spordle/helpers";
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import { Card, CardBody, CardText, CardTitle, Col, Container, Row } from 'reactstrap';
import I18nLink from '../../components/I18nRouting/I18nLink';
import ImgLoader from '../../components/loading/ImgLoader';
import Scrollspy from '../../components/Scrollspy';
import { I18nContext } from '../../contexts/I18nContext';
import RenderOrgs from './RenderOrgs';
import AdSlot from '../../components/advertisement/AdSlot';
import { destroyHCRSlots, initializeAdSlots } from '../../components/advertisement/adsHelper';

const Home = () => {
    const { getGenericLocale } = useContext(I18nContext);
    const [ adIsReady, setAdIsReady ] = useState(false);
    const intl = useIntl();

    useEffect(() => {
        initializeAdSlots(getGenericLocale());
        setAdIsReady(true);

        return () => {
            destroyHCRSlots();
        }
    }, []);

    return (
        <>
            <div
                className="home-hero hero"
                style={{ backgroundImage: "url(https://spordle-frontend-assets.s3.ca-central-1.amazonaws.com/hcrsite/homeHeroBg_desktop.jpg)" }}
            >
                <Container>
                    <Row className="align-items-center">
                        <Col lg="5">
                            <div className="hero-content">
                                <ImgLoader
                                    className="hero-logo"
                                    src={"https://spordle-frontend-assets.s3.ca-central-1.amazonaws.com/hcrsite/HC@2x.png"}
                                    alt="Hockey Canada"
                                    loadingLayout={<div className="hero-logo" style={{ height: 100, width: 110 }} />}
                                />
                                <h1 className="hero-text">
                                    <span className="hero-text-small font-title-small"><Translate id='home.hero.title.small' /></span>
                                    <span className={stringBuilder('home-hero-text-big hero-text-big font-title', { 'fr': getGenericLocale() === 'fr' })}><Translate id='home.hero.title.big' /></span>
                                </h1>
                            </div>
                        </Col>
                        <Col lg="7" className="d-none d-lg-block">
                            <ImgLoader
                                className="mw-100"
                                src={"https://spordle-frontend-assets.s3.ca-central-1.amazonaws.com/hcrsite/homeHeroImg.png"}
                                alt={intl.formatMessage({ id: 'home.hero.img.alt' })}
                                loadingLayout={<div style={{ height: 340 }} />}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            <section className="home-section-campaign font-title home-hashtag">
                <span className=''>#</span>
                <span className='hockey'>HOCKEY</span>
                <span className=''>CANADA</span>
            </section>
            <section className="py-5">
                <Container className="text-center">
                    <div className="mb-4">
                        <Scrollspy>
                            <p>
                                {/* this was originaly 2 paragraph, lyno asked to make it one hc might not like it */}
                                <Translate id='home.lead.1' />
                                <Translate id='home.lead.2' />
                            </p>
                        </Scrollspy>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="border-top flex-grow-1" />
                        <I18nLink
                            className="mx-3" id='learn-more'
                            path={{
                                en: '/about',
                                fr: '/a-propos',
                            }}
                            defaultPath='/about'
                        >
                            <Translate id='home.learnMore' />
                        </I18nLink>
                        <div className="border-top flex-grow-1" />
                    </div>
                </Container>
                <Container className="px-5 px-md-0">
                    <Scrollspy>
                        <div className='d-flex flex-wrap justify-content-center align-items-stretch px-4'>
                            <div className="d-flex home-layer-card-container my-4 mx-3">
                                <Card className="home-layer-card h-100" style={{ width: 325 }}>
                                    <ImgLoader
                                        className="home-layer-card-img card-img-top"
                                        src={"https://spordle-frontend-assets.s3.ca-central-1.amazonaws.com/hcrsite/homeRegisterToPlay.jpg"}
                                        alt="Hockey Player with equipment"
                                        loadingLayout={<Skeleton className="rounded-0" style={{ lineHeight: "unset" }} width={325} />}
                                    />
                                    <CardBody className="d-flex flex-column">
                                        <CardTitle tag="h2" className="font-title"><Translate id='home.card.play.title' /></CardTitle>
                                        <CardText>
                                            <I18nLink
                                                id='play' className="stretched-link font-medium mt-auto mr-1"
                                                path={{
                                                    en: '/register',
                                                    fr: '/inscription',
                                                }}
                                                defaultPath='/register'
                                            >
                                                <Translate id='home.card.btn' />
                                            </I18nLink>
                                            <Translate id='home.card.play.text' />
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="d-flex home-layer-card-container my-4 mx-3">
                                <Card className="home-layer-card h-100" style={{ width: 325 }}>
                                    <ImgLoader
                                        className="home-layer-card-img card-img-top"
                                        alt="Hockey player on ice"
                                        src={"https://spordle-frontend-assets.s3.ca-central-1.amazonaws.com/hcrsite/homeRegisterClinic.jpg"}
                                        loadingLayout={<Skeleton className="rounded-0" style={{ lineHeight: "unset" }} width={325} />}
                                    />
                                    <CardBody className="d-flex flex-column">
                                        <CardTitle tag="h2" className="font-title"><Translate id='home.card.clinic.title' /></CardTitle>
                                        <CardText>
                                            <I18nLink
                                                id='clinics' className="stretched-link font-medium mt-auto mr-1"
                                                path={{
                                                    en: '/clinics',
                                                    fr: '/stages',
                                                }}
                                                defaultPath='/clinics'
                                            >
                                                <Translate id='home.card.btn' />
                                            </I18nLink>
                                            <Translate id='home.card.clinic.text' />
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="d-flex home-layer-card-container my-4 mx-3">
                                <Card className="home-layer-card h-100" style={{ width: 325 }}>
                                    <ImgLoader
                                        className="home-layer-card-img card-img-top"
                                        alt="Joueur de hockey sur glace"
                                        src={"https://spordle-frontend-assets.s3.ca-central-1.amazonaws.com/hcrsite/HockeyCanada_NoviceSkills.png"}
                                        loadingLayout={<Skeleton className="rounded-0" style={{ lineHeight: "unset" }} width={325} />}
                                    />
                                    <CardBody className="d-flex flex-column">
                                        <CardTitle tag="h2" className="font-title"><Translate id='home.card.applyNow.title' /></CardTitle>
                                        <CardText>
                                            <Translate id='home.card.applyNow.text' values={{ link: <a className='stretched-link' href={getGenericLocale() === "fr" ? 'https://www.hockeycanada.ca/fr-ca/corporate/foundation/donate' : 'https://www.hockeycanada.ca/en-ca/corporate/foundation/donate'}><Translate id='home.card.btn' /></a> }} />
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </div>
                            <AdSlot
                                adIsReady={adIsReady}
                                className="d-flex justify-content-center mt-2"
                                minWidth={320}
                                minHeight={50}
                                adId={getGenericLocale() == "fr" ? "1699036793050" : "1699036745568"}
                            />
                        </div>
                    </Scrollspy>
                </Container>
            </section>
            <section className="home-layer-assist bg-primary py-5 text-light text-center">
                <Container className="px-5 px-md-0">
                    <Scrollspy><img src={"https://spordle-frontend-assets.s3.ca-central-1.amazonaws.com/hcrsite/logo_assist_fund.png"} alt="Logo Assist Fund" /></Scrollspy>
                    <Scrollspy><h2 className="text-uppercase font-weight-bold text-shadow font-title"><Translate id='home.assistFunds.title' /></h2></Scrollspy>
                    <Scrollspy><p className="text-shadow"><Translate id='home.assistFunds.text.1' /></p></Scrollspy>
                    <Scrollspy><p className="text-shadow mb-4"><Translate id='home.assistFunds.text.2' /></p></Scrollspy>
                    <Scrollspy>
                        <a
                            target='_blank'
                            rel='noopener noreferrer'
                            href={getGenericLocale() === "fr" ? "https://fondsaide.fondationhockeycanada.ca/fr/page/don.html" : "https://assistfund.hockeycanadafoundation.ca/en/page/donate.html"}
                            className="btn btn-light shadow-sm text-primary px-5 py-2"
                            id='donate'
                        >
                            <Translate id='home.assistFunds.btn' />
                        </a>
                    </Scrollspy>
                </Container>
            </section>
            {/* <h2 className="mb-4 font-title"><Translate id='home.partners.title'/></h2> */}
            <RenderOrgs />
        </>
    );
}

export default Home;