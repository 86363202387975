import { setUser } from '@sentry/react';
import { sendGAEvent, setGAUser, setGAUserProperties } from '@spordle/ga4';
import queryString from 'query-string';
import { Component, createContext } from 'react';
import { withRouter } from 'react-router';
import API_SPORDLE from "../api/API-Spordle";
import { serverError } from '../api/CancellableAPI';
import noProfileLogo from '../assets/images/no-profile.png';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../helpers/browserStorage';
import { I18nContext } from './I18nContext';
import API_ACCOUNT from '../api/API-Account';
import { getClients } from '../api/endpoints/clients';
// import { getClients } from '../api/endpoints/clients';

export const AuthContext = createContext();
AuthContext.displayName = 'AuthContext';

class AuthContextProvider extends Component{

    static contextType = I18nContext;

    constructor(props){
        super(props);

        this._initState = {
            // Spordle Accounts (cognito)
            type: queryString.parse(props.location.search).type || null,
            // getting the accessToken from the storage (this only applies in localhost since the token will never be set in any other environment)
            accessToken: queryString.parse(props.location.search).accessToken || getLocalStorageItem('accessToken') || '',
            userData: null,
            identityRoles: false,
            waitingForLogin: false,

            // Client data
            clients: null,
            spordleClient: null,
            pageUrl: null,
        }

        if(this._initState.accessToken){
            API_ACCOUNT.defaults.headers.common['X-Access-Token'] = this._initState.accessToken;
            API_SPORDLE.defaults.headers.common['X-Access-Token'] = this._initState.accessToken;

            // for localhost only
            // the access token will only be set / fetched in localhost development
            // it makes it a lot easier to open the registration modal after a refresh
            if(process.env.NODE_ENV === 'development'){
                setLocalStorageItem('accessToken', this._initState.accessToken)
            }
        }

        // Removing accessToken and type from the query params url
        // /page/some-id?accessToken=123&type=Bearer&otherParam=test#register
        // TO
        // /page/some-id?otherParam=test#register
        const parsedUrl = queryString.parseUrl(`${props.location.pathname}${props.location.search}${props.location.hash}`, { parseFragmentIdentifier: true/* parse hash(#) */ })
        delete parsedUrl.query.accessToken;
        delete parsedUrl.query.type;
        delete parsedUrl.query.lang;// Removeing for the I18nContext
        props.history.replace(queryString.stringifyUrl(parsedUrl))

        this.state = this._initState;
    }

    componentDidUpdate(prevState){
        if(this.state.userData){
            const attributes = { ...this.state.userData.attributes };
            delete attributes.logo;
            delete attributes.sub;
            setUser({
                email: this.state.userData.attributes.email,
                id: this.state.userData.userName,
                username: this.state.userData.userName,
                attributes: attributes,
            });
            setGAUser(this.state.userData.userName);
            setGAUserProperties(attributes);
        }else{
            setUser(null);
            setGAUser(null);
            setGAUserProperties(null);
        }
    }

    // Get the clients on load and set them in the state
    // Set other quick access data from the clients
    componentDidMount(){
        getClients()
            .then((clients) => {
                const spordleClient = clients.find((client) => client?.metadata?.client_code === "spordle");

                this.setState(() => ({
                    clients: clients,
                    spordleClient: spordleClient,
                    pageUrl: spordleClient ? spordleClient.metadata.products.PAGE.url : "https://page.spordle.com",
                }))
            })

        if(this.state.accessToken){
            this._authenticate()
                .then(async(identityId) => {
                    await this.getWaitingListNotifications(identityId)
                    this.setState((prevState) => ({
                        waitingForLogin: false,
                        accessToken: prevState.accessToken,
                    }))
                })
                .catch((error) => {
                    this._initState = {
                        type: null,
                        userData: null,
                        waitingForLogin: false,
                        accessToken: '',
                    }
                    delete API_SPORDLE.defaults.headers.common['X-Access-Token'];
                    delete API_ACCOUNT.defaults.headers.common['X-Access-Token'];
                    this.setState(() => this._initState);
                    removeLocalStorageItem('accessToken');
                    console.error(error);
                })
        }
    }

    /**
     * Auto authenticate when receiving access token in url
     * @param {string} [accessToken]
     * @private
     */
    _authenticate = (accessToken = this.state.accessToken) => {
        return API_SPORDLE.get('authentication/users?' + queryString.stringify({ access_token: accessToken }))
            .then((response) => {
                if(response.data.status){
                    const user = response.data.result;
                    const attributes = {
                        logo: noProfileLogo, // TODO: Replace with database logo
                    };
                    user.UserAttributes?.forEach((attribute) => {
                        attributes[attribute.Name] = attribute.Value;
                    })
                    this.setState(() => ({ userData: { attributes: attributes, userName: user.Username } }));
                    this.getUserIdentityRole(user.Username, true);
                    return user.Username
                }
                throw response.data.errors;

            }, serverError);
    }

    /**
     * Gets the primary meta member of the identity
     * @param {string} identityId ID of the Identity to get Meta Members from
     * @returns {Promise<object>}
     */
    getPrimaryMeta = (identityId = this.state.userData.userName, fromApi = false) => {
        if(!fromApi && this.state.primaryMeta){
            return Promise.resolve(this.state.primaryMeta);
        }

        return this.getMetaMembers(identityId, { is_primary: 1 })
            .then((meta) => {
                const primary = meta.find((m) => m.is_primary == "1");

                this.setState({ primaryMeta: primary });

                return primary;
            });
    }

    /**
     * Get's all the user's IdentityRoles
     * @param {string|undefined} userId The user we want to get the IdentityRoles from. If undefined then uses the userId in the state
     * @returns {Promise}
     */
    getUserIdentityRole = (userId = this.state.userData?.Username, getIdentityRoleObj = false) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `/authentication/users/${userId}/roles` }))
            .then((response) => {
                if(response.data.status){
                    if(getIdentityRoleObj){
                        return Promise.all(response.data.identity_roles
                            .map((idRole) => this.getIdentityRole(idRole.identity_role_id)))
                            .then((identityRoles) => {
                                this.setState({ identityRoles: identityRoles.length > 0 ? identityRoles : [] });
                                return identityRoles
                            })
                    }
                    this.setState({ identityRoles: response.data.identity_roles.length > 0 ? response.data.identity_roles : [] });
                    return response.data.identity_roles;
                }
                throw new Error(response.data.errors[0].code);
            }, serverError)
    }

    /**
     * Gets an identityRole by it's id
     * @param {string} identityRoleId
     * @returns {Promise}
     */
    getIdentityRole = (identityRoleId) => {
        return API_SPORDLE.get(`/rights/identity-roles/${identityRoleId}`)
            .then((response) => {
                if(response.data.status){
                    this.wasGod = response.data.identity_roles[0].identity?.power_user == '1';
                    return response.data.identity_roles[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * @typedef {'READ'|'ADD'|'EDIT'|'DELETE'|'IMPORT'|'EXPORT'} Actions
     */

    /**
     * Determines if this role grants the access to a given action in a given component
     * @param {Actions | Actions[]} action
     * @param {string} componentCode The component's code i.e 'dashboard'
     * @param {string} componentPermissionCode The component's code i.e 'organization_profile'
     * @param {boolean} skipAccess Bypass the "hasAccessTo" that checks if a READ is present. Used for componentPermissionCode that have no READ option. EX: members_outstanding_balance
     * @param {array} components this array contains the identity roles' access information
     * @returns {boolean} If the action is granted in this component
     */
    canDoAction = (action, componentCode, componentPermissionCode, skipAccess = false, components) => {
        if(!skipAccess && (Array.isArray(action) ? action.length > 0 : action) && componentCode && componentPermissionCode && !this.hasAccessTo(componentCode, componentPermissionCode, components))
            return false;

        return (components.find((comp) => comp.code === componentCode)
            ?.component_permissions.find((cp) => cp.code === componentPermissionCode)
            ?.role_component_permissions.findIndex((rcp) => (Array.isArray(action) ? action.includes(rcp.action) : rcp.action === action) && rcp.active === '1') ?? -1) !== -1;
    }

    /**
     * Determines if this role grants the access to a given component
     * @param {string} componentCode The component's code i.e 'dashboard'
     * @param {string|string[]} componentPermissionCode The component's code i.e 'organization_profile'
     * @returns {boolean} If the action is granted in this component
     */
    hasAccessTo = (componentCode, componentPermissionCode, components) => {
        const hasActiveRead = (rcp) => rcp.action === 'READ' && rcp.active == '1';

        const isValidComponentPermissionCode = (cpc) => {
            const componentPermission = component.component_permissions.find((permission) => permission.code === cpc);
            if(componentPermission && componentPermission.active === '1'){
                if(componentPermission.role_component_permissions.some(hasActiveRead))
                    return true;
            }
            return false;
        }

        const component = components.find((comp) => comp.code === componentCode);
        if(component && component.active === '1'){
            const isActivated = component.component_permissions.find((cp) => cp.visible_in_menu === '1' && cp.active === '1')?.role_component_permissions[0]?.active === '1';
            if(isActivated){
                if(componentPermissionCode){
                    if(Array.isArray(componentPermissionCode)){
                        if(componentPermissionCode.every(isValidComponentPermissionCode)){
                            return true;
                        }
                    }else if(isValidComponentPermissionCode(componentPermissionCode)){
                        return true;
                    }
                }else{
                    // Access to the component in general
                    for(let index = 0; index < component.component_permissions.length; index++){
                        if(component.component_permissions[index].role_component_permissions.some(hasActiveRead))
                            return true;
                    }
                }
            }
        }
        return false;
    }


    /**
     * Gets all the Meta Members from a specified Identity
     * @param {string} identityId ID of the Identity to get Meta Members from
     * @param {object} [queryParams] Query params of the call {@link https://api.id.dev.spordle.dev/documentations/#/Accounts/Apicontroller%5CAccounts%5CAccounts%3A%3AgetMetaMembers|documentation}
     * @returns {Promise}
     */
    getMetaMembers = (identityId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/accounts/${identityId}/meta-members`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.meta_members;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Logout the user
     * @returns {Promise}
     */
    signOut = () => {
        const deleteParam = new URLSearchParams({
            access_token: this.state.accessToken,
        });
        return API_SPORDLE.delete('/authentication/users/sign-out', { data: deleteParam })
            .then((response) => {
                sendGAEvent('log_out');
                if(response.data.status){
                    this._initState = {
                        type: null,
                        userData: null,
                        waitingForLogin: false,
                        identityRoles: false,
                        accessToken: '',
                    }
                    delete API_SPORDLE.defaults.headers.common['X-Access-Token'];
                    this.setState(() => this._initState);
                }
            }, serverError);
    }

    isLoggedIn = () => this.state.accessToken && this.state.userData;

    /**
     * Gets the waiting list notifications
     * @param {string} identityId ID of the identity to check for waiting list notifications
     * @returns {Promise}
     */
    getWaitingListNotifications = (identityId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/accounts/${identityId}/waiting-list-items`,
        }))
            .then((response) => {
                if(response.data.status){
                    this.setState((prevState) => ({ userData: { ...prevState.userData, waitingListNotifications: response.data.waiting_list_items } }));
                    return response.data.waiting_list_items
                }
                throw response.data.errors[0]
            }, serverError)
    }

    getAccountAddresses = (identityId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/accounts/${identityId}/addresses`,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.addresses;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    render(){
        return (
            <AuthContext.Provider value={{
                ...this.state,
                getPrimaryMeta: this.getPrimaryMeta,
                signOut: this.signOut,
                isLoggedIn: this.isLoggedIn,
                getUserIdentityRole: this.getUserIdentityRole,
                getWaitingListNotifications: this.getWaitingListNotifications,
                canDoAction: this.canDoAction,
                getAccountAddresses: this.getAccountAddresses,
            }}
            >
                {this.props.children}
            </AuthContext.Provider>
        );
    }
}

export default withRouter(AuthContextProvider);