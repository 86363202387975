import ClinicList from '../views/clinics/ClinicList';
import Home from '../views/home/Home';
import Register from '../views/register/Register';
import About from '../views/about/About';
import Support from '../views/support/Support';
import ClinicRedirect from '../views/clinics/ClinicRedirect';

var homeRoutes = [
    /*{
		exact: true, // must match exact path (example to let second level paths : /teams vs /teams/profile)
		navlabel: true,
		skipTranslate: true, // Skip translate to input custom text
		name: "Skip Translate", // skipTranslate==true
		name: "sidebar.home", // skipTranslate==false (translate i18n)
		skipSidebar: true, // skip rendering route in sidebar
		childPath: '/invoice', // on Sidebar element, place second level routes that should highlight this sidebar item
		customNavLink: TournamentAdminRoute, // To tell what component to use for a custom build NavLink
		userLevelExclude: [{userLevel: [FEDERATION, PROVINCIAL, CLUB, ACCOUNT], redirectTo: '/tournaments'},{userLevel: REGION, redirectTo: TournamentAdminRoute}] // These users will NOT have access
	},*/
    {
        defaultPath: '/clinics',
        path: {
            en: '/clinics',
            fr: '/stages',
        },
        name: 'Register for Clinics',
        component: ClinicList,
        exact: true,
    },
    {
        path: '/clinics/:id',
        name: 'Clinic Details',
        component: ClinicRedirect,
    },
    {
        path: '/clinic-details/:id',
        name: 'Clinic Details',
        component: ClinicRedirect,
    },
    {
        defaultPath: '/home',
        path: {
            en: '/home',
            fr: '/accueil',
        },
        name: 'Home',
        component: Home,
        exact: true,
    },
    {
        defaultPath: '/register',
        path: {
            en: '/register',
            fr: '/inscription',
        },
        name: 'Register',
        component: Register,
        exact: true,
    },
    {
        defaultPath: '/about',
        path: {
            en: '/about',
            fr: '/a-propos',
        },
        name: 'about',
        component: About,
        exact: true,
    },
    {
        defaultPath: '/support',
        path: {
            en: '/support',
            fr: '/soutien',
        },
        name: 'support',
        component: Support,
        exact: true
    },
    {
        path: '/',
        defaultPathTo: '/home',
        pathTo: {
            en: '/home',
            fr: '/accueil',
        },
        redirect: true,
        exact: true,
    },
];
export default homeRoutes;