import clinicCartModalities from "./clinicCartModalities";
import clinicCartParticipants from "./clinicCartParticipants";
import clinicCartPaymentMethod from "./clinicCartGateway";
import clinicCartQuestions from "./clinicCartQuestions";
import clinicCartSummary from "./clinicCartSummary";
import clinicCartWaivers from "./clinicCartWaivers";
import clinicCartItems from "./clinicCartItems";
import clinicCartConfirmation from "./clinicCartConfirmation";
import clinicCartWaitingList from "./clinicCartWaitingList";
import clinicCartMissingFields from "./clinicCartMissingFields";

export default{
    ...clinicCartParticipants,
    ...clinicCartQuestions,
    ...clinicCartWaivers,
    ...clinicCartSummary,
    ...clinicCartModalities,
    ...clinicCartPaymentMethod,
    ...clinicCartItems,
    ...clinicCartConfirmation,
    ...clinicCartWaitingList,
    ...clinicCartMissingFields,

    "clinicCart.ineligibleWarning.title" : "Attention",
    "clinicCart.ineligibleWarning.back"  : "Sélection de participant",

    "clinicCard.confirmAddress.title" : "Confirmation d'adresse",
    "clinicCard.confirmAddress.title.phone" : "Confirmation de téléphone",
}