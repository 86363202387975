import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { I18nContext } from '../../contexts/I18nContext';
import { getI18nLink } from '../../helpers/i18nRoutes';

const I18nLink = ({ path, tag: Tag, defaultPath, params, ...routeProps }) => {
    const { getGenericLocale } = useContext(I18nContext)
    return <Tag {...routeProps} to={getI18nLink(path, getGenericLocale(), defaultPath, params)} />;
}

I18nLink.defaultProps = {
    tag: Link,
}

export default I18nLink;
