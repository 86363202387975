import { useSpordleTable } from "@spordle/datatables";
import Translate from "@spordle/intl-elements";
import SpordleSelect from "@spordle/spordle-select";
import moment from "moment";
import { useRef, useState } from "react";
import DateTime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import { FormattedMessage } from "react-intl";
import {
    Label
} from "reactstrap";
import { DisplayI18n } from "../../../helpers/i18nHelper";

const ClinicListFilterDates = (props) => {
    const periodsSelectRef = useRef(null);

    const [ startDateError, setStartDateError ] = useState('');
    const [ endDateError, setEndDateError ] = useState('');
    const spordleTable = useSpordleTable();

    const isValidStartEndDate = (date, isStartDate) => {
        if(isStartDate){
            return spordleTable.getFilters().endDate && moment.isMoment(spordleTable.getFilters().endDate) ? date.isBefore(moment(spordleTable.getFilters().endDate).add(1, 'day')) : true
        }
        return spordleTable.getFilters().startDate && moment.isMoment(spordleTable.getFilters().startDate) ? date.isAfter(moment(spordleTable.getFilters().startDate).subtract(1, 'day')) : true

    }

    const isValidPeriodDate = (date) => {
        const currentPeriod = periodsSelectRef.current?.getSpordleTable().getData().find((option) => option.value === periodsSelectRef.current?.state.selectedOptions[0]);
        if(currentPeriod){
            return date.isBetween(moment(currentPeriod.startDate).subtract(1, 'day'), moment(currentPeriod.endDate).add(1, 'day'))
        }
        return true

    }

    const isDisabled = () => spordleTable.state.loadingState === 'loading' || spordleTable.state.loadingState === 'lazy' || props.fieldsDisabled

    return (
        <div className="border-bottom justify-content-between">
            <div className="pb-2 mb-3">
                <Label for={spordleTable.generateId('filters', 'period', 'periodId')} className="font-weight-bold"><Translate id='clinics.filters.period' /></Label>
                <SpordleSelect
                    ref={periodsSelectRef}
                    id={spordleTable.generateId('filters', 'period', 'periodId')} name='periodId'
                    disabled={isDisabled()}
                    values={spordleTable.getFilters().periodId ? [ spordleTable.getFilters().periodId ] : props.initialPeriodId ? [ props.initialPeriodId ] : []}
                    options={
                        [
                            {
                                label: "clinics.filters.allPeriods",
                                i18n: null,
                                value: 'ALL',
                                current: 0,
                            },
                        ].concat((props.initialPeriodId && props.periods) ? props.periods.reduce((newArray, period) => {
                            if(period.active == 1){
                                newArray.push({
                                    value: period.period_id,
                                    label: period.name,
                                    i18n: period.i18n,
                                    startDate: period.start_date,
                                    endDate: period.end_date,
                                    current: period.current,
                                })
                            }
                            return newArray
                        }, []) : [])
                    }
                    renderOption={({ option }) => (
                        <>
                            {option.value !== 'ALL' ?
                                <>
                                    <DisplayI18n field="name" defaultValue={option.label} i18n={option.i18n} />
                                    {(option.current == 1) && <div className='small text-muted'><Translate id='misc.current' /></div>}
                                </>
                                :
                                <Translate id={option.label} />
                            }
                        </>
                    )}
                    onOptionSelected={(values) => {
                        spordleTable.filterChange('periodId', values[0]);
                    }}
                />
            </div>

            <div className="pb-2 mb-3">
                <div className="d-flex">
                    <Label for="startDate" className="font-weight-bold"><Translate id='clinics.filters.startDate' /></Label>
                    {spordleTable.getFilters().startDate && // if startDate is filtered
                        <button onClick={() => spordleTable.filterChange('startDate', '')} className="ml-auto reset-btn text-muted" type="button" id={spordleTable.generateId('filters', 'startDate', 'clear')}>
                            <Translate id='misc.clear' /><i className="mdi mdi-close align-middle ml-1" />
                        </button>
                    }
                </div>
                <DateTime
                    key={spordleTable.getFilters().startDate}
                    initialValue={spordleTable.getFilters().startDate}
                    id={spordleTable.generateId('filters', 'startDate')}
                    name='startDate'
                    className="filter-datetime"
                    dateFormat='YYYY-MM-DD'
                    timeFormat={false}
                    onChange={(date) => {
                        if(moment.isMoment(date)){
                            // is between period dates
                            if(isValidPeriodDate(date)){
                                // is before end date
                                if(isValidStartEndDate(date, true)){
                                    setStartDateError('')
                                    spordleTable.filterChange('startDate', date)
                                }else{
                                    spordleTable.filterChange('endDate', '');
                                }
                            }else{
                                setStartDateError('clinics.filters.startDate.between')
                            }
                        }else if(date === ''){
                            setStartDateError('')
                            spordleTable.filterChange('startDate', date)
                        }else{
                            setStartDateError('form.validation.date.format')
                        }
                    }}
                    renderInput={(props) => (
                        <FormattedMessage id='clinics.filters.date.placeholder'>
                            {(message) => (
                                <div className='search-wrapper'>
                                    <input {...props} placeholder={message} disabled={isDisabled()} />
                                </div>
                            )}
                        </FormattedMessage>
                    )}
                />
                {startDateError &&
                    <span className='text-danger small'><Translate id={startDateError} /></span>
                }
            </div>
            <div className="border-md-bottom pb-3 mb-3">
                <div className="d-flex">
                    <Label for="endDate" className="font-weight-bold"><Translate id='clinics.filters.endDate' /></Label>
                    {spordleTable.getFilters().endDate && // if endDate is filtered
                        <button onClick={() => spordleTable.filterChange('endDate', '')} className="ml-auto reset-btn text-muted" type="button" id={spordleTable.generateId('filters', 'endDate', 'clear')}>
                            <Translate id='misc.clear' /><i className="mdi mdi-close align-middle ml-1" />
                        </button>
                    }
                </div>
                <DateTime
                    key={spordleTable.getFilters().endDate}
                    initialValue={spordleTable.getFilters().endDate}
                    id={spordleTable.generateId('filters', 'startDate')}
                    name='endDate'
                    className="filter-datetime"
                    dateFormat='YYYY-MM-DD'
                    timeFormat={false}
                    onChange={(date) => {
                        if(moment.isMoment(date)){
                            // is between period dates
                            if(isValidPeriodDate(date)){
                                // is after start date
                                if(isValidStartEndDate(date, false)){
                                    setEndDateError('')
                                    spordleTable.filterChange('endDate', date)
                                }else{
                                    setEndDateError('clinics.filters.endDate.before')
                                }
                            }else{
                                setEndDateError('clinics.filters.endDate.between')
                            }
                        }else if(date === ''){
                            setEndDateError('')
                            spordleTable.filterChange('endDate', date)
                        }else{
                            setEndDateError('form.validation.date.format')
                        }
                    }}
                    isValidDate={(current) => isValidPeriodDate(current) && isValidStartEndDate(current, false)}
                    renderInput={(props) => (
                        <FormattedMessage id='clinics.filters.date.placeholder'>
                            {(message) => (
                                <div className='search-wrapper'>
                                    <input {...props} placeholder={message} disabled={isDisabled()} />
                                </div>
                            )}
                        </FormattedMessage>
                    )}
                />
                {endDateError &&
                    <span className='text-danger small'><Translate id={endDateError} /></span>
                }
            </div>
        </div>
    )
}
export default ClinicListFilterDates;