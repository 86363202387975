import { useContext } from 'react';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';

// I18N
import { LOCALES } from '../../../i18n';
import Translate from '@spordle/intl-elements';
import { I18nContext } from '../../../contexts/I18nContext';
import LogoHcr from '../../../components/LogoHcr';
import getReferrer from '../../../helpers/getReferer';
import { AuthContext } from '../../../contexts/AuthContext';
import { stringifyUrl } from 'query-string';
import { getLangSwitcherLink } from '../../../helpers/i18nRoutes';

const Footer = () => {
    return (
        <footer className="bg-dark text-light text-center text-md-left">
            <Container className="container-lg">
                <div className="pt-4 mb-4 pt-md-5 mb-md-5 d-flex align-items-center justify-content-between">
                    <Link to='/' className="reset-a" id='footer-home'>
                        <LogoHcr />
                    </Link>
                    <a href={getReferrer('HCR')} className="btn btn-primary" rel='noopener noreferrer' id='hcr'>
                        <Translate id='footer.adminBtn' />
                    </a>
                </div>

                <hr className="border-top my-4 my-md-3 d-none d-md-block" />{/* desktop hr */}

                <div className="d-block d-md-flex flex-md-wrap">
                    <p className="mb-4 mr-md-5">
                        <small className="text-muted">
                            <Translate id='footer.copyright' values={{ year: new Date().getFullYear() }} />
                        </small>
                    </p>
                    <hr className="w-25 border-top my-4 d-md-none" />{/* mobile hr */}
                    <I18nContext.Consumer>
                        {({ locale }) => (
                            <p className="mb-0 pb-4 d-flex flex-column align-items-center flex-md-row">
                                <a
                                    href={locale === 'fr-ca' ? 'https://www.spordle.com/fr/confidentialite/' : 'https://www.spordle.com/privacy/'}
                                    className="reset-a hover-opacity"
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    id='privacy'
                                >
                                    <Translate id='footer.privacy' />
                                </a>
                                <span className="mx-3 d-none d-md-inline">|</span>
                                <a
                                    href={locale === 'fr-ca' ? 'https://www.spordle.com/fr/termes/' : 'https://www.spordle.com/terms/'}
                                    className="reset-a hover-opacity"
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    id='terms'
                                >
                                    <Translate id='footer.terms' />
                                </a>
                            </p>
                        )}
                    </I18nContext.Consumer>
                    <LangSwitcher className='hover-opacity ml-auto pb-4 reset-a' id='footer-lang-switcher' />
                </div>
            </Container>
        </footer>
    );
}

const LangSwitcher = (props) => {
    const auth = useContext(AuthContext);
    const { locale, getGenericLocale } = useContext(I18nContext);
    const lang = locale === LOCALES.FRENCH ? 'en' : 'fr';
    return (
        <a
            {...props}
            hrefLang={lang}
            href={stringifyUrl({
                url: getLangSwitcherLink(getGenericLocale() == 'fr' ? 'en' : 'fr'),
                query: {
                    lang: process.env.REACT_APP_ENVIRONMENT === 'prod' ? undefined : lang,
                    accessToken: auth.accessToken,
                },
            }, {
                skipNull: true,
                skipEmptyString: true,
            })}
        >
            {locale === LOCALES.FRENCH ? 'English' : 'Français'}
        </a>
    );
}

export default Footer;