import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import { jsObjectToApi } from '@spordle/helpers';

/** @type {React.Context<Omit<AccountsContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>} */
export const AccountsContext = createContext();
AccountsContext.displayName = 'AccountsContext';

class AccountsContextProvider extends React.Component{

    /**
     * Gets all the Meta Members from a specified Identity
     * @param {string} identityId ID of the Identity to get Meta Members from
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Accounts/Apicontroller%5CAccounts%5CAccounts%3A%3AgetMetaMembers|documentation}
     * @returns {Promise.<Array>}
     */
    getIdentityMetaMembers = (identityId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/accounts/${identityId}/meta-members`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.meta_members
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Gets all the Members from a specified Identity for a specified Clinic
     * @param {string} identityId ID of the Identity to get Members from
     * @param {string} clinicId ID of the Clinic to get restrictions from
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Accounts/Apicontroller%5CAccounts%5CAccounts%3A%3AgetMetaMembers|documentation}
     * @returns {Promise.<Array>}
     */
    getIdentityMembersForClinic = (identityId, clinicId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/accounts/${identityId}/clinics/${clinicId}`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.members
                }
                throw response.data.errors[0]
            }, serverError)
    }

    getIdentityMetaMember = (identityId, metaMemberId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `/accounts/${identityId}/meta-members/${metaMemberId}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.meta_members[0]
                }
                throw response.data.errors[0]
            }, serverError)
    }

    createMetaMember = (identityId, firstName, lastName, birthdate, relation, memberId) => {
        const params = new URLSearchParams();
        params.append('first_name', firstName);
        params.append('last_name', lastName);
        if(relation) params.append('relation', relation);
        if(birthdate) params.append('birthdate', birthdate);
        if(memberId) params.append('member_id', memberId)

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/accounts/${identityId}/meta-members` }), params)
            .then((response) => {
                if(response.data.status){
                    return {
                        meta_member_id: response.data.meta_member_id,
                        warnings: response.data.warnings,
                    }
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Links an Identity to a Meta Member
     * @param {string} identityId ID of the Identity to link to a Meta Member
     * @param {string} metaMemberId ID of the Meta Member to link an Identity to
     * @param {Array} members
     * @returns {Promise}
     */
    linkMemberToMetaMember = (identityId, metaMemberId, members) => {
        const params = new URLSearchParams();
        members.forEach((memberId, index) => {
            params.append(`members[${index}]`, memberId)
        })

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `/accounts/${identityId}/meta-members/${metaMemberId}/members` }), params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0]
            })
    }

    cancelWaitingListItem = (identityId, waitingListItemId, values) => {
        const params = new URLSearchParams();
        jsObjectToApi(values, params);

        return API_SPORDLE.patch(`/accounts/${identityId}/waiting-list-items/${waitingListItemId}/cancel`, params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <AccountsContext.Provider value={{ ...this }}>
                {this.props.children}
            </AccountsContext.Provider>
        )
    }
}

export default AccountsContextProvider;