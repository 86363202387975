import { Component, createContext } from 'react';

export const ThemeContext = createContext();

ThemeContext.displayName = 'ThemeContext';

class ThemeContextProvider extends Component{
    state = {
        activeTheme: "light",

        themes: {
            light: {
                activeDir: "ltr",
                activeThemeLayout: "vertical",
                activeTheme: "light",
                activeSidebarType: "full",
                activeLogoBg: "skin6",
                activeNavbarBg: "skin6",
                activeSidebarBg: "skin5",
                activeSidebarPos: "fixed",
                activeHeaderPos: "fixed",
                activeLayout: "full",
            },
            dark: {
                activeDir: "ltr",
                activeThemeLayout: "vertical",
                activeTheme: "dark",
                activeSidebarType: "full",
                activeLogoBg: "skin5",
                activeNavbarBg: "skin5",
                activeSidebarBg: "skin5",
                activeSidebarPos: "fixed",
                activeHeaderPos: "fixed",
                activeLayout: "full",
            },
            blue: {
                activeDir: "ltr",
                activeThemeLayout: "vertical",
                activeTheme: "blue",
                activeSidebarType: "full",
                activeLogoBg: "skin1",
                activeNavbarBg: "skin1",
                activeSidebarBg: "skin3",
                activeSidebarPos: "fixed",
                activeHeaderPos: "fixed",
                activeLayout: "full",
            },
            green: {
                activeDir: "ltr",
                activeThemeLayout: "vertical",
                activeTheme: "green",
                activeSidebarType: "full",
                activeLogoBg: "skin2",
                activeNavbarBg: "skin2",
                activeSidebarBg: "skin2",
                activeSidebarPos: "fixed",
                activeHeaderPos: "fixed",
                activeLayout: "full",
            },
        },
    }

    setTheme = (theme) => {
        this.setState({
            activeTheme: this.getThemeName(theme),
        });
    }

    getThemeName = (theme) => {
        switch (theme){
            case 'light':
            case 'dark':
            case 'blue':
            case 'green':
                return theme;
            default:
                return 'light';
        }
    }

    getTheme = () => {
        return this.state.themes[this.state.activeTheme];
    }

    render(){
        return (
            <ThemeContext.Provider value={{ ...this.state, setTheme: this.setTheme, getTheme: this.getTheme }}>
                {this.props.children}
            </ThemeContext.Provider>
        );
    }
}
export default ThemeContextProvider;