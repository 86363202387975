import { useContext, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Container, Spinner } from "reactstrap";
import { ClinicsContext } from "../../contexts/ClinicsContext";
import { OrganizationContext } from "../../contexts/OrganizationContext";
import { AuthContext } from "../../contexts/AuthContext";
import { useState } from "react";
import Translate from "@spordle/intl-elements";

const ClinicRedirect = () => {
    const { id } = useParams();
    const { getClinic } = useContext(ClinicsContext);
    const { getOrganization } = useContext(OrganizationContext);
    const { pageUrl } = useContext(AuthContext);
    const [ error, setError ] = useState(false);

    const getBranch = (org) => {
        if(!org){
            return null;
        }

        if([ "FEDERATION", "BRANCH" ].includes(org.organisation_category?.category_system)){
            return org;
        }

        return (org.breadcrumbs || []).find(({ organisation_category }) => organisation_category.category_system === "BRANCH");
    }

    const getOrgPageUrl = (shortName, clinicId) => {
        if(shortName && clinicId) {
            try {
                // The short name should be the same as the org's Page short name.
                // Might differ on test environments.
                return new URL(`${shortName}/clinics/${clinicId}`, pageUrl).href;
            } catch(e) {
                console.error(e);
            }
        }

        return "";
    };

    const redirectToClinic = async () => {
        let url;

        try {
            const clinic = await getClinic(id, true);
            const org = await getOrganization(clinic?.organisation?.organisation_id);
            const branch = getBranch(org);
            url = getOrgPageUrl(branch.short_name, clinic.clinic_id);
        } catch(e) {
            console.error(e);
        }

        if(url) {
            window.location = url;
        } else {
            setError(true);
        }
    }

    useEffect(() => {
        if(pageUrl) {
            redirectToClinic();
        }
    }, [ pageUrl ]);

    return (
        <Container className="py-5 container-lg d-flex align-items-center flex-column justify-content-center">
            {error ?
                <>
                    <h1 className="display-3 font-weight-bold">404</h1>
                    <p><Translate id="clinics.clinicDetails.404" /></p>
                    <Link to="/clinics" className="btn btn-dark"><Translate id="clinics.clinicDetails.list" /></Link>
                </>
            :
                <>
                    <Spinner type="grow" size="lg" color="primary" />
                    <div className="mt-2"><Translate id="clinics.clinicDetails.redirecting" /></div>
                </>
            }
        </Container>
    );
}

export default ClinicRedirect;