export default{
    "misc.en"        : "Anglais",
    "misc.fr"        : "Français",
    "misc.english"   : "Anglais",
    "misc.french"    : "Français",
    "misc.logo.text" : "Registre",

    "misc.success" : "Success",
    "misc.warning" : "Warning",
    "misc.danger"  : "Error",
    "misc.info"    : "Info",

    "misc.clear"                                : "Effacer",
    "misc.select"                               : "Sélectionner",
    "misc.select.placeholder"                   : "Sélectionner...",
    "misc.select.suggested"                     : "Suggéré",
    "misc.SimpleSearchTable.search.placeholder" : "Rechercher",
    "misc.price"                                : "Prix",
    "misc.more"                                 : "Plus",
    "misc.before"                               : "Avant",
    "misc.after"                                : "Après",
    "misc.or"                                   : "Ou",
    "misc.priceValue"                           : "{price} $",
    "misc.loading"                              : "Chargement...",
    "misc.error"                                : "Une erreur s'est produite",
    "misc.yes"                                  : "Oui",
    "misc.no"                                   : "Non",
    "misc.ok"                                   : "Ok",
    "misc.noSelection"                          : "Aucune sélection possible",
    "misc.noResults"                            : "Aucun résultat",
    "misc.search"                               : "Rechercher",
    "misc.searchResults"                        : "Résultat(s) de recherche",
    "misc.moreFilter"                           : "Afficher les filtres",
    "misc.lessFilter"                           : "Masquer les filtres",
    "misc.noTranslation"                        : "Aucune traduction disponible",
    "misc.optional"                             : "Optionnel",
    "misc.hcr#"                                 : "# HCR ",
    "misc.retry"                                : "Réessayer",
    "misc.fatalError.goback"                    : "Revenir sur la plateforme",
    "misc.yearsOld"                             : "{age} ans",

    "misc.googlePlaces.open"                    : "Ouvrir dans Google Maps",
    "misc.googlePlaces.placeholder"             : "Indiquez un emplacement",
    "misc.googlePlaces.manualAddress.toggleOn"  : "Entrer l'adresse manuellement",
    "misc.googlePlaces.manualAddress.toggleOff" : "Indiquer l'adresse avec l'assistant Google",

    "misc.previous" : "Précédent",
    "misc.next"     : "Suivant",
    "misc.add"      : "Ajouter",
    "misc.create"   : "Créer",
    "misc.remove"   : "Retirer",
    "misc.edit"     : "Modifier",
    "misc.confirm"  : "Confirmer",
    "misc.cancel"   : "Annuler",
    "misc.current"  : "Courant",

    "misc.yrs"              : "ans",
    "misc.gender"           : "Identité de genre",
    "misc.female"           : "Femme",
    "misc.male"             : "Homme",
    "misc.nonBinary"        : "Non Binaire",
    "misc.nonSpecified"     : "Je préfère ne pas répondre",
    "misc.preferToDescribe" : "Je préfère me décrire moi-même",

    "misc.notSupported.title"  : "Ce fureteur n'est pas supporté",
    "misc.notSupported.text.1" : "La version de votre fureteur n'est pas supportée par notre plateforme",
    "misc.notSupported.text.2" : "SVP, veuillez le mettre à jour ou essayez un différent fureteur",

    // Clipboard helper
    "misc.clipboard.success"         : "Copié dans le presse-papier!",
    "misc.clipboard.address.success" : "Adresse copiée!",

    "misc.clipboard.error" : "Impossible de copier dans le presse-papiers",
}