import { useSpordleTable } from "@spordle/datatables";
import { FormikInputNumber } from "@spordle/formik-elements";
import { debounce, stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { Formik, Form } from "formik";
import { useCallback, useState } from "react";
import "react-datetime/css/react-datetime.css";
import { Label } from "reactstrap";
import ClinicListFilterCategories from "./ClinicListFilterCategories";
import ClinicListFilterDates from "./ClinicListFilterDates";
import ClinicListFilterStatusFormat from "./ClinicListFilterStatusFormat";

const ClinicListFilter = (props) => {
    const [ isOpen, setIsOpen ] = useState(false);
    const [ currentClinicReference, setCurrentClinicReference ] = useState('');
    const spordleTable = useSpordleTable();

    const toggleCollapse = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen)
    };

    const debouncedSearchChange = useCallback(debounce(spordleTable.filterChange, 300), []);

    return (
        <section className="mb-5 mb-lg-0">
            <div className="pb-3 border-bottom mb-3 d-block d-lg-none">
                <button className="btn btn-primary" type='button' onClick={toggleCollapse} id={spordleTable.generateId('more-filters', 'main')}>
                    <i className="mdi mdi-filter-variant mr-1" /><Translate id='clinics.filters' />
                </button>
            </div>
            <div className={stringBuilder('filter-form-container offset-menu-container', { 'is-open': isOpen })}>
                <div className='filter-form offset-menu'>
                    <div className="filter-form-header d-flex justify-content-between align-items-center bg-dark text-light d-lg-none">
                        <h3 className="h5 mb-0 d-inline-block"><Translate id='clinics.filters.refine' /></h3>
                        <button onClick={toggleCollapse} type='button' className="d-inline-block btn text-light" id={spordleTable.generateId('more-filters', 'close')}>
                            <i className="mdi mdi-close" />
                        </button>
                    </div>
                    <div className="filter-form-content">

                        {/* <ClinicFilterCity/> */}

                        {/* Clinc reference number Filter */}
                        <div>
                            <Formik
                                initialValues={{
                                    clinicReference: spordleTable.getFilters().clinicReference || '',
                                }}
                            >
                                <Form>
                                    <div>
                                        <Label for={spordleTable.generateId('filters', 'clinic', 'clinicReference')} className="font-weight-bold"><Translate id='clinics.filters.clinic_reference' /></Label>
                                        <FormikInputNumber
                                            id={spordleTable.generateId('filters', 'clinic', 'clinicReference')}
                                            name='clinicReference'
                                            onChange={(val) => {
                                                setCurrentClinicReference(val.target.value || '');
                                                debouncedSearchChange('clinicReference', val.target.value)
                                            }}
                                        />
                                    </div>
                                </Form>
                            </Formik>
                            <div className='d-flex align-items-center mt-2 mb-1'>
                                <div className='border-top flex-grow-1' />
                                <div className='px-3 font-medium'><Translate id='clinics.filters.orSeperator' /></div>
                                <div className='border-top flex-grow-1' />
                            </div>
                        </div>

                        <ClinicListFilterCategories fieldsDisabled={currentClinicReference !== ''} periods={props.periods} />

                        <ClinicListFilterDates fieldsDisabled={currentClinicReference !== ''} periods={props.periods} initialPeriodId={props.initialPeriodId} />

                        <ClinicListFilterStatusFormat fieldsDisabled={currentClinicReference !== ''} />

                        <div className="mt-3 text-right d-lg-none">
                            <button className="btn btn-primary" type='button' onClick={toggleCollapse} id={spordleTable.generateId('more-filters', 'menu')}>
                                <Translate id='clinics.filters' />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default ClinicListFilter;