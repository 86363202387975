export default{
    // general
    "clinics.location.ONLINE"      : "Online - Recorded/On-Demand",
    "clinics.location.ONLINE_LIVE" : "Online - Live",
    "clinics.location.ONSITE"      : "In Person",

    "clinics.status.OPEN_PUBLIC_SIGNUP"    : "Open for registration",
    "clinics.status.OPEN_PUBLIC_SHOW_LIST" : "Open to the public",
    "clinics.status.PRIVATE"               : "Private",
    "clinics.status.DRAFT"                 : "Draft",
    "clinics.status.UNDER_CREATION"        : "Under creation",

    "clinics.date"                 : "Date",
    "clinics.dates"                : "Dates",
    "clinics.date.to"              : "To",
    "clinics.date.from"            : "From",
    "clinics.date.until"           : "Until",
    "clinics.hostOrganization"     : "Host Organization",
    "clinics.category"             : "Category",
    "clinics.qualification"        : "Qualification",
    "clinics.level"                : "Level",
    "clinics.noLevel"              : "No level",
    "clinics.description"          : "Description",
    "clinics.noDescription"        : "No description",
    "clinics.soldOut"              : "Sold out",
    "clinics.soldOutWaitingList"   : "Sold out - Waiting list",
    "clinics.click.here.to.manage" : "Click here to",
    "clinics.manage"               : "Manage the clinic in SID",

    "clinics.firstSession" : "First session (out of {sessions})",

    // table
    "clinics.table.paginationMessage"       : "Clinics {itemStartPos} to {itemEndPos} out of a total of {totalItems} clinics",
    "clinics.table.emptyLayout.noResult"    : "No results...",
    "clinics.table.emptyLayout.noResultTip" : "You can search or use the filters",

    // filters
    "clinics.filters.categories"        : "Categories",
    "clinics.filters.qualification"     : "Qualification",
    "clinics.filters.period"            : "Season",
    "clinics.filters.allPeriods"        : "All Seasons",
    "clinics.filters.clinic_reference"  : "Reference Number",
    "clinics.filters.startDate"         : "Start date",
    "clinics.filters.startDate.between" : "Start date must be in the date range of the selected period",
    "clinics.filters.startDate.before"  : "Start date must be before the end date",
    "clinics.filters.endDate"           : "End date",
    "clinics.filters.endDate.between"   : "End date must be in the date range of the selected period",
    "clinics.filters.endDate.before"    : "End date must be after the start date",
    "clinics.filters.date.placeholder"  : "YYYY-MM-DD",
    "clinics.filters.favoritesOnly"     : "Favorites only",
    "clinics.filters.priceRange"        : "Price range",
    "clinics.filters.status"            : "Status",
    "clinics.filters.sessionFormat"     : "Session format",
    "clinics.filters.orSeperator"       : "or",

    "clinics.filters.resetFilters" : "Reset filters",
    "clinics.filters.clearAll"     : "Clear all",
    "clinics.filters"              : "Filters",
    "clinics.filters.refine"       : "Refine Clinic Search",

    // branch select
    "clinics.branchSelect.selectBranch" : "Select your organization",
    "clinics.branchSelect.selectOrg"    : "Select an organization...",

    // hero
    "clinics.hero.find"        : "Find your Clinic",
    "clinics.hero.find.1"      : "Find your",
    "clinics.hero.find.2"      : "Clinic",
    "clinics.hero.presentedBy" : "Presented by",
    "clinics.hero.placeholder" : "Search by clinic number",
}