import { useEffect } from "react";
import styles from "./AdSlot.module.scss";
import { stringBuilder } from "@spordle/helpers";

const AdSlot = ({ adId, minWidth = "320px", minHeight = "50px", className, adIsReady }) => {
    const id = `div-gpt-ad-${adId}-0`;

    const displayAd = () => {
        const googletag = window.googletag || { cmd: [] };
        googletag.cmd.push(function(){
            console.log("DISPLAY", id);
            googletag.display(id);
        });
    };

    useEffect(() => {
        if(adIsReady || typeof adIsReady === "undefined"){
            displayAd();
        }
    }, [ adId, adIsReady ]);

    return (
        <div
            id={id}
            style={{
                minWidth: minWidth,
                minHeight: minHeight
            }}
            className={stringBuilder(styles.ad, className)}
        />
    );
}

export default AdSlot;