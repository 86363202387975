export default{
    "forgotPassword.title.accountRecovery" : "Récupération du compte",

    "forgotPassword.label.enterEmail"         : "Pour réinitialiser votre mot de passe, veuillez entrer votre adresse courriel",
    "forgotPassword.label.noEmail"            : "Vous n'avez pas reçu de courriel?",
    "forgotPassword.label.enterNewPassword"   : "Veuillez entrer votre nouveau mot de passe",
    "forgotPassword.label.confirmNewPassword" : "Confirmez votre nouveau mot de passe",

    "forgotPassword.action.backToLogin"        : "Retour au login",
    "forgotPassword.action.sendEmail"          : "Envoyer un courriel",
    "forgotPassword.action.confirmNewPassword" : "Confirmer mon nouveau mot de passe",

    "forgotPassword.success.emailSent"       : "Un courriel de récupération à été envoyé à l'adresse courriel fournie. Veuillez cliquez sur le lien trouvé dans le courriel pour réinitialiser votre mot de passe.",
    "forgotPassword.success.emailSent.toast" : "Un courriel a été envoyé!",
    "forgotPassword.success.newPassword"     : "Votre mot de passe à été changé!",

    "forgotPassword.error.genericError"  : "Un problème est survenu. Veuillez actualiser la page et réessayer.",
    "forgotPassword.error.requiredEmail" : "Un adresse courriel est requis.",
    "forgotPassword.error.invalidEmail"  : "Please enter a valid email.",

    "forgotPassword.error.300" : "Nous n'avons trouvé aucun compte lié à l'adresse courriel fournis.",
}