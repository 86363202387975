export default{
    // Loading
    "confirm-account.loading.title"       : "Confirmation",
    "confirm-account.loading.description" : "Vous serez redirigé sous peu ...",

    // Success
    "confirm-account.success.title" : "Compte confirmé",

    // Failed
    "confirm-account.failed.title"  : "Oups, une erreur s'est produite!",
    "confirm-account.failed.action" : "Retour à l'accueil",
    "confirm-account.error.server"  : "Un problème est survenu. Veuillez actualiser la page et réessayer.",
    "confirm-account.error.204"     : "Un problème est survenu. Veuillez contacter le support technique.",
    "confirm-account.error.2000"    : "Un problème est survenu. Veuillez contacter le support technique.",
}