export default{
    // headings
    "login.heading.title" : "Registre de Hockey Canada",
    "login.heading.intro" : "Bienvenue sur la page d'accueil du nouveau registre de Hockey Canada, le carrefour central pour l'inscription des participants et la plateforme nationale de gestion des affiliations pour tous les niveaux et activités (stages, formations, certifications) du hockey mineur à travers le pays.",
    "login.heading.cta"   : "En Savoir Plus",

    // Login form
    "login.form.label.title"                  : "Connexion",
    "login.form.label.email"                  : "Email",
    "login.form.label.password"               : "Mot de passe",
    "login.form.label.rememberMe"             : "Se souvenir de moi",
    "login.form.action.submit"                : "Connexion",
    "login.form.action.forgotPassword"        : "Mot de passe oublié?",
    "login.form.label.signup"                 : "Vous n'avez pas de compte? ",
    "login.form.action.signup"                : "S'inscrire",
    "login.form.validation.email.required"    : "Un email est requis ",
    "login.form.validation.email.email"       : "Il doit s'agir d'un email valide ",
    "login.form.validation.password.required" : "Mot de passe requis",

    // Admin login
    "login.adminConnexion.loading"       : "Connexion...",
    "login.adminConnexion.EXPIRED_TOKEN" : "Votre session a expiré. Veuillez vous reconnecter.",

    // AntiSpam
    "login.toast.warning.spam" : "Attention, veuillez attendre quelques secondes avant de poursuivre",

    // Error
    "login.error.server" : "Un problème est survenu. Veuillez actualiser la page et réessayer.",
    "login.error.101"    : "Mauvais mot de passe.",
    "login.error.3028"   : "Le courriel ou le mot de passe entré est incorrect.",
}