import { useEffect, useRef, useState } from 'react';
import { stringBuilder } from "@spordle/helpers";

/**
* @param {React.ComponentType} [tag='div'] The desired htmlElement of the container.
* @param {string} [className]
* @param {string || number} [top=0] Value (in px) of the top, when isSticky
* @param {string} [isStickyClassName] additionnal classes when the element is isSticky
* @param {string} [media] mediaquery when this needs to apply the sticky behaviour sm || md || lg || xl

* media currently only work on refresh, not on window resize
* Be carefull to not have a child with margin bottom, it might make the header "bounce" when isSticky.
*/
const Sticky = ({
    tag: Tag,
    className,
    top = 0,
    isStickyClassName,
    children,
    media,
    ...props
}) => {
    const [ enable, setEnable ] = useState(true) // this changes based on the media value and the window size

    const [ isSticky, setIsSticky ] = useState(null);
    const [ stickTop, setStickTop ] = useState(null); // "top" value, when the element is stuck
    const [ containerHeight, setContainerHeight ] = useState(null) // container's height to prevent a "bounce" when the element become stuck

    const stickyContainer = useRef(null);
    const sticky = useRef(null); // element that becomes fixed

    const parsedTop = parseFloat(top);

    /**
    * @param {number} intersection distance from the "stickyIntersection" at which it needs to stick / unstick (<=0 means it stick, >0 mean it does not stick)
    */
    const toggleSticky = (intersection) => {
        if(intersection < 0){ // needs to stick
            if(!isSticky){

                // set a height on container to prevent a "bounce" when sticky becomes fixed
                setContainerHeight(sticky.current.offsetHeight)
                setStickTop(parsedTop)

                setIsSticky(true);
            }

        }else if(isSticky){ // does not stick
            // reset unnessessary height+top
            setContainerHeight(null)
            setStickTop(null)

            setIsSticky(false);
        }
    }

    useEffect(() => {
        const handleScroll = () => {
            // this if make sure it is enable + we havent change page with <Link>
            if(enable && stickyContainer.current && sticky.current){
                toggleSticky(stickyContainer.current.getBoundingClientRect().top - parsedTop)
            }
        }

        window.addEventListener('scroll', handleScroll);

        // cleanup
        return () => {
            window.removeEventListener('scroll', () => handleScroll);
        }
    }, [ isSticky ])// isSticky is needed in toggleSticky

    // custom mediaquery enables / disables the sticky behaviour
    useEffect(() => {
        if(media === 'sm'){
            setEnable(window.matchMedia("(min-width: 576px)").matches)
        }else if(media === 'md'){
            setEnable(window.matchMedia("(min-width: 768px)").matches)
        }else if(media === 'lg'){
            setEnable(window.matchMedia("(min-width: 992px)").matches)
        }else if(media === 'xl'){
            setEnable(window.matchMedia("(min-width: 1200px)").matches)
        }
    }, [])

    return (
        <Tag {...props} ref={stickyContainer} className={className} style={{ height: containerHeight ?? null }}>
            {/* {console.log(enable)} */}
            <div ref={sticky} className={stringBuilder('sticky', className, { 'isSticky': enable && isSticky }, { isStickyClassName: enable && isSticky && isStickyClassName })} style={{ top: stickTop ?? null }}>
                {children}
            </div>
        </Tag>
    );
};

export default Sticky;

Sticky.defaultProps = {
    tag: 'div',
};