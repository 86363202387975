/**
 * Get the URL to Spordle Account platform
 * @param {string} [authAs='SPORDLE'] Where we went to connect.
 * @return {string} The url to Spordle Account platform
 */
export default function getAccountUrl(authAs = 'CLINIC', auth = true){
    const endPath = auth ? `auth/${authAs}` : '';

    switch (process.env.REACT_APP_ENVIRONMENT){
        case 'prod':
            return `https://account.spordle.com/${endPath}`;
        case 'training':
            return `https://account.training.hcr.spordle.com/${authAs}`;
        case 'uat':
            return `https://account.uat.spordle.dev/${endPath}`;
        case 'hcstage':
            return `https://account.hcstage.spordle.dev/${endPath}`;
        case 'stage':
            return `https://account.stage.spordle.dev/${endPath}`;
        case 'int':
            return `https://account.int.spordle.dev/${endPath}`;
        case 'dev':
        default:
            return `https://account.dev.spordle.dev/${endPath}`;

    }
}