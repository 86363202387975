export default{
    "recovery.title.accountRecovery" : "Récupération du compte",

    "recovery.label.enterEmail"         : "Veuillez entrer votre adresse courriel",
    "recovery.label.noEmail"            : "Vous n'avez pas reçu de courriel?",
    "recovery.label.enterNewPassword"   : "Veuillez entrer votre nouveau mot de passe",
    "recovery.label.confirmNewPassword" : "Confirmez votre nouveau mot de passe",

    "recovery.validation.password.required"        : "Mot de passe requis",
    "recovery.validation.password.space"           : "Le mot de passe ne peut pas contenir d'espaces ",
    "recovery.validation.password.letter"          : "Doit contenir une lettre ",
    "recovery.validation.password.number"          : "Doit contenir 1 chiffre ",
    "recovery.validation.password.uppercase"       : "Doit contenir une majuscule ",
    "recovery.validation.password.lenght"          : "Doit contenir au moins 6 caractères",
    "recovery.validation.passwordConfirm.required" : "Veuillez confirmer votre mot de passe ",
    "recovery.validation.passwordConfirm.match"    : "Les mots de passe doivent correspondre",

    "recovery.action.backToLogin"        : "Retour au login",
    "recovery.action.sendEmail"          : "Envoyer un courriel",
    "recovery.action.confirmNewPassword" : "Confirmer mon nouveau mot de passe",

    "recovery.success.emailSent"   : "Un courriel de récupération à été envoyé à l'adresse courriel ci-dessus. Veuillez cliquez sur le lien dans le courriel pour commencer la récupération de votre compte.",
    "recovery.success.newPassword" : "Votre mot de passe à été changé!",

    "recovery.error.genericError"  : "Un problème est survenu. Veuillez actualiser la page et réessayer.",
    "recovery.error.requiredEmail" : "Un adresse courriel est requis.",
    "recovery.error.invalidEmail"  : "Please enter a valid email.",

    "recovery.error.server" : "Un problème est survenu. Veuillez actualiser la page et réessayer.",
    "recovery.error.300"    : "Nous n'avons trouvé aucun compte lié à l'adresse courriel fournis.",
    "recovery.error.204"    : "Un problème est survenu. Veuillez contacter le support technique.",
}