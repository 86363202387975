export default{
    "sidebar.home"      : "Home",
    "sidebar.dashboard" : "Dashboard",
    "sidebar.profile"   : "Profil",
    "sidebar.setup"     : "Settings",
    "sidebar.user"      : "Users",

    /* Account */
    "sidebar.account"                : "Account",
    "sidebar.account.dashboard"      : "Dashboard",
    "sidebar.account.members"        : "Members",
    "sidebar.account.cards"          : "Cards",
    "sidebar.account.payment"        : "Payment Methods",
    "sidebar.account.communications" : "Communications",
    "sidebar.account.notifications"  : "Notifications",

    /* Organization */
    "sidebar.organization"           : "Organization",
    "sidebar.organization.dashboard" : "Dashboard",
    "sidebar.organization.profile"   : "Profile",
    "sidebar.organization.users"     : "Users",
    "sidebar.organization.settings"  : "Settings",
    "sidebar.organization.change"    : "Change Organization",

    /* Participants */
    "sidebar.participants"           : "Participants",
    "sidebar.participants.dashboard" : "Participants Dashboard",
    "sidebar.participants.create"    : "Add Participant",
    "sidebar.participants.search"    : "Search Participants",
    "sidebar.participants.reports"   : "Participants Reports",
    "sidebar.participants.settings"  : "Participants Settings",

    /* Clinics */
    "sidebar.clinics"           : "Clinics",
    "sidebar.clinics.dashboard" : "Clinics Dashboard",
    "sidebar.clinics.create"    : "Create a Clinic",
    "sidebar.clinics.search"    : "Search Clinics",
    "sidebar.clinics.reports"   : "Clinics Reports",
    "sidebar.clinics.settings"  : "Clinics Settings",

    /* Teams */
    "sidebar.teams"           : "Teams",
    "sidebar.teams.dashboard" : "Teams Dashboard",

    /* Leagues */
    "sidebar.leagues"           : "Leagues",
    "sidebar.leagues.dashboard" : "Leagues Dashboard",

    /* Reports */
    "sidebar.reports.global" : "Global Reports",
}