export default{
    "clinic.detail.cart.waitingListInfo.title"  : "Register to the waiting list",
    "clinic.detail.cart.waitingListInfo.text"   : "The clinic is currently full of attendees, but a waiting list is available and you can register to it.",
    "clinic.detail.cart.waitingListInfo.text2"  : "If you are selected to attend this clinic, you will receive an email inviting you to come back to this page to complete your registration.",
    "clinic.detail.cart.waitingListInfo.text3"  : "You will have the option to decline an invitation in case you change your mind.",
    "clinic.detail.cart.waitingListInfo.button" : "Start registration",

    "clinic.detail.cart.waitingListConfirmation.title"       : "Waiting list registration confirmation",
    "clinic.detail.cart.waitingListConfirmation.thanks"      : "Thank you for registering to the waiting list!",
    "clinic.detail.cart.waitingListConfirmation.number"      : "Waiting list number: ",
    "clinic.detail.cart.waitingListConfirmation.emailSentTo" : "An email confirmation has been sent to",
    "clinic.detail.cart.waitingListConfirmation.keepAnEye"   : "Keep an eye on your emails to stay up to date with your registration, we will contact you if necessary.",

    "clinic.detail.cart.waitingListReturn.title"                : "Registration completion",
    "clinic.detail.cart.waitingListReturn.welcomeBack"          : "Welcome back!",
    "clinic.detail.cart.waitingListReturn.selected"             : "You have been selected to participate in this clinic.",
    "clinic.detail.cart.waitingListReturn.continueRegistration" : "Click the {confirm} button to continue your registration for {firstName} {lastName}, or click the {cancel} button to cancel your registration to this clinic and to the waiting list.",
    "clinic.detail.cart.waitingListReturn.header"               : "Cancellation",
    "clinic.detail.cart.waitingListReturn.reason"               : "Cancellation reason",
    "clinic.detail.cart.waitingListReturn.reason.required"      : "Cancellation reason is required",

    "clinic.detail.cart.waitingListCheckoutError.title" : "Waiting list",
    "clinic.detail.cart.waitingListCheckoutError.text"  : "Unfortunately, the last spot in this clinic was filled while your were completing your registration, we have registered you to the waiting list.",
}