export default{
    "clinics.detail.cart.waivers.title"    : "Waivers",
    "clinics.detail.cart.waivers.subtitle" : "Waivers",
    "clinics.detail.cart.waivers.of"       : "of",
    "clinics.detail.cart.waivers.accept"   : "I have read and accept the waiver",
    "clinics.detail.cart.waivers.required" : "This waiver is mandatory",
    "clinics.detail.cart.terms.title"      : "Payment Terms and Conditions",
    "clinics.detail.cart.terms.subtitle"   : "Payment Terms and Conditions",
    "clinics.detail.cart.terms.accept"     : "I have read and accept the terms and conditions",
    "clinics.detail.cart.terms.required"   : "Please check the box above to confirm that you accept these terms and conditions",
}