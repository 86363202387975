import PropTypes from 'prop-types';
import { stringBuilder } from "@spordle/helpers";
import { useContext } from 'react';
import { I18nContext } from '../contexts/I18nContext';

/**
* @param {string} [className]
* @param {string} [dark=false] // text becomes dark
*/
const LogoHcr = ({
    className,
    dark,
}) => {
    const i18nContext= useContext(I18nContext);
    return (
        <div className={stringBuilder('d-flex align-items-center', className)}>
            <img
                src={`https://spordle-frontend-assets.s3.ca-central-1.amazonaws.com/hcrsite/${i18nContext.getGenericLocale().toUpperCase()}_HC REGISTRY_LOGO REV.png`}
                alt="Hockey Canada"
                className="mw-100 mh-100"
                height="88px"
            />
        </div>
    );
};

export default LogoHcr;

LogoHcr.propTypes = {
    tag: PropTypes.string,
    className: PropTypes.string,
};
LogoHcr.defaultProps = {
    tag: 'div',
};