export default{
    "clinics.detail.cart.summary.title"                     : "Résumé d'inscription",
    "clinics.detail.cart.summary.orderSummary"              : "Résumé de la commande",
    "clinics.detail.cart.summary.for"                       : "Inscription pour",
    "clinics.detail.cart.summary.edit"                      : "Modifier l'inscription de ce membre",
    "clinics.detail.cart.summary.removeMember"              : "Retirer ce membre",
    "clinics.detail.cart.summary.removeMember.confirmation" : "Ce membre sera retiré de votre commande",
    "clinics.detail.cart.summary.registration"              : "Inscription",
    "clinics.detail.cart.summary.requiredItems"             : "Articles requis",
    "clinics.detail.cart.summary.otherItems"                : "Autres articles",
    "clinics.detail.cart.summary.action"                    : "Cette action est irréversible",
    "clinics.detail.cart.summary.credit"                    : "Crédits disponibles",
    "clinics.detail.cart.summary.credit.activate"           : "Activer les crédits pour ce membre",
    "clinics.detail.cart.summary.credit.deactivate"         : "Désactiver les crédits pour ce membre",
    "clinics.detail.cart.summary.rebate"                    : "Rabais",
    "clinics.detail.cart.summary.subtotal"                  : "Sous-total pour",
    "clinics.detail.cart.summary.total"                     : "Prix total",
    "clinics.detail.cart.summary.noTaxes"                   : "taxes non-incluses",
    "clinics.detail.cart.summary.taxes"                     : "Taxes",
}