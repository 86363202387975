import { useContext, useEffect, useState } from "react";
import "react-datetime/css/react-datetime.css";
import Skeleton from "react-loading-skeleton";
import { DisplayI18n } from "../../../helpers/i18nHelper";
import Translate from "@spordle/intl-elements";
import SpordleSelect from "@spordle/spordle-select";
import { stringBuilder } from "@spordle/helpers";

// contexts
import { QualificationCategoriesContext } from '../../../contexts/QualificationCategoriesContext';
import { QualificationsContext } from '../../../contexts/QualificationsContext';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { useSpordleTable } from "@spordle/datatables";
import { Collapse, Label } from "reactstrap";
import { AxiosIsCancelled } from "../../../api/CancellableAPI";

const ClinicListFilterCategories = (props) => {
    const qualificationCategoriesContext = useContext(QualificationCategoriesContext);
    const qualificationsContext = useContext(QualificationsContext);
    const organizationContext = useContext(OrganizationContext);
    const spordleTable = useSpordleTable();

    const [ clinicCategories, setClinicCategories ] = useState(false);

    const [ qualificationOptions, setQualificationOptions ] = useState(false);
    const [ qualificationSelectLoading, setQualificationSelectLoading ] = useState(false)

    useEffect(() => {
        if(spordleTable.getFilters().organizationId && Array.isArray(props.periods)){
            setQualificationSelectLoading(true)
            qualificationsContext.getQualifications({
                organisation_id: spordleTable.getFilters().organizationId,
                period_id: spordleTable.getFilters().periodId !== 'ALL' ? spordleTable.getFilters().periodId : props.periods?.find((period) => period.current == 1)?.period_id,
                qualification_category_id: spordleTable.getFilters().category,
            })
                .then((quals) => {
                    setQualificationOptions(quals.reduce((newArray, qualification) => {
                        if(qualification.active == 1){
                            newArray.push({
                                value: qualification.qualification_id,

                                label: qualification.name,
                                i18n: qualification.i18n,

                                levelLabel: qualification.qualification_level?.name || null,
                                levelI18n: qualification.qualification_level?.i18n || null,
                            })
                        }
                        return newArray
                    }, []))
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error);
                        setQualificationOptions([]);
                    }
                })
                .finally(() => setQualificationSelectLoading(false))
        }
    }, [ spordleTable.getFilters().organizationId, spordleTable.getFilters().periodId, spordleTable.getFilters().category, props.periods ])

    // On load or when the federation changes, load the qualification categories
    // on page refresh the federation might not be set yet
    useEffect(() => {
        if(organizationContext.state.federation?.organisation_id){
            qualificationCategoriesContext.getQualificationCategories()
                .then((categories) => {
                    setClinicCategories(categories.reduce((newArray, cat) => {
                        if(cat.active == 1){ // meow (=^ェ^=)
                            newArray.push(cat)
                        }
                        return newArray
                    }, []))
                })
                .catch(console.error)
        }
    }, [ organizationContext.state.federation?.organisation_id ]);

    const isDisabled = () => spordleTable.state.loadingState === 'loading' || spordleTable.state.loadingState === 'lazy' || props.fieldsDisabled

    return (
        <div className="border-bottom mb-3">
            <div className='pb-2 mb-3'>
                <div className="d-flex">
                    <div className="mb-2 font-weight-bold"><Translate id='clinics.filters.categories' /></div>
                    {spordleTable.getFilters().category && // if category is filtered
                        <button onClick={() => spordleTable.filterChange('category', '')} className="ml-auto reset-btn text-muted" type="button" id={spordleTable.generateId('filters', 'category', 'clear')}>
                            <Translate id='misc.clear' /><i className="mdi mdi-close align-middle ml-1" />
                        </button>
                    }
                </div>
                {clinicCategories ?
                    <div className="d-flex flex-wrap">
                        {clinicCategories.map((category) => {
                            const inputId = spordleTable.generateId('filters', 'category', category.qualification_category_id)
                            return (
                                <div key={category.qualification_category_id}>
                                    {/* FormikCheckButton doesn't work with label htmlFor */}
                                    <input
                                        key={spordleTable.getFilters().category}
                                        id={inputId}
                                        disabled={isDisabled()}
                                        className="filter-checkbox"
                                        name='category' type="checkbox" defaultChecked={spordleTable.getFilters().category === category.qualification_category_id}
                                        onChange={(e) => {
                                            const newCategory = e.target.checked ? category.qualification_category_id : '';
                                            spordleTable.filterChange('qualification', '', false);
                                            spordleTable.filterChange('category', newCategory);
                                        }}
                                    />
                                    <label htmlFor={inputId} className={stringBuilder("filter-checkbox-container rounded mr-2 user-select-none", { 'disabled': isDisabled() })}>
                                        <div className={`filter-dot bg-clinic-${isNaN(parseInt(category.color)) ? 0 : category.color} mr-1`} />
                                        <DisplayI18n field='name' defaultValue={category.name} i18n={category.i18n} />
                                    </label>
                                </div>
                            )
                        })}
                    </div>
                    :
                    <Skeleton height='38px' className='mb-2' count={3} />
                }
            </div>

            <Collapse isOpen={!!spordleTable.getFilters().category}>
                <div className='pb-3 mb-3'>
                    <div className="d-flex mb-2">
                        <Label for={spordleTable.generateId('filters', 'qualification', 'qualificationId')} className="font-weight-bold mb-0"><Translate id='clinics.filters.qualification' /></Label>
                        {spordleTable.getFilters().qualification && // if category is filtered
                            <button onClick={() => spordleTable.filterChange('qualification', '')} className="ml-auto reset-btn text-muted" type="button" id={spordleTable.generateId('filters', 'qualification', 'clear')}>
                                <Translate id='misc.clear' /><i className="mdi mdi-close align-middle ml-1" />
                            </button>
                        }
                    </div>
                    <SpordleSelect
                        id={spordleTable.generateId('filters', 'qualification', 'qualificationId')} name='qualificationId'
                        disabled={isDisabled()}
                        isLoading={qualificationSelectLoading}
                        values={spordleTable.getFilters().qualification ? [ spordleTable.getFilters().qualification ] : []}
                        options={qualificationOptions || []}
                        renderOption={(option) => (
                            <>
                                <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />
                                {option.option.levelLabel &&
                                    <>
                                        <span className='mx-1'>-</span>
                                        <DisplayI18n field='name' defaultValue={option.option.levelLabel} i18n={option.option.levelI18n} />
                                    </>
                                }
                            </>
                        )}
                        onOptionSelected={(values) => {
                            spordleTable.filterChange('qualification', values[0] || '')
                        }}
                    />
                </div>
            </Collapse>
        </div>
    )
}
export default ClinicListFilterCategories;