const HCRLeaderboardResponsiveSizes = [
    {
        screenSize: [ 1500, 0 ],
        adSize: [
            [ 1470, 100 ],
        ],
    },
    {
        screenSize: [ 975, 0 ],
        adSize: [
            [ 970, 90 ],
        ],
    },
    {
        screenSize: [ 730, 0 ],
        adSize: [
            [ 728, 90 ],
        ],
    },
    {
        screenSize: [ 470, 0 ],
        adSize: [
            [ 468, 60 ],
        ],
    },
    {
        screenSize: [ 310, 0 ],
        adSize: [
            [ 320, 50 ],
        ],
    },
    {
        screenSize: [ 0, 0 ],
        adSize: [
            [ 320, 50 ],
        ],
    },
];

export const bigboxConfig = {
    en: [
        '/18279188/HCR_Portal_Main_300x250_External', [300, 250],
        'div-gpt-ad-1699036561080-0',
        []
    ], // Bigbox: 'min-width: 300px; min-height: 250px;
    fr: [
        '/18279188/HCR_Portal_Main_300x250_External_French',
        [300, 250],
        'div-gpt-ad-1699036608579-0',
        []
    ], // min-width: 300px; min-height: 250px;
}

export const leaderboardHeaderConfig = {
    en: [
        '/18279188/HCR_Portal_Main_Leaderboard_Header_External',
        [[1470, 100], [320, 50], [970, 90], [728, 90], [468, 60]],
        'div-gpt-ad-1699036745568-0',
        HCRLeaderboardResponsiveSizes
    ], // min-width: 320px; min-height: 50px;
    fr: [
        '/18279188/HCR_Portal_Main_Leaderboard_Header_External_french',
        [[320, 50], [1470, 100], [468, 60], [970, 90], [728, 90]],
        'div-gpt-ad-1699036793050-0',
        HCRLeaderboardResponsiveSizes
    ], // min-width: 320px; min-height: 50px;
}

export const leaderboardFooterConfig = {
    en: [
        '/18279188/HCR_Portal_Main_Leaderboard_Footer_External',
        [[728, 90], [320, 50], [468, 60], [970, 90], [1470, 100]],
        'div-gpt-ad-1699036854795-0',
        HCRLeaderboardResponsiveSizes
    ], // min-width: 320px; min-height: 50px;
    fr: [
        '/18279188/HCR_Portal_Main_Leaderboard_Footer_External_french',
        [[468, 60], [970, 90], [728, 90], [320, 50], [1470, 100]],
        'div-gpt-ad-1699036889181-0',
        HCRLeaderboardResponsiveSizes
    ] // min-width: 320px; min-height: 50px;
}

const configs = {
    en: [
        leaderboardHeaderConfig.en,
        leaderboardFooterConfig.en,
        bigboxConfig.en
    ],
    fr: [
        leaderboardHeaderConfig.fr,
        leaderboardFooterConfig.fr,
        bigboxConfig.fr
    ]
}

export function defineAdSlots(configs) {
    const googletag = window.googletag || { cmd: [] };

    configs.forEach((slotConfig) => {
        const [ name, size, id, mappingSizes ] = slotConfig;
        console.log("DEFINE SLOT", id);
        const responsiveAdSlot = googletag.defineSlot(name, size, id).addService(googletag.pubads());
        const mapping = googletag.sizeMapping();

        mappingSizes.forEach(({ screenSize, adSize }) => {
            mapping.addSize(screenSize, adSize);
        });

        responsiveAdSlot.defineSizeMapping(mapping.build());
    });
}


export function initializeAdSlots(lang){
    console.log("-----");
    console.log("INITIALIZE CODE FOR ADS", configs);
    const googletag = window.googletag || { cmd: [] };
    if(!googletag.cmd){
        googletag.cmd = [];
    }

    const langConfigs = configs[lang];

    if(!Array.isArray(langConfigs)) {
        return;
    }

    googletag.cmd?.push(function(){
        defineAdSlots(langConfigs);

        googletag.pubads().enableSingleRequest();
        googletag.pubads().collapseEmptyDivs();
        googletag.enableServices();
    });
    console.log("-----");
}

export function destroyHCRSlots(){
    const googletag = window.googletag || { cmd: [] };
    if(!googletag.cmd){
        googletag.cmd = [];
    }

    console.log("-----");
    console.log('DESTROY SLOTS');

    // Removing the HCR Ads on each page change to rerender them manually.
    googletag.cmd?.push(function(){
        googletag.destroySlots();
    });
}