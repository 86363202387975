export default{
    // ONLY ADD GENERAL FIELDS
    // Example of a general field   : First Name (can be used for attendees, instructors, users, etc.)
    // Example of a specific field  : Organization Name (only used in organization)

    // Fields
    "form.fields.forms"                            : "Formulaires",
    "form.fields.firstName"                        : "Prénom",
    "form.fields.lastName"                         : "Nom de famille",
    "form.fields.name"                             : "Nom",
    "form.fields.name.lang"                        : "Nom ({lang})",
    "form.fields.title"                            : "Titre",
    "form.fields.title.lang"                       : "Titre ({lang})",
    "form.fields.short_name"                       : "Nom abrégé",
    "form.fields.short_name.lang"                  : "Nom abrégé ({lang})",
    "form.fields.description"                      : "Description",
    "form.fields.description.lang"                 : "Description ({lang})",
    "form.fields.email"                            : "Courriel",
    "form.fields.fees"                             : "Frais",
    "form.fields.phone"                            : "Numéro de téléphone",
    "form.fields.extension"                        : "Extension",
    "form.fields.mobilePhone"                      : "Téléphone mobile",
    "form.fields.phoneType"                        : "Type de téléphone",
    "form.fields.dateOfBirth"                      : "Date de naissance",
    "form.fields.gender"                           : "Identité de genre",
    "form.fields.genderDescription"                : "Identification du genre",
    "form.fields.genderDescription.required"       : "Veuillez spécifier le genre auquel vous vous identifiez",
    "form.fields.gender.placeholder"               : "Sélectionnez un identité de genre",
    "form.fields.gender.male"                      : "Masculin",
    "form.fields.gender.female"                    : "Féminin",
    "form.fields.gender.other"                     : "Je préfère ne pas répondre",
    "form.fields.gender.prefer_not_to_say"         : "Je préfère ne pas répondre",
    "form.fields.streetNumber"                     : "Numéro d'adresse",
    "form.fields.address"                          : "Adresse",
    "form.fields.address2.placeholder"             : "Appartement, suite, unité, étage, bâtiment, etc.",
    "form.fields.addressType"                      : "Type d'adresse",
    "form.fields.moveInYear"                       : "Année d'emménagement",
    "form.fields.moveInYear.required"              : "L'année d'emménagement est requise",
    "form.fields.moveInYear.future"                : "L'année ne peut pas être dans le futur",
    "form.fields.moveInYear.format"                : "L'année doit être d'un format valide",
    "form.fields.city"                             : "Ville",
    "form.fields.country"                          : "Pays",
    "form.fields.state"                            : "État",
    "form.fields.province"                         : "Province",
    "form.fields.zip"                              : "Code postal",
    "form.fields.primaryLanguage"                  : "Langue maternelle",
    "form.fields.primaryLanguage.placeholder"      : "Sélectionnez une langue",
    "form.fields.secondaryLanguages"               : "Langue seconde",
    "form.fields.nationality"                      : "Citoyenneté",
    "form.fields.nationality.placeholder"          : "Sélectionnez une citoyenneté",
    "form.fields.nationality.selected"             : "{count} citoyennetés sélectionnées",
    "form.fields.nationality.CA"                   : "Canadien",
    "form.fields.nationality.US"                   : "Américain",
    "form.fields.lastConnection"                   : "Dernière connexion",
    "form.fields.language"                         : "Langue",
    "form.fields.general"                          : "Général",
    "form.fields.generalInfo"                      : "Informations Générales",
    "form.fields.status"                           : "Statut",
    "form.fields.relation"                         : "Votre relation avec le participant ",
    "form.fields.relation.FATHER"                  : "Père",
    "form.fields.relation.MOTHER"                  : "Mère",
    "form.fields.relation.DAUGHTER"                : "Fille",
    "form.fields.relation.SON"                     : "Fils",
    "form.fields.relation.CHILD"                   : "Enfant",
    "form.fields.relation.OTHER"                   : "Autre",
    "form.fields.identifyAsIndigenous"             : "S'identifier comme Autochtone",
    "form.fields.identifyAsIndigenous.required"    : "Veuillez spécifier si vous vous identifiez comme Autochtone.",
    "form.fields.indigenousGroup"                  : "Groupe Autochtone",
    "form.fields.indigenousGroup.required"         : "Le groupe Autochtone est requis.",
    "form.fields.ethnicity"                        : "Ethnicité",
    "form.fields.ethnicity.required"               : "L'ethnicité est requise.",
    "form.fields.ethnicityNote"                    : "Description de l'ethnicité",
    "form.fields.ethnicityNote.required"           : "La note est requise.",
    "form.fields.birthCountry"                     : "Pays de naissance",
    "form.fields.identityGroupIndigenous"          : "Description du groupe Autochtone",
    "form.fields.identityGroupIndigenous.required" : "Veuillez identifier le groupe.",

    "form.fields.waiver.required"      : "Veuillez sélectionner une option.",
    "form.fields.waiver.defaultAccept" : "J'ai lu et j'accepte la dispense",
    "form.fields.waiver.doNotSign"     : "Je préfère ne pas signer",
    "form.fields.waiver.notMandatory"  : "Cette dispense n'est pas obligatoire",
    "form.fields.waiver.mandatory"     : "Cette dispense est obligatoire",
    "form.fields.waiver.clear"         : "Effacer la sélection",

    // Canada
    "form.fields.country.ca"  : "Canada",
    "form.fields.state.ca.ab" : "Alberta",
    "form.fields.state.ca.bc" : "Colombie-Britannique",
    "form.fields.state.ca.pe" : "Île-du-Prince-Édouard",
    "form.fields.state.ca.mb" : "Manitoba",
    "form.fields.state.ca.nb" : "Nouveau-Brunswick",
    "form.fields.state.ca.ns" : "Nouvelle-Écosse",
    "form.fields.state.ca.on" : "Ontario",
    "form.fields.state.ca.qc" : "Québec",
    "form.fields.state.ca.sk" : "Saskatchewan",
    "form.fields.state.ca.nl" : "Terre-Neuve-et-Labrador",
    "form.fields.state.ca.nu" : "Nunavut",
    "form.fields.state.ca.nt" : "Territoires du Nord-Ouest",
    "form.fields.state.ca.yt" : "Yukon",

    // Error messages (Yup)
    "form.validation.firstName.required"       : "Le prénom est requis",
    "form.validation.lastName.required"        : "Le nom est requis",
    "form.validation.name.required"            : "Le nom est requis",
    "form.validation.name.lang.required"       : "Le nom ({lang}) est requis",
    "form.validation.title.required"           : "Le titre est requis",
    "form.validation.description.required"     : "La description est requise",
    "form.validation.email.required"           : "Le courriel est requis",
    "form.validation.email.valid"              : "Le courriel doit être valide",
    "form.validation.phone.required"           : "Le numéro de téléphone est requis",
    "form.validation.phone.valid"              : "Le numéro de téléphone doit être valide",
    "form.validation.phoneType.required"       : "Le type de téléphone est requis",
    "form.validation.dateOfBirth.required"     : "La date de naissance est requise",
    "form.validation.dateOfBirth.valid"        : "La date de naissance doit être d'un bon format",
    "form.validation.gender.required"          : "L'identité de genre est requis",
    "form.validation.address.required"         : "L'adresse est requise",
    "form.validation.addressType.required"     : "Le type d'adresse est requise",
    "form.validation.POBox.required"           : "La boîte postale est requise",
    "form.validation.streetNumber.required"    : "Le numéro d'adresse est requis",
    "form.validation.city.required"            : "La ville est requise",
    "form.validation.country.required"         : "Le pays est requis",
    "form.validation.state.required"           : "L'état est requis",
    "form.validation.province.required"        : "La province est requise",
    "form.validation.zip.required"             : "Le code postal est requis",
    "form.validation.zip.valid"                : "Le code postal doit être valide",
    "form.validation.primaryLanguage.required" : "La langue primaire est requise",
    "form.validation.nationality.required"     : "La citoyenneté est requise",
    "form.validation.ethnicity.required"       : "L'ethnicité est requise",
    "form.validation.language.required"        : "La langue est requise",
    "form.validation.relation.required"        : "La relation est requise",
    "form.validation.birthCountry.required"    : "Le pays de naissance est requis",

    "form.validation.date.format" : "La date doit être d'un format valide",
}