import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import withContexts from '../helpers/withContexts';
import { OrganizationContext } from './OrganizationContext';
import { PeriodsContext } from './PeriodsContext';

/** @type {React.Context<Omit<QualificationsContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const QualificationsContext = createContext();
QualificationsContext.displayName = 'QualificationsContext';

class QualificationsContextProvider extends React.PureComponent{
    /**
     * Get a list of all the Qualifications
     * @param {object} [queryParams] The params for that specific api call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Qualifications/Apicontroller%5CClinics%5CQualifications%3A%3Aindex|documentation}
     * @returns {Promise.<Array>}
     */
    getQualifications = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/qualifications`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.qualifications;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    render(){
        return (
            <QualificationsContext.Provider value={{ ...this }}>
                {this.props.children}
            </QualificationsContext.Provider>
        );
    }
}

export default withContexts(OrganizationContext, PeriodsContext)(QualificationsContextProvider);
