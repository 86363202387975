export default{
    // General
    "signup.label.backToLogin" : "Retour au login",
    "signup.label.title"       : "Inscription",
    "signup.action.signup"     : "S'inscrire",

    // Sign up form
    "signup.signupForm.label.firstName"       : "Prénom",
    "signup.signupForm.label.lastName"        : "Nom de famille",
    "signup.signupForm.label.email"           : "Adresse courriel",
    "signup.signupForm.label.password"        : "Mot de passe",
    "signup.signupForm.label.passwordConfirm" : "Confirmez le mot de passe",

    "signup.signupForm.validation.firstName.required"    : "Prénom requis",
    "signup.signupForm.validation.lastName.required"     : "Nom de famille requis",
    "signup.signupForm.validation.email.required"        : "Email requis",
    "signup.signupForm.validation.email.email"           : "Email invalide",
    "signup.signupForm.validation.password.required"     : "Mot de passe requis",
    "signup.signupForm.validation.password.space"        : "Le mot de passe ne peut pas contenir d'espaces ",
    "signup.signupForm.validation.password.number"       : "Doit contenir 1 chiffre ",
    "signup.signupForm.validation.password.letter"       : "Doit contenir une lettre ",
    "signup.signupForm.validation.password.uppercase"    : "Doit contenir une majuscule ",
    "signup.signupForm.validation.password.lenght"       : "Doit contenir au moins 6 caractères",
    "signup.signupForm.validation.passwordConfirm.match" : "Les mots de passe doivent correspondre",

    // Error
    "signup.error.title.server" : "Oups, une erreur s'est produite !",
    "signup.error.server"       : "Un problème est survenu. Veuillez actualiser la page et réessayer.",
    "signup.error.title.3010"   : "Adresse courriel déjà utilisé.",
    "signup.error.3010"         : "Un compte existe déjà avec cette adresse courriel.",
    "signup.error.action"       : "Retour à l'inscription",

    // Loading
    "signup.loading.title"       : "Création en cours !",
    "signup.loading.description" : "Une fois terminé, vous allez reçevoir un courriel de confirmation.",

    // Confirmed
    "signup.confirmed.title"       : "Confirmez votre courriel",
    "signup.confirmed.description" : "Nous avons envoyé un courriel, veuillez confirmer votre compte pour vous connecter.",
    "signup.confirmed.notReceived" : "Vous n'avez pas reçu de courriel?",
    "signup.confirmed.resend"      : "Envoyer courriel",
}