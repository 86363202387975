import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import { OrganizationContext } from './OrganizationContext';
import { QualificationCategoriesContext } from './QualificationCategoriesContext';
import { PeriodsContext } from './PeriodsContext';
import withContexts from '../helpers/withContexts';

import * as Sentry from "@sentry/react";

/** @type {React.Context<Omit<ClinicsContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'> & ClinicsContextProvider['state']>} */
export const ClinicsContext = createContext();
ClinicsContext.displayName = 'ClinicsContext';

class ClinicsContextProvider extends React.PureComponent{
    // this context manages both the clinics endpoint and the clinics sessions endpoint

    state = {
        currentClinic: {
            sessions: null,
            contacts: null,
            instructors: null,
        },
    }

    componentDidUpdate(){
        Sentry.setContext('clinic', this.state.currentClinic.clinic_id ? {
            clinicId: this.state.currentClinic.clinic_id,
            name: this.state.currentClinic.name,
            activeStatus: this.state.currentClinic.active_status,
            createdBy: this.state.currentClinic.created_by?.identity_id,
        } : null);
        Sentry.setTag('clinicId', this.state.currentClinic.clinic_id);
    }

    clearCurrentClinic = () => {
        return new Promise((resolve) => {
            this.setState(() => ({ currentClinic: {} }), resolve(true))
        })
    }

    clearCurrentClinicId = () => {
        return new Promise((resolve) => {
            this.setState((prevState) => ({ currentClinic: { ...prevState.currentClinic, clinic_id: null } }), resolve(true))
        })
    }

    /**
     * Will preset clinic data when we pass data throw routing
     * Because of the state of the API, the data we preset might not be 100%.
     * -> Solution: We will preset and still asyc getAllClinicInfos in the profile view.
     * -> Effect: The page will load with the preset context state and rerender when api returns all data.
     *            This should be almost transparent for the user
     * @param {Object} clinicData ID of the clinic we are presetting in the state
     * @returns {Promise}
     */
    presetCurrentClinic = (clinicData) => {
        return new Promise((resolve) => {
            // Split data to simulate getAllClinicInfos()
            this.setState(() => ({
                currentClinic: {
                    ...clinicData,
                    category: clinicData?.qualification?.qualification_category || null, // will be used a lot for the color, easier access that way
                    clinic_id: null, // Force profile to reload currentClinic when if compares clinic_id
                    sessions: null,
                    contacts: null,
                    instructors: null,
                },
            }), resolve(true))
        })
    }

    getClinic = (clinicId, fromAPI) => {
        if(this.state.currentClinic.clinic_id === clinicId && !fromAPI){
            return Promise.resolve(this.state.currentClinic)
        }

        return API_SPORDLE.get(queryString.stringifyUrl({ url: `/clinics/${clinicId}` }))
            .then((response) => {
                if(response.data.status){
                    // we don't set the sessions here because the sessions from the clinic call are missing information
                    this.setState((prevState) => ({
                        currentClinic: {
                            ...prevState.currentClinic,
                            ...response.data.clinics[0],
                            category: response.data.clinics[0].qualification?.qualification_category ? {
                                ...response.data.clinics[0].qualification?.qualification_category,
                                // do the parsing here so we don't have to do it anywhere else
                                color: isNaN(parseInt(response.data.clinics[0].qualification?.qualification_category?.color)) ? 0 : response.data.clinics[0].qualification?.qualification_category?.color,
                            } : null,
                        },
                    }))
                    return response.data.clinics[0]
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * [GET] - Get the clinic's available organizations
     * @param {string} clinicId Id of the clinic
     * @param {Object} params Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Clinic/11833e7a99f5aecdad3ad79980750abe|documentation}
     * @returns {Promise}
     */
    getClinicOrganizations = (clinicId, params) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/clinics/${clinicId}/organisations`,
            query: params,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.clinic_organisations
                }
                throw response.data.errors[0]
            })
    }

    // getAllClinicInfos = (clinicId, settings = { sessions: false, contacts: false, instructors: false, memberTypes: false, merchantAccounts: false }) => {
    //     return API_SPORDLE.get(queryString.stringifyUrl({ url: `/clinics/${clinicId}` }))
    //         .then(response => {
    //             if (response.data.status) {
    //                 return Promise.all([
    //                     settings.sessions ? this._getAllClinicSessions(clinicId) : Promise.resolve(),
    //                     settings.contacts ? this._getAllClinicContacts(clinicId) : Promise.resolve(),
    //                     settings.instructors ? this._getAllClinicInstructors(clinicId) : Promise.resolve(),

    //                     // temporary calls, the API returns nothing but the ID and the name for these sub resources, but we need stuff like color, i18n, logo, etc.
    //                 ])
    //                     .then(promises => {
    //                         this.setState(() => ({
    //                             currentClinic: {
    //                                 ...response.data.clinics[0],
    //                                 sessions: settings.sessions ? promises[0] : this.state.currentClinic.sessions,
    //                                 contacts: settings.contacts ? promises[1] : this.state.currentClinic.contacts,
    //                                 instructors: settings.instructors ? promises[2] : this.state.currentClinic.instructors,
    //                                 memberTypes: settings.memberTypes ? promises[3] : this.state.currentClinic.memberTypes,
    //                             }
    //                         }), () => { return true })
    //                     })
    //                     .catch(error => {
    //                         console.error(error)
    //                     })
    //             } else {
    //                 throw new Error(response.data.errors[0].code)
    //             }
    //         }, serverError)
    // }

    // /**
    //  * Returns an array of all the contacts for a clinic
    //  * @param {string} clinicId ID of the clinic to get contacts from
    //  * @private
    //  */
    // _getAllClinicContacts = (clinicId) => {
    //     return API_SPORDLE.get(queryString.stringifyUrl({ url: `clinics/${clinicId}/contacts` }))
    //         .then(response => {
    //             if (response.data.status) {
    //                 return response.data.clinic_contacts
    //             }
    //             throw new Error(response.data.errors[0].code)
    //         }, serverError)
    // }

    // /**
    //  * Returns an array of all the instructors for a clinic
    //  * @param {string} clinicId ID of the clinic to get instructors from
    //  * @private
    //  */
    // _getAllClinicInstructors = (clinicId) => {
    //     return API_SPORDLE.get(queryString.stringifyUrl({ url: `clinics/${clinicId}/instructors` }))
    //         .then(response => {
    //             if (response.data.status) {
    //                 return response.data.clinic_instructors
    //             }
    //             throw new Error(response.data.errors[0].code)
    //         }, serverError)
    // }

    // /**
    //  * Returns an array of all the sessions for a clinic
    //  * @param {string} clinicId ID of the clinic to get sessions from
    //  * @private
    //  */
    // _getAllClinicSessions = (clinicId) => {
    //     return API_SPORDLE.get(queryString.stringifyUrl({ url: `/clinics/${clinicId}/sessions`, query: { sort: '+start_date' } }))
    //         .then(response => {
    //             if (response.data.status) {
    //                 return response.data.clinic_sessions
    //             }
    //             throw new Error(response.data.errors[0].code)
    //         }, serverError)
    // }

    /**
     * Gets all the clinic's contacts
     * @param {string} clinicId The clinic we want to get the contacts from
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Clinics/Apicontroller%5CClinics%5CClinics%3A%3AgetAllClinicContacts|documentation}
     * @returns {Promise.<Array>}
     */
    getClinicContacts = (clinicId, queryParams = {}) => {
        if(this.state.currentClinic.clinic_id === clinicId && this.state.currentClinic.contacts){
            return Promise.resolve(this.state.currentClinic.contacts)
        }

        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/clinics/${clinicId}/contacts`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    this.setState((prevState) => ({
                        currentClinic: {
                            ...prevState.currentClinic,
                            contacts: response.data.clinic_contacts,
                        },
                    }))
                    return response.data.clinic_contacts;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Gets all the clinic's instructors
     * @param {string} clinicId The clinic we want to get the instructors from
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Clinics/Apicontroller%5CClinics%5CClinics%3A%3AgetAllClinicInstructors|documentation}
     * @returns {Promise.<Array>}
     */
    getClinicInstructors = (clinicId, queryParams = {}) => {
        if(this.state.currentClinic.clinic_id === clinicId && this.state.currentClinic.instructors){
            return Promise.resolve(this.state.currentClinic.instructors)
        }

        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/clinics/${clinicId}/instructors`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    this.setState((prevState) => ({
                        currentClinic: {
                            ...prevState.currentClinic,
                            instructors: response.data.clinic_instructors,
                        },
                    }))
                    return response.data.clinic_instructors;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Gets all the clinic's sessions
     * @param {string} clinicId The clinic we want to get the sessions from
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Clinic%20Sessions/Apicontroller%5CClinics%5CClinicsessions%3A%3AgetClinicSessions|documentation}
     * @returns {Promise.<Array>}
     */
    getClinicSessions = (clinicId, queryParams = {}) => {
        if(this.state.currentClinic.clinic_id === clinicId && this.state.currentClinic.sessions){
            return Promise.resolve(this.state.currentClinic.sessions)
        }

        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/clinics/${clinicId}/sessions`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    this.setState((prevState) => ({
                        currentClinic: {
                            ...prevState.currentClinic,
                            sessions: response.data.clinic_sessions,
                        },
                    }))
                    return response.data.clinic_sessions;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * @param {object} [queryParams] The params for that specific api call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Clinics/GetClinicsByParams|documentation}
     * @returns {Promise}
     */
    getClinics = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/clinics',
            // Creates an object with an org id by default being the current org
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.clinics;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Gets all the organization's item
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Fees/Apicontroller%5CPricing%5CFees%3A%3AgetAllFees|documentation}
     * @returns {Promise.<Array>}
     */
    getClinicItems = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/fees',
            query: Object.assign({
                organisation_id: this.props.OrganizationContext.organisation_id,
                //period_id: this.props.PeriodsContext.selectedPeriod.period_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.fees;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get all clinic attendees from a clinic
     * @param {string} clinic_id
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Clinic%20Attendees/Apicontroller%5CClinics%5CClinicAttendees%3A%3AgetClinicAttendees|documentation}
     * @returns {Promise.<Array>}
     */
    getClinicAttendees = (clinic_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/clinics/${clinic_id}/attendees`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.clinic_attendees;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get a specific clinic attendee
     * @param {string} clinic_id
     * @param {string} clinic_attendee_id
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Clinic%20Attendees/Apicontroller%5CClinics%5CClinicAttendees%3A%3AgetSpecificClinicAttendee|documentation}
     * @returns {Promise}
     */
    getClinicAttendee = (clinic_id, clinic_attendee_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/clinics/${clinic_id}/attendees/${clinic_attendee_id}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.clinic_attendee[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get a specific clinic attendee by member_id
     * @param {string} clinic_id
     * @param {string} member_id
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Clinic%20Attendees/Apicontroller%5CClinics%5CClinicAttendees%3A%3AgetSpecificClinicAttendeeByMemberId|documentation}
     * @returns {Promise}
     */
    getClinicAttendeeByMemberId = (clinic_id, member_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/clinics/${clinic_id}/members/${member_id}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.clinic_attendee[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * GET - get list of all clinic states by organization id
     * @param {string} [organisation_id] ID of the identity role
     * @returns {Promise.<Array>}
    */
    getClinicStates = (organisation_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/clinic-states',
            query: { organisation_id: organisation_id || this.props.OrganizationContext.organisation_id },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        })).then((response) => {
            if(response.data.status){
                return response.data.clinic_states.filter((state) => state.active == '1' && state.visible_on_platform == '1');
            }
            throw response.data.errors[0];
        }, serverError);
    }

    render(){
        return (
            <ClinicsContext.Provider value={{ ...this.state, ...this }}>
                {this.props.children}
            </ClinicsContext.Provider>
        );
    }
}

export default withContexts(PeriodsContext, QualificationCategoriesContext, OrganizationContext)(ClinicsContextProvider);
