const SearchImage = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250" {...props}>
            <g className="cls-1" style={{ isolation: 'isolate' }}>
                <g id="Calque_1" data-name="Calque 1">
                    <path style={{ opacity: 0.1, mixBlendMode: 'multiply' }} className="fill-dark" d="M236.38,221.58,213,198.2a2.54,2.54,0,0,0-3.6,0l-1.33,1.33-1.44-1.43c0-.11,0-.22,0-.34V179.2a55.64,55.64,0,0,0,0-49.64V18.87a5.32,5.32,0,0,0-5.31-5.32H53.17a5.32,5.32,0,0,0-5.31,5.32V197.76a5.32,5.32,0,0,0,5.31,5.32h77.06a55.65,55.65,0,0,0,53.46,0h16l2.41,2.41-1.33,1.33a2.55,2.55,0,0,0,0,3.61l23.38,23.38a6.75,6.75,0,0,0,9.54,0l2.68-2.69A6.74,6.74,0,0,0,236.38,221.58Z" />
                    <path className="opacity-05 fill-current cls-3" d="M241.47,149.25c8.31-10.3,8.36-25.78,3.69-37.65A44.3,44.3,0,0,0,233.56,95c-5.56-5-12.62-6.52-18.89-10.36-7.13-4.36-9.52-11.13-12-18.74-4.34-13.41-11.39-25.9-23.91-33.17-19.35-11.23-45.39-6.74-60,10.17C108.66,54.55,104.59,70,95.24,82.25A74.65,74.65,0,0,1,81.5,95.85C71,103.94,59,109.68,47,115.12,31.42,122.18,11.13,127,4.48,144.81c-7.28,19.45.76,44.45,13,60.15,19.24,24.71,51.69,32.42,81.38,25.3,19.22-4.62,36.39-14.67,51.83-26.77,10.42-8.17,19.12-18.24,29.22-26.76,10.95-9.24,21.6-13,35.6-15.47,9.33-1.61,18.23-3.43,24.9-10.77C240.77,150.08,241.13,149.67,241.47,149.25Z" />
                    <rect style={{ strokeMiterlimit: 10 }} className="fill-white stroke-gray" x="39.76" y="5.43" width="158.79" height="189.53" rx="8.53" />
                    <rect className="fill-current" x="49.25" y="14.9" width="139.8" height="9.47" rx="1.42" />
                    <rect className="fill-white opacity-05 cls-6" x="52.08" y="17.94" width="11.55" height="3.39" rx="0.59" />
                    <rect className="fill-white opacity-05 cls-6" x="67.55" y="17.94" width="38.89" height="3.39" rx="0.59" />
                    <rect className="opacity-025 cls-7" x="52.08" y="47.35" width="31.99" height="3.39" rx="0.59" />
                    <circle style={{ opacity: 0.1, mixBlendMode: 'multiply' }} className="fill-dark" cx="37.34" cy="80.35" r="25.37" />
                    <circle className="fill-current" cx="32.35" cy="75.37" r="25.37" />
                    <circle className="fill-current" cx="229.62" cy="61.03" r="14.34" />
                    <circle className="fill-current" cx="169.47" cy="230.23" r="14.34" />
                    <path style={{ strokeMiterlimit: 10, strokeLinecap: 'round', strokeDasharray: 6 }} className="fill-white stroke-gray" d="M110,130.82c0-2,.07-3.79.14-5.41a17.84,17.84,0,0,1,1.24-6.55,18.41,18.41,0,0,1,3.83-5.14,42.9,42.9,0,0,1,4.85-3.9c1.71-1.17,3.41-2.27,5.11-3.32a28.18,28.18,0,0,0,4.6-3.45,15.77,15.77,0,0,0,3.33-4.31,12.52,12.52,0,0,0,1.28-5.88,2.09,2.09,0,0,0,0-.25,12.69,12.69,0,0,0-1.17-5.21A14.36,14.36,0,0,0,129.94,83a15.69,15.69,0,0,0-4.76-3,15.51,15.51,0,0,0-5.78-1.09A12.44,12.44,0,0,0,114.16,80,14.85,14.85,0,0,0,110,82.84a15.66,15.66,0,0,0-3,3.92,17.82,17.82,0,0,0-1.7,4.51,11,11,0,0,1-2.07,4.12,5.09,5.09,0,0,1-3.86,1.88H90.52a2.51,2.51,0,0,1-2.17-.8,3.77,3.77,0,0,1-.7-1.69V92.93A28.9,28.9,0,0,1,90.3,80.8a29.89,29.89,0,0,1,6.93-9.53,31.72,31.72,0,0,1,10.1-6.2A34,34,0,0,1,131.41,65a31.65,31.65,0,0,1,10,6,28.79,28.79,0,0,1,6.87,9.21,26.54,26.54,0,0,1,2.53,10.51q0,.58,0,1.17a29.69,29.69,0,0,1-1.73,10.83c-.23.59-.47,1.16-.73,1.71" />
                    <path style={{ strokeMiterlimit: 10 }} className="fill-white stroke-gray" d="M172.11,95.79a55.56,55.56,0,1,0,14.76,91c.43-.39.85-.8,1.26-1.21a55.59,55.59,0,0,0-16-89.75Zm6.31,80a41.83,41.83,0,1,1,0-59.15A41.66,41.66,0,0,1,178.42,175.83Z" />
                    <path style={{ opacity: 0.05 }} className="fill-white" d="M137.48,106a47.71,47.71,0,0,0,13.35,82,41.83,41.83,0,0,1-13.35-82Z" />
                    <path className="fill-current" d="M189.2,183h6.12a1.15,1.15,0,0,1,1.15,1.15v12.21a0,0,0,0,1,0,0h-8.42a0,0,0,0,1,0,0V184.14A1.15,1.15,0,0,1,189.2,183Z" transform="translate(-77.8 191.5) rotate(-45)" />
                    <path className="fill-white stroke-gray" d="M206.58,188.18h10.76a3.27,3.27,0,0,1,3.27,3.27V221.9a8.65,8.65,0,0,1-8.65,8.65h0a8.65,8.65,0,0,1-8.65-8.65V191.45A3.27,3.27,0,0,1,206.58,188.18Z" transform="translate(-85.96 211.2) rotate(-45)" />
                    <path style={{ opacity: 0.1 }} className="fill-dark" d="M178.42,116.68A41.82,41.82,0,0,1,150.83,188a47.71,47.71,0,0,1-13.35-82A41.79,41.79,0,0,1,178.42,116.68Z" />
                    <path style={{ strokeMiterlimit: 10, strokeLinecap: 'round', strokeDasharray: 6 }} className="fill-none stroke-gray" d="M153.79,104.72a28.81,28.81,0,0,1-3,4.36,36.22,36.22,0,0,1-6.89,6.3c-2.47,1.71-4.77,3.33-6.89,4.89a29.17,29.17,0,0,0-5.33,4.88,9.49,9.49,0,0,0-2.12,6.26v6a17.83,17.83,0,0,1-.24,3.23,4.59,4.59,0,0,1-.78,2,2.63,2.63,0,0,1-1.46.94,9,9,0,0,1-2.25.24H111.48a3.33,3.33,0,0,1-2.72-1.18,4.1,4.1,0,0,1-1-2.68v-1.59m14.65,40.35h3.7a3.66,3.66,0,0,0,2-.44,2.61,2.61,0,0,0,1.06-1.21,5.36,5.36,0,0,0,.44-1.74c.05-.63.08-1.31.08-2V161.59a3,3,0,0,0-.55-1.94,3.71,3.71,0,0,0-1.38-1,5.65,5.65,0,0,0-1.81-.43c-.66,0-1.31-.08-1.94-.08H111.63a3,3,0,0,0-2.58,1" />
                </g>
            </g>
        </svg>
    );
}

export default SearchImage;