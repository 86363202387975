import { useEffect, useRef, useState } from "react";
import ReactTooltip from "react-tooltip";

function useIsVisible(ref){
    const [ isVisible, setIsVisible ] = useState(false);

    useEffect(() => {
        if(ref){
            const top = ref.getBoundingClientRect().top;
            setIsVisible(top >= 0 && top <= window.innerHeight);
        }else{
            setIsVisible(false);
        }
    });

    return isVisible;
}

export function useTooltip(){
    const tooltipRef = useRef(null);
    const isVisible = useIsVisible(tooltipRef.current);

    const showTooltip = () => {
        if(tooltipRef.current){
            ReactTooltip.show(tooltipRef.current);
        }
    }

    const hideTooltip = () => {
        if(tooltipRef.current){
            ReactTooltip.hide(tooltipRef.current);
        }
    }

    useEffect(() => {
        if(!isVisible){
            hideTooltip();
        }
        return hideTooltip;
    }, [ isVisible ])

    return { ref: tooltipRef, onMouseEnter: showTooltip, onMouseLeave: hideTooltip };
}