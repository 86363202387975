import { SpordleTableView, useSpordleTable } from '@spordle/datatables';
import Translate from "@spordle/intl-elements";
import { useContext, useEffect, useState } from "react";
import { Alert, Button, Col, Collapse, Container, Fade, Row } from 'reactstrap';
import SassVars from "../../../assets/scss/custom/components/_header.module.scss";
import Sticky from "../../../components/Sticky";
import { I18nContext } from "../../../contexts/I18nContext";
import { Form, Formik } from 'formik';
import { FormikInputText, FormikSelect } from '@spordle/formik-elements';
import { object, string } from 'yup';
import { UtilsContext } from '../../../contexts/UtilsContext';
import { DisplayI18n } from '../../../helpers/i18nHelper';
import { getLocalStorageItem, setLocalStorageItem } from '../../../helpers/browserStorage';
import AdSlot from '../../../components/advertisement/AdSlot';
import { stringBuilder } from '@spordle/helpers';

const RegisterContent = () => {
    const spordleTable = useSpordleTable();
    const { getCountries } = useContext(UtilsContext);
    const i18nContext = useContext(I18nContext);
    const province = getLocalStorageItem('province');
    const [ showHelper, setShowHelper ] = useState(false);

    useEffect(() => {
        if(spordleTable.getFilters().postalCode &&
           spordleTable.getData().length > 0 &&
           !spordleTable.getData().find((org) => org.zip_code === spordleTable.getFilters().postalCode) &&
           spordleTable.state.loadingState === 'success'
        ){
            setShowHelper(true);
        }else{
            setShowHelper(false);
        }
    }, [ spordleTable.getFilters().postalCode, spordleTable.state.loadingState ]);

    return (
        <>
            <Sticky top={SassVars.navbarHeight}>
                <div className="register-hero hero bg-primary" style={{ backgroundImage: "url(https://spordle-frontend-assets.s3.ca-central-1.amazonaws.com/hcrsite/playHero_desktop.jpg)" }}>
                    <Container>
                        <div className="d-lg-flex">
                            <div className="hero-content mb-2">
                                <img className="hero-logo" src={"https://spordle-frontend-assets.s3.ca-central-1.amazonaws.com/hcrsite/HC@2x.png"} alt="Hockey Canada" />
                                <h1 className="hero-text">
                                    <span className="hero-text-small font-title-small">
                                        <Translate id="register.title.find" />
                                    </span>
                                    <span className="hero-text-big font-title">
                                        <Translate id="register.title.organisation" />
                                    </span>
                                </h1>
                            </div>
                            <div className="flex-grow-1 register-search pb-3 text-dark">
                                <Formik
                                    enableReinitialize
                                    initialValues={{
                                        postalCode: "",
                                        provinceCode: province || "",
                                    }}
                                    validationSchema={object().shape({
                                        postalCode: string().required(),
                                        provinceCode: string().required(),
                                    })}
                                    onSubmit={(values) => {
                                        setLocalStorageItem('province', values.provinceCode);
                                        spordleTable.filterChange('postalCode', values.postalCode, false);
                                        spordleTable.filterChange('provinceCode', values.provinceCode);
                                    }}
                                >
                                    {(formik) => (
                                        <Form>
                                            <Row form>
                                                <Col md='5' className='mb-2'>
                                                    <FormikSelect
                                                        id={spordleTable.generateId('filters', 'provinceCode')}
                                                        name="provinceCode"
                                                        manualError
                                                        placeholder="register.search.provinceCode.placeholder"
                                                        className='branch-select'
                                                        searchKeys={[
                                                            `i18n.${i18nContext.getGenericLocale()}.name`,
                                                        ]}
                                                        renderOption={({ option }) => (
                                                            <DisplayI18n
                                                                field="name"
                                                                i18n={option.i18n}
                                                                defaultvalue={option.label}
                                                            />
                                                        )}
                                                        loadData={(from) => {
                                                            switch (from){
                                                                case 'CDM':
                                                                    return getCountries()
                                                                        .then((countries) => {
                                                                            const canada = countries.find((country) => country.code === "CA");
                                                                            return canada.sub_divisions.map((province) => ({ value: province.code, i18n: province.i18n, label: province.code }))
                                                                        })
                                                            }
                                                        }}
                                                    />
                                                </Col>
                                                <Col md='4' className='mb-2'>
                                                    <FormikInputText
                                                        type="search"
                                                        name="postalCode"
                                                        className="no-clear"
                                                        placeholder="register.search.postalCode.placeholder"
                                                        manualError
                                                    />
                                                </Col>
                                                <Col md='3'>
                                                    <Button color="dark" type="submit" block>
                                                        <Translate id="misc.search" />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </Container>
                </div>
            </Sticky>
            <Container className="pb-5 pt-4 container-lg">
                {spordleTable.getDisplayedData().length > 0 &&
                    <div className={stringBuilder({ "d-none": spordleTable.state.loadingState == "error" })}>
                        <AdSlot
                            adId={i18nContext.getGenericLocale() == "fr" ? "1699036793050" : "1699036745568"}
                            className="d-flex justify-content-center mb-4"
                            minWidth={320}
                            minHeight={50}
                        />
                    </div>
                }
                <Collapse isOpen={showHelper}>
                    <Fade in={showHelper}>
                        <Alert color='dark'>
                            <Translate id='register.search.result.helper' />
                        </Alert>
                    </Fade>
                </Collapse>
                <SpordleTableView />
                {spordleTable.getDisplayedData().length > 0 &&
                    <div className={stringBuilder({ "d-none": spordleTable.state.loadingState == "error" })}>
                        <AdSlot
                            adId={i18nContext.getGenericLocale() == "fr" ? "1699036889181" : "1699036854795"}
                            className="d-flex justify-content-center mt-5"
                            minWidth={320}
                            minHeight={50}
                        />
                    </div>
                }
            </Container>
        </>
    );
}

export default RegisterContent;