import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import withContexts from '../helpers/withContexts';
import { OrganizationContext } from './OrganizationContext';

/** @type {React.Context<Omit<QualificationCategoriesContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'> & QualificationCategoriesContextProvider['state']>} */
export const QualificationCategoriesContext = createContext();
QualificationCategoriesContext.displayName = 'QualificationCategoriesContext';

class QualificationCategoriesContextProvider extends React.Component{
    // /** @type {?Array} */
    // qualificationCategories = null;

    state = {
        qualificationCategories: null,
    }

    /**
     * Get a list of all the categories for a clinic
     * @param {boolean} [fromApi=false] Will fetch the data from the api
     * @returns {Promise.<Array>}
     */
    getQualificationCategories = (fromApi = false) => {
        if(Array.isArray(this.state.qualificationCategories) && !fromApi){
            return Promise.resolve(this.state.qualificationCategories);
        }

        return API_SPORDLE.get(queryString.stringifyUrl({ url: `/qualification-categories`, query: { organisation_id: this.props.OrganizationContext.federation.organisation_id } }))
            .then((response) => {
                if(response.data.status){
                    // Lite Format
                    const qualification_categories = response.data.qualification_categories || [];
                    const organisations = response.data.organisations || {};

                    // Build Resources
                    return qualification_categories.map((category) => {
                        if(organisations[category.organisation_id]){
                            category.organisation = organisations[category.organisation_id];
                            category.organisation.organisation_id = category.organisation_id;
                        }

                        // Cleanup resource IDs
                        delete category.organisation_id;

                        return category;
                    })
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <QualificationCategoriesContext.Provider value={{ ...this, ...this.state }}>
                {this.props.children}
            </QualificationCategoriesContext.Provider>
        );
    }
}

export default withContexts(OrganizationContext)(QualificationCategoriesContextProvider);
