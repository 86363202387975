import header from "./header";
import misc from "./misc";
import form from "./form";
import sidebar from "./sidebar";
import footer from "./footer";
import warning from "./warnings";

export default{
    ...header,
    ...misc,
    ...form,
    ...sidebar,
    ...footer,
    ...warning,
}