// contexts
import { useSpordleTable } from '@spordle/datatables';
import SpordleSelect from '@spordle/spordle-select';
import { useContext, useEffect, useState } from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import { AxiosIsCancelled } from '../api/CancellableAPI';
import { I18nContext } from '../contexts/I18nContext';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { DisplayI18n } from '../helpers/i18nHelper';
import UserImg from './UserImg';

const SelectOrganisation = ({ branchOrgs, initialBranchOrgId, orgCategories, resetFilters, id }) => {
    const organizationContext = useContext(OrganizationContext);
    const i18nContext = useContext(I18nContext)
    const spordleTable = useSpordleTable();

    const [ branchSelectOptions, setBranchSelectOptions ] = useState(false);

    const [ subOrgsSelectIsLoading, setSubOrgsSelectIsLoading ] = useState(false);
    const [ subOrgsSelectOptions, setSubOrgsSelectOptions ] = useState(false);

    useEffect(() => {
        if(branchOrgs && initialBranchOrgId && orgCategories){
            setBranchSelectOptions(branchOrgs.map((org) => ({
                abbr: org.abbreviation,
                value: org.organisation_id,
                label: org.organisation_name,
                i18n: org.i18n,
                logo: org.logo,
            })))
            getSubOrgs(initialBranchOrgId)
        }
    }, [ branchOrgs, initialBranchOrgId, orgCategories ])

    const getSubOrgs = (orgId) => {
        setSubOrgsSelectIsLoading(true);
        organizationContext.getCategoryOrganizations(orgId, orgCategories.map(({ category_id }) => category_id))
            .then((orgs) => {
                const filteredOrgs = orgs.reduce((options, org) => {
                    if(org.visible_on_website != "0") {
                        options.push({
                            abbr: org.abbreviation,
                            value: org.organisation_id,
                            label: org.organisation_name,
                            i18n: org.i18n,
                            logo: org.logo,
                        })
                    }

                    return options;
                }, [])

                setSubOrgsSelectOptions(filteredOrgs);
                setSubOrgsSelectIsLoading(false);
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e.message)
                    setSubOrgsSelectIsLoading(false);
                }
            })
    }

    return (
        <Row form>
            <Col md='6'>
                <FormGroup>
                    <SpordleSelect
                        id={spordleTable.generateId('filters', 'organization')}
                        className='text-dark'
                        values={spordleTable.getFilters().organizationId ? [ spordleTable.getFilters().organizationId ] : []}
                        searchKeys={[
                            `i18n.${i18nContext.getGenericLocale()}.name`,
                            `abbr`,
                        ]}
                        options={branchSelectOptions || []}
                        renderOption={(option) => <CustomOptionRender org={option.option} selectedOption={false} />}
                        renderSelectedOption={(option) => <CustomOptionRender org={option} selectedOption />}
                        onOptionSelected={(values) => {
                            if(spordleTable.getFilters().organizationId !== values[0]){
                                getSubOrgs(values[0])
                                resetFilters()
                                spordleTable.filterChange('organizationId', values[0])
                                spordleTable.filterChange('subOrganizationId', '')
                            }
                        }}
                    />
                </FormGroup>
            </Col>
            <Col md='6'>
                <FormGroup>
                    <SpordleSelect
                        id={spordleTable.generateId('filters', 'subOrganizationId')}
                        className='text-dark'
                        clearable
                        isLoading={subOrgsSelectIsLoading}
                        values={spordleTable.getFilters().subOrganizationId ? [ spordleTable.getFilters().subOrganizationId ] : []}
                        searchKeys={[
                            `i18n.${i18nContext.getGenericLocale()}.name`,
                            `abbr`,
                        ]}
                        options={subOrgsSelectOptions || []}
                        renderOption={(option) => <CustomOptionRender org={option.option} selectedOption={false} />}
                        renderSelectedOption={(option) => <CustomOptionRender org={option} selectedOption />}
                        onOptionSelected={(values) => {
                            if(!values[0]){
                                spordleTable.filterChange('subOrganizationId', '');
                            }else{
                                resetFilters()
                                spordleTable.filterChange('subOrganizationId', values[0]);
                            }
                        }}
                    />
                </FormGroup>
            </Col>
        </Row>

    );
}

const CustomOptionRender = ({ org, selectedOption }) => (
    <div className={`d-flex align-items-center ${!selectedOption ? 'py-2' : ''}`}>
        <div className="d-flex justify-content-center align-items-center mr-2">
            <UserImg
                abbr={org.abbr || org.label}
                src={org.logo?.full_path}
                filePos={org.logo?.file_position}
                width={25}
                height={25}
                alt={org.organisation_name + ' logo'}
                className="p-1 shadow-sm"
            />
        </div>
        <span className='text-truncate'><DisplayI18n field='name' defaultValue={org.label} i18n={org.i18n} />{org.abbr && <span className="text-muted"> ({org.abbr})</span>}</span>
    </div>
);

export default SelectOrganisation;