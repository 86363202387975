export default{
    // headings
    "login.heading.title" : "Hockey Canada Registry",
    "login.heading.intro" : "Welcome to the new Hockey Canada Registry homepage, the central hub of participant registration and national affiliation management platform for all levels and activities (clinics, training sessions, certifications) of minor hockey across the country.",
    "login.heading.cta"   : "Learn More",

    // Login form
    "login.form.label.title"                  : "Login",
    "login.form.label.email"                  : "Email",
    "login.form.label.password"               : "Password",
    "login.form.label.rememberMe"             : "Remember me",
    "login.form.action.submit"                : "Login",
    "login.form.action.forgotPassword"        : "Forgot password?",
    "login.form.label.signup"                 : "Dont have an account?",
    "login.form.action.signup"                : "Sign up",
    "login.form.validation.email.required"    : "Email is required",
    "login.form.validation.email.email"       : "This must be a valid email",
    "login.form.validation.password.required" : "Password is required",

    // Admin login
    "login.adminConnexion.loading"       : "Login...",
    "login.adminConnexion.EXPIRED_TOKEN" : "Your session has expired. Please log back in.",

    // AntiSpam
    "login.toast.warning.spam" : "Warning, please wait a few seconds to continue",

    // Error
    "login.error.server" : "There was a problem. Please refresh the page and try again.",
    "login.error.101"    : "Mauvais mot de passe.",
    "login.error.3028"   : "The email or password is incorrect.",
}