export default{
    // general
    "clinics.location.ONLINE"      : "En ligne - Sur demande",
    "clinics.location.ONLINE_LIVE" : "En ligne - En direct",
    "clinics.location.ONSITE"      : "En personne",

    "clinics.status.OPEN_PUBLIC_SIGNUP"    : "Inscription ouverte",
    "clinics.status.OPEN_PUBLIC_SHOW_LIST" : "Ouverte au public",
    "clinics.status.PRIVATE"               : "Privée",
    "clinics.status.DRAFT"                 : "Brouillon",
    "clinics.status.UNDER_CREATION"        : "En création",

    "clinics.date"                 : "Date",
    "clinics.dates"                : "Dates",
    "clinics.date.to"              : "À",
    "clinics.date.from"            : "De",
    "clinics.date.until"           : "Jusqu'au",
    "clinics.hostOrganization"     : "Organisation hôte",
    "clinics.category"             : "Catégorie",
    "clinics.qualification"        : "Qualification",
    "clinics.level"                : "Niveau",
    "clinics.noLevel"              : "Aucun niveau",
    "clinics.description"          : "Description",
    "clinics.noDescription"        : "Aucune description",
    "clinics.soldOut"              : "Épuisé",
    "clinics.soldOutWaitingList"   : "Épuisé - Liste d'attente",
    "clinics.click.here.to.manage" : "Cliquer ici pour",
    "clinics.manage"               : "Gérer la clinique dans SID",

    "clinics.firstSession" : "Première séance (sur {sessions})",

    // table
    "clinics.table.paginationMessage"       : "Stages {itemStartPos} à {itemEndPos} sur un total de {totalItems} stages",
    "clinics.table.emptyLayout.noResult"    : "Aucun résultat...",
    "clinics.table.emptyLayout.noResultTip" : "Vous pouvez effectuer une recherche ou utiliser les filtres",

    // filters
    "clinics.filters.categories"        : "Catégories",
    "clinics.filters.qualification"     : "Qualification",
    "clinics.filters.period"            : "Saison",
    "clinics.filters.allPeriods"        : "Toutes les saisons",
    "clinics.filters.clinic_reference"  : "Numéro de référence",
    "clinics.filters.startDate"         : "Date de début",
    "clinics.filters.startDate.between" : "La date de début doit être dans la plage de dates de la période sélectionnée",
    "clinics.filters.startDate.before"  : "La date de début doit être avant la date de fin",
    "clinics.filters.endDate"           : "Date de fin",
    "clinics.filters.endDate.between"   : "La date de fin doit être dans la plage de dates de la période sélectionnée",
    "clinics.filters.endDate.before"    : "La date de fin doit être après la date de début",
    "clinics.filters.date.placeholder"  : "AAAA-MM-JJ",
    "clinics.filters.favoritesOnly"     : "Favoris seulement",
    "clinics.filters.priceRange"        : "Échelle de prix",
    "clinics.filters.status"            : "Statut",
    "clinics.filters.sessionFormat"     : "Format des séances",
    "clinics.filters.orSeperator"       : "ou",

    "clinics.filters.resetFilters" : "Réinitialiser les filtres",
    "clinics.filters.clearAll"     : "Tout effacer",
    "clinics.filters"              : "Filtres",
    "clinics.filters.refine"       : "Affiner la recherche de cliniques",

    // branch select
    "clinics.branchSelect.selectBranch" : "Sélectionner votre organisation",
    "clinics.branchSelect.selectOrg"    : "Sélectionner une organisation...",

    // hero
    "clinics.hero.find"        : "Trouver votre stage",
    "clinics.hero.find.1"      : "Trouver votre",
    "clinics.hero.find.2"      : "stage",
    "clinics.hero.presentedBy" : "Présenté par",
    "clinics.hero.placeholder" : "Saisissez un numéro de stage",
}