import register from "./register";
import clinics from "./clinics";
import maintenance from "./maintenance";
import home from "./home";
import about from "./about";
import support from "./support";

export default{
    ...clinics,
    ...maintenance,
    ...register,
    ...home,
    ...about,
    ...support,
}