export default{
    "forgotPassword.title.accountRecovery" : "Account Recovery",

    "forgotPassword.label.enterEmail"         : "To reset your password, please enter your email address below",
    "forgotPassword.label.noEmail"            : "Didn't receive an email?",
    "forgotPassword.label.enterNewPassword"   : "Please enter your new password",
    "forgotPassword.label.confirmNewPassword" : "Confirm your new password",

    "forgotPassword.action.backToLogin"        : "Back to login",
    "forgotPassword.action.sendEmail"          : "Send an email",
    "forgotPassword.action.confirmNewPassword" : "Submit my new password",

    "forgotPassword.success.emailSent"       : "An email has been sent to the address provided. Please follow the link found within to reset your HCR password.",
    "forgotPassword.success.emailSent.toast" : "An email has been sent!",
    "forgotPassword.success.newPassword"     : "Your password has been changed!",

    "forgotPassword.error.genericError"  : "A problem occured. Please refresh the page and try again.",
    "forgotPassword.error.requiredEmail" : "An email address is required.",
    "forgotPassword.error.invalidEmail"  : "Please enter a valid email.",

    "forgotPassword.error.300" : "The email provided is not linked to any account.",
}