export default{
    "clinics.detail.cart.questions.title"         : "Questionnaire",
    "clinics.detail.cart.questions.subtitle"      : "Questions pour le participant",
    "clinics.detail.cart.questions.of"            : "de",
    "clinics.detail.cart.questions.required"      : "Ce champ est requis",
    "clinics.detail.cart.questions.min"           : "Veuillez respecter la valeur minimum",
    "clinics.detail.cart.questions.max"           : "Veuillez respecter la valeur maximum",
    "clinics.detail.cart.questions.label.between" : "Entre",
    "clinics.detail.cart.questions.label.and"     : "et",
    "clinics.detail.cart.questions.label.min"     : "Minimum",
    "clinics.detail.cart.questions.label.max"     : "Maximum",
}