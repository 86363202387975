import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import withContexts from '../helpers/withContexts';
import { I18nContext } from './I18nContext';
import { displayI18n } from '../helpers/i18nHelper';

import * as Sentry from "@sentry/react";

/** @type {React.Context<Omit<PeriodsContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const PeriodsContext = createContext();
PeriodsContext.displayName = 'PeriodsContext';

class PeriodsContextProvider extends React.Component{
    /*
    State exemple:
        state = {
            selectedPeriod: {
                "period_id":"1eb6a483-7a42-6186-82db-02c510e3b1a6",
                "name":"Season 2020-2021",
                "active":"1",
                "start_date":"2020-06-01",
                "end_date":"2021-05-01",
                "shared_date":null,
                "calculation_date":null,
                "organisation":{
                    "organisation_id":"1eb1f80b-0885-62e2-87c6-e454e80c8447",
                    "organisation_name":"Hockey Canada"},
                    "current":1,
                    "i18n":{"fr":{"name":"Saison 2020-2021"},"en":{"name":"Season 2020-2021"}
                }
            },
            periods: [
                {
                    "period_id":"1eb5a60a-8e99-68fa-ba7f-a8a1592b6e57",
                    "name":"Season 2018-2019",
                    "active":"1",
                    "start_date":"2018-06-01",
                    "end_date":"2019-05-01",
                    "shared_date":null,
                    "calculation_date":"2020-12-08",
                    "organisation":{"organisation_id":"1eb1f80b-0885-62e2-87c6-e454e80c8447","organisation_name":"Hockey Canada"},
                    "current":0,
                    "i18n":{"fr":{"name":"Saison 2018-2019"},"en":{"name":"Season 2018-2019"}}
                }
            ]
        }
    */

    state = {
        periods: false,
    }

    componentDidUpdate(){
        Sentry.setContext('period', this.state.periods ? {
            periods: JSON.stringify(this.state.periods.map((period) => ({
                period_id: period.period_id,
                name: period.name,
            }))),
        } : null);
        // doesn't apply here since there is no selected Period like in SID
        // Sentry.setTag('periodId', this.state.periods);
    }

    /**
     * Set the selected period (header view dropdown)
     * @param {object} period The selected period
     * @returns {void}
     */
    setSelectedPeriod = (period, callback) => {
        this.setState(() => ({ selectedPeriod: period }), callback);
    }

    /**
     * Get all the periods within an organization
     * @param {string} organizationId The organization id we want to get the periods from
     * @returns {Promise<Array>}
     */
    getOrganizationPeriods = (organizationId) => {
        if(this.state.periods){
            return Promise.resolve(this.state.periods)
        }
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `periods`, query: { organisation_id: organizationId } }))
            .then((response) => {
                if(response.data.status){
                    const _periods = response.data.periods?.sort((a, b) => {
                        const valueA = (displayI18n("name", a.i18n, a.name, this.props.I18nContext.getGenericLocale())).toLowerCase()
                        const valueB = (displayI18n("name", b.i18n, b.name, this.props.I18nContext.getGenericLocale())).toLowerCase()
                        if(valueA > valueB)
                            return 1;
                        else if(valueA < valueB)
                            return -1;
                        return 0;
                    })
                    this.setState(() => ({ periods: _periods }));
                    return _periods;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get period information
     * @param {string} periodId
     * @returns {Promise}
     */
    getPeriod = (periodId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `periods/${periodId}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.periods[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <PeriodsContext.Provider value={{ ...this }}>
                {this.props.children}
            </PeriodsContext.Provider>
        )
    }
}

export default withContexts(I18nContext)(PeriodsContextProvider);