import Translate from '@spordle/intl-elements';
import { Container } from 'reactstrap';
import AnErrorOcured from './assets/images/AnErrorOcured.png';
import { I18nProvider } from './i18n';
import { builtLocale } from './contexts/I18nContext';

/**
 * @type {import('@sentry/react/dist/errorboundary').FallbackRender}
 */
const FatalError = (props) => {
    // console.log(props);
    return (
        <I18nProvider locale={builtLocale(window.navigator.userLanguage?.toLowerCase() || window.navigator.language.toLowerCase() || 'en')}>
            <div className='min-vh-100 bg-light d-flex align-items-center'>
                <Container className="flex-grow-1">
                    <div className='text-center h2 mb-3 font-bold'>
                        <Translate id='misc.error' />...
                    </div>
                    <div className='text-center'>
                        <img src={AnErrorOcured} height='250' />
                    </div>
                    <div className='text-center mt-3'>
                        <a href={window.location.href} className="btn btn-primary font-medium"><Translate id='misc.fatalError.goback' /></a>
                    </div>
                </Container>
            </div>
        </I18nProvider>
    );
}

export default FatalError;