import Translate from '@spordle/intl-elements';
import { Container } from 'reactstrap';
import AdSlot from '../../components/advertisement/AdSlot';
import { useContext, useEffect, useState } from 'react';
import { I18nContext } from '../../contexts/I18nContext';
import { destroyHCRSlots, initializeAdSlots } from '../../components/advertisement/adsHelper';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const About = () => {
    const i18nContext = useContext(I18nContext);
    const [ adIsReady, setAdIsReady ] = useState(false);

    useEffect(() => {
        initializeAdSlots(i18nContext.getGenericLocale());
        setAdIsReady(true);

        return () => {
            destroyHCRSlots();
        }
    }, []);

    return (
        <main>
            <Container className="pt-4 pb-5">
                <AdSlot
                    adIsReady={adIsReady}
                    adId={i18nContext.getGenericLocale() == "fr" ? "1699036793050" : "1699036745568"}
                    className="d-flex justify-content-center mb-5"
                    minWidth={320}
                    minHeight={50}
                />

                <div className="clearfix">
                    <AdSlot
                        className="float-right ml-4 mb-4"
                        adIsReady={adIsReady}
                        adId={i18nContext.getGenericLocale() == "fr" ? "1699036608579" : "1699036561080"}
                    />
                    <h1><Translate id='about.hcr.title' /></h1>
                    <p>
                        <Translate
                            id='about.hcr.text'
                            values={{
                                br: () => (
                                    <br />
                                ),
                            }}
                        />
                    </p>

                    <h2 className="mt-5 h4"><Translate id='about.hc.title' /></h2>
                    <p>
                        <Translate
                            id='about.hc.text'
                            values={{
                                link: (chunks) => (
                                    <a target='_blank' href={i18nContext.getGenericLocale() === "fr" ? 'https://www.hockeycanada.ca/fr-ca' : 'https://www.hockeycanada.ca/en-ca'}>
                                        {chunks}
                                    </a>
                                ),
                                ig: (chunks) => (
                                    <a target='_blank' href={'https://www.instagram.com/hockeycanada'}>
                                        {chunks}
                                    </a>
                                ),
                                fb: (chunks) => (
                                    <a target='_blank' href={'https://www.facebook.com/HockeyCanada/'}>
                                        {chunks}
                                    </a>
                                ),
                                x: (chunks) => (
                                    <a target='_blank' href={'https://twitter.com/HockeyCanada?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor'}>
                                        {chunks}
                                    </a>
                                ),
                                br: () => (
                                    <br />
                                ),
                            }}
                        />
                    </p>

                    <h2 className="mt-5 h4"><Translate id='about.spordle.title' /></h2>
                    <p className="mb-0">
                        <Translate
                            id='about.spordle.text'
                            values={{
                                link: (chunks) => (
                                    <a target='_blank' href={'https://solutions.spordle.com'}>
                                        {chunks}
                                    </a>
                                ),
                                br: () => (
                                    <br />
                                ),
                            }}
                         />
                    </p>
                </div>
                <AdSlot
                    adIsReady={adIsReady}
                    adId={i18nContext.getGenericLocale() == "fr" ? "1699036889181" : "1699036854795"}
                    className="d-flex justify-content-center mt-5"
                    minWidth={320}
                    minHeight={50}
                />
            </Container>
        </main>
    );
}

export default About;