export default{
    "clinics.detail.cart.waivers.title"    : "Dispenses",
    "clinics.detail.cart.waivers.subtitle" : "Dispenses",
    "clinics.detail.cart.waivers.of"       : "de",
    "clinics.detail.cart.waivers.accept"   : "J'ai lu et j'accepte la dispense",
    "clinics.detail.cart.waivers.required" : "Cette dispense est obligatoire",
    "clinics.detail.cart.terms.title"      : "Termes et conditions",
    "clinics.detail.cart.terms.subtitle"   : "Termes et conditions",
    "clinics.detail.cart.terms.accept"     : "J'ai lu et j'accepte les termes et conditions",
    "clinics.detail.cart.terms.required"   : "Veuillez cocher la case ci-dessus pour confirmer que vous acceptez ces termes et conditions",
}