export default{
    "clinics.detail.cart.summary.title"                     : "Registration summary",
    "clinics.detail.cart.summary.orderSummary"              : "Order summary",
    "clinics.detail.cart.summary.for"                       : "Registration for",
    "clinics.detail.cart.summary.edit"                      : "Edit this member's registration",
    "clinics.detail.cart.summary.removeMember"              : "Remove this member",
    "clinics.detail.cart.summary.removeMember.confirmation" : "This member will be removed from your order",
    "clinics.detail.cart.summary.registration"              : "Registration",
    "clinics.detail.cart.summary.requiredItems"             : "Required items",
    "clinics.detail.cart.summary.otherItems"                : "Other items",
    "clinics.detail.cart.summary.action"                    : "This action is irreversible",
    "clinics.detail.cart.summary.credit"                    : "Available credits",
    "clinics.detail.cart.summary.credit.activate"           : "Activate credits for this member",
    "clinics.detail.cart.summary.credit.deactivate"         : "Deactivate credits for this member",
    "clinics.detail.cart.summary.rebate"                    : "Discount",
    "clinics.detail.cart.summary.subtotal"                  : "Subtotal for",
    "clinics.detail.cart.summary.total"                     : "Total price",
    "clinics.detail.cart.summary.noTaxes"                   : "taxes are not included",
    "clinics.detail.cart.summary.taxes"                     : "Taxes",
}