/**
 *
 * @param {'SPORDLE'|'ACCOUNT'|'HCR'|'PAGE'|'PAGE_VERCEL'|'PAGE_VERCEL-2'|'TOUR'|'HCR-SITE'} platform
 * @returns
 */
function getReferrer(platform){
    switch (platform){
        case 'SPORDLE':
            return window.location.href;
        case 'ACCOUNT':
            switch (process.env.REACT_APP_ENVIRONMENT){
                case 'prod':
                    return `https://account.spordle.com`;
                case 'training':
                    return `https://account.training.hcr.spordle.com`;
                case 'uat':
                    return `https://account.uat.spordle.dev`;
                case 'hcstage':
                    return `https://account.hcstage.spordle.dev`;
                case 'stage':
                    return `https://account.stage.spordle.dev`;
                case 'int':
                    return `https://account.int.spordle.dev`;
                case 'dev':
                default:
                    return `https://account.dev.spordle.dev`;
            }
        case 'PAGE':
            switch (process.env.REACT_APP_ENVIRONMENT){
                case 'prod':
                    return 'https://page.hockeycanada.ca';
                case 'training':
                    return `https://page.training.hcr.spordle.com`;
                case 'uat':
                    return `https://page.uat.spordle.dev`;
                case 'hcstage':
                    return `https://page.hcstage.spordle.dev`;
                case 'stage':
                    return `https://page.stage.spordle.dev`;
                case 'int':
                    return `https://page.int.spordle.dev`;
                case 'dev':
                default:
                    return `https://page.dev.spordle.dev`;
            }
        case 'PAGE-VERCEL':
            switch (process.env.REACT_APP_ENVIRONMENT){
                case 'prod':
                    return 'https://page2.hockeycanada.ca';
                case 'training':
                    return `https://pages-git-training-spordle.vercel.app`;
                case 'uat':
                    return `https://pages-git-uat-spordle.vercel.app`;
                case 'hcstage':
                    return `https://pages-git-hcstage-spordle.vercel.app`;
                case 'stage':
                    return `https://pages-git-stage-spordle.vercel.app`;
                case 'int':
                    return `https://pages-git-int-spordle.vercel.app`;
                case 'dev':
                default:
                    return `https://pages-git-dev-spordle.vercel.app`;
            }
        case 'PAGE-VERCEL-2':
            switch (process.env.REACT_APP_ENVIRONMENT){
                case 'prod':
                    return 'https://page.spordle.com';
                case 'training':
                case 'uat':
                    return `https://page.v2.spordle.dev`;
                case 'hcstage':
                case 'stage':
                    return `https://pages-next-v2-git-pagenextv2-stage-spordle.vercel.app`;
                case 'int':
                case 'dev':
                default:
                    return `https://pages-next-v2-git-pagenextv2-int-spordle.vercel.app`;
            }
        case 'HCR': // SID - HCR
            switch (process.env.REACT_APP_ENVIRONMENT){
                case 'prod':
                    return 'https://hcr3.hockeycanada.ca';
                case 'training':
                    return `https://id.training.hcr.spordle.com`;
                case 'uat':
                    return `https://id.uat.spordle.dev`;
                case 'hcstage':
                    return `https://id.hcstage.spordle.dev`;
                case 'stage':
                    return `https://id.stage.spordle.dev`;
                case 'int':
                    return `https://id.int.spordle.dev`;
                case 'dev':
                default:
                    return `https://id.dev.spordle.dev`;
            }
        case 'SID':
            switch (process.env.REACT_APP_ENVIRONMENT){
                case 'prod':
                    return 'https://id.spordle.com';
                case 'training':
                    return 'https://id.training.hcr.spordle.com';
                case 'uat':
                    return `https://id.uat.spordle.dev`;
                case 'hcstage':
                    return `https://id.hcstage.spordle.dev`;
                case 'stage':
                    return `https://id.stage.spordle.dev`;
                case 'int':
                    return `https://id.int.spordle.dev`;
                case 'dev':
                default:
                    return `https://id.dev.spordle.dev`;
            }
        case 'HCR-SITE':
            switch (process.env.REACT_APP_ENVIRONMENT){
                case 'prod':
                    return 'https://register.hockeycanada.ca';
                case 'training':
                    return `https://hcrsite.training.hcr.spordle.com`;
                case 'uat':
                    return `https://hcrsite.uat.spordle.dev`;
                case 'hcstage':
                    return `https://hcrsite.hcstage.spordle.dev`;
                case 'stage':
                    return `https://hcrsite.stage.spordle.dev`;
                case 'int':
                    return `https://hcrsite.int.spordle.dev`;
                case 'dev':
                default:
                    return `https://hcrsite.dev.spordle.dev`;
            }
        case 'TOUR':
            switch (process.env.REACT_APP_ENVIRONMENT){
                case 'prod':
                    // TODO
                    return 'Some prod url';
                case 'stage':
                default:
                    return 'https://stage.d22wymmaco0ct.amplifyapp.com/auth';
                case 'dev':
                    return 'https://dev.d22wymmaco0ct.amplifyapp.com/auth';
            }
        default:
            break;
    }
}

function getSubDomain(){
    return window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
}

function getDomain(url = window.location.href){
    url = url.replace(/(https?:\/\/)?(www.)?/i, '');

    url = url.split('.');
    url = url[url.length - 2];

    if(!url)
        return '';

    if(url.indexOf('/') !== -1){
        return url.split('/')[0];
    }

    return url;
}

function getFullSubDomain(url = window.location.host){
    const splittedDomain = url.split('.');
    const domain = getDomain(url);
    const buildedSubDomain = [];
    for(let index = 0; index < splittedDomain.length; index++){
        if(splittedDomain[index] === domain){ // Is the domain
            return buildedSubDomain.join('.');
        }
        buildedSubDomain.push(splittedDomain[index]);
    }
    return buildedSubDomain.join('.')
}

export default getReferrer;
export{ getDomain, getFullSubDomain, getSubDomain };