import Skeleton from "react-loading-skeleton";
import { useImgIsLoaded } from "../customHooks/useImgIsLoaded";

const ImgLoader = ({ height = "auto", width = "auto", src, loadingLayout, loadedLayout, className, ...props }) => {
    const [ ref, isLoaded, onLoad ] = useImgIsLoaded();

    return (
        <>
            {!isLoaded && (loadingLayout ?? <Skeleton width={width} height={height} />)}
            <img ref={ref} className={`${isLoaded ? className : 'd-none'}`} src={src} onLoad={onLoad} {...props} />
        </>
    );
}

export default ImgLoader;