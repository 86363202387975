import { DateFormat } from "@spordle/intl-elements";
import moment from "moment";
import styles from "./ClinicSessionTime.module.scss";

const ClinicSessionTime = ({ session }) => {
    return (
        <time dateTime={moment(session?.start_date).toISOString()}>
            <span className={styles.Time}>
                <DateFormat value={session?.start_date} format='YYYY-MM-DD' />
            </span>
            <DateFormat value={session?.start_date} format='hh:mm A' />
        </time>
    );
}

export default ClinicSessionTime;