import { useState } from "react";
import { Container } from "reactstrap";

const CookieWarning = ({ ...props }) => {
    const [ isOpen, setIsOpen ] = useState(false);

    const closeCookie = (e) => {
        e.preventDefault();
        setIsOpen(false);
    }

    return (
        <>
            {isOpen &&
                <div className={`cookie-warning-container py-4`}>
                    <Container className="container-lg">
                        <div className="cookie-warning-inner">
                            <div className="d-flex justify-content-between">
                                <h2>We use cookies.</h2>
                                <button type="button" className="btn btn-link"><i className="mdi-close mdi" /></button>
                            </div>
                            <p>This website uses cookies to ensure you get the best experience.</p>
                            <a onClick={(e) => closeCookie(e)} href="" className="btn btn-primary">Understood</a><a className="btn btn-link" href="">Learn more</a>
                        </div>
                    </Container>
                </div>
            }
        </>
    )
}

export default CookieWarning;