export default{
    "home.noData.hi"            : "Bonjour",
    "home.noData.description.1" : "Bienvenue dans la plateforme d'inscription de tournois!",
    "home.noData.description.2" : "Vous pouvez débuter avec la sélection d'une équipe HCR via l'onglet de tournois",
    "home.noData.action"        : "Inscrire mon équipe",

    "home.teams.title"                      : "Mes équipes",
    "home.teams.seeAll"                     : "Voir tous",
    "home.teams.button.register"            : "Inscription",
    "home.teams.link.avaialbleRegistration" : "Inscription disponible",

    "home.notification.title.notifications" : "Notifications",
    "home.notification.title.registrations" : "Registrations",
    "home.notification.readyToPay"          : "Inscription prête à payer",
    "home.notification.confirmed"           : "Inscription confirmé",
    "home.notification.canceled"            : "Inscription refusé",
    "home.notification.sent"                : "Inscription envoyé",
    "home.notification.price"               : "Prix",
    "home.notification.cancel"              : "Pour annuler une inscription, veuillez communiquer directement avec le tournois",
    "home.notification.markAsRead"          : "Marquer comme lu",
    "home.notification.markedAsRead"        : "Notification marquée comme lue!",
}