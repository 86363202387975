import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { DisplayI18n } from '../../helpers/i18nHelper';

/**
* Badge that will follow api status logic using i18n & color code (0,1,2,3,4,5)
* @param {object} [status]
* @param {React.ComponentType} [icon]
* @example <StatusBadge status={qualification_status} color={color_code}/>
*/
const StatusBadge = ({ status, color, icon }) => {
    // Color codes defined with API (same concept as clinic color but we don't have the classes plugged)
    const getColor = () => {
        switch (parseInt(color)){
            case 0:
                return 'danger' // red
            case 1:
                return 'warning' // yellow
            case 2:
                return 'success' // green
            case 3:
                return 'light' // gray
            case 4:
                return 'info' // blue
            default:
                return 'info'
        }
    }

    return (
        <Badge color={getColor()}>{icon}<DisplayI18n field='name' defaultValue={'-'} i18n={status?.i18n} /></Badge>
    )
};

export default StatusBadge;

StatusBadge.propTypes = {
    status: PropTypes.object,
    color: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    icon: PropTypes.node,
};