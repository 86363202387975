import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import gsap from "gsap";
import Scrollspy from '../../components/Scrollspy';
import { Col, Collapse, Container, Row } from 'reactstrap';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { AxiosIsCancelled } from '../../api/CancellableAPI';

const RenderOrgs = () => {
    const organizationContext = useContext(OrganizationContext)
    const [ orgs, setOrgs ] = useState(null);

    useLayoutEffect(() => {
        if(organizationContext.state.federation?.organisation_id){
            organizationContext.getPartners(organizationContext.state.federation.organisation_id)
                .then(setOrgs)
                .catch((e) => {
                    if(!AxiosIsCancelled(e.message)){
                        console.error(e.message);
                    }
                })
        }
    }, [ organizationContext.state.federation?.organisation_id ])

    return (
        <Collapse isOpen={!!orgs && Array.isArray(orgs)} unmountOnExit>
            {/* other component because we need to useEffect to execute when all the logos are loaded */}
            <section className="py-5 text-center">
                <Container className="px-5 px-md-0">
                    <OrgsDisplay orgs={orgs} />
                </Container>
            </section>
        </Collapse>
    )
}

const OrgsDisplay = ({ orgs }) => {
    const orgRefs = useRef([]);
    const tl = gsap.timeline({ paused: true });

    useEffect(() => {
        tl.staggerFrom(orgRefs.current, 1, { y: -10, opacity: 0, ease: 'power4' }, 0.075)
    }, [])

    return (
        <Scrollspy timeline={tl}>
            <Row className="home-org-row justify-content-center align-items-center">
                {orgs.reduce((newArray, partner, i) => {
                    if(partner.logo){
                        newArray.push(
                            // eslint-disable-next-line react/no-array-index-key
                            <Col key={i} className="my-4 home-org-col" xs="auto">{/* odd number of org makes it look bad with basic bootstrap cols */}
                                <div ref={(ref) => orgRefs.current.push(ref)}>
                                    <a className="hover-opacity" target="_blank" href={partner.website} rel="noopener noreferrer">
                                        <img src={partner.logo.full_path} alt={`${partner.organisation_name}`} width='100' />
                                    </a>
                                </div>
                            </Col>,
                        )
                    }
                    return newArray
                }, [])}
            </Row>
        </Scrollspy>
    )
}

export default RenderOrgs;