import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import App from './app';
import { init as sentryInit, reactRouterV5Instrumentation } from '@sentry/react';
import { ExtraErrorData as ExtraErrorDataIntegration } from "@sentry/integrations";
import { Integrations } from "@sentry/tracing";
import { getI18nPolyfills, externalTranslate } from '@spordle/intl-elements';
import { history } from './helpers';
import { initializeGA } from '@spordle/ga4';
import '@spordle/helpers';
import './assets/scss/style.scss';
import './helpers/yupValidations';// Importing custom Yup validations
import 'react-loading-skeleton/dist/skeleton.css';
import 'moment/locale/en-ca';
import 'moment/locale/fr-ca';
import { setupCustomValidation } from '@spordle/formik-elements';

sentryInit({
    dsn: "https://7c2627959a7746d1941285c2a742fad9@o566818.ingest.sentry.io/5747118",
    integrations: [
        new Integrations.BrowserTracing({ routingInstrumentation: reactRouterV5Instrumentation(history) }),
        new ExtraErrorDataIntegration(),
    ],

    allowUrls: [// Will block errors from external scripts from being logged into Sentry
        'http://localhost',
        /https?:\/\/.*spordle\.(com|dev)/,
        'https://register.hockeycanada.ca',
        'https://inscritpion.hockeycanada.ca',
    ],

    ignoreErrors: [
        'Non-Error promise rejection captured with value: Object Not Found Matching Id:', // This error is caused by Microsoft Outlook SafeLink and is specific to Microsoft Outlook SafeLink
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: process.env.REACT_APP_ENVIRONMENT === 'prod' ? 0.05 : 0, // Disable transaction logging when NOT on prod
    environment: `${process.env.REACT_APP_ENVIRONMENT} - ${window.location.hostname}`,
    enabled: process.env.NODE_ENV !== 'development', // Enabled when not on localhost
    autoSessionTracking: true,
    attachStacktrace: true,
    release: `${process.env.REACT_APP_VERSION} - ${process.env.REACT_APP_ENVIRONMENT}`, // Matches the release from Sentry
    dist: `HCR-Site-${process.env.REACT_APP_GIT_BRANCH}`, // Matches the dist from the released artifacts
    beforeSend: function(event, hint){
        // Checking for CSS and js file load fail
        if(event.exception.values?.findIndex((exception) => exception.type === 'ChunkLoadError') !== -1){
            if(process.env.REACT_APP_ENVIRONMENT !== 'prod'){
                alert(externalTranslate('warning.versionOverlapping'));
            }
            window.location.reload(true);
            return null;// Prevent logging this specific error to Sentry
        }
        return event;
    },
    beforeBreadcrumb: function(breadcrumb, hint){
        if(breadcrumb.category === 'xhr' && hint.xhr){ // Adding reason
            if(!(hint.xhr.status >= 200 && hint.xhr.status < 300)){ // Error
                try{
                    const result = JSON.parse(hint.xhr.responseText);
                    if(result.errors)
                        breadcrumb.data.reason = result.errors;
                }catch{
                    if(hint.xhr.statusText)
                        breadcrumb.data.reason = hint.xhr.statusText;
                }
            }
        }
        return breadcrumb;
    },
});

Promise.all([
    //process.env.REACT_APP_ENVIRONMENT === 'prod' ? initializeGA("G-D6Y5SH1JER") : Promise.resolve(), // Spordle Google Analytics for HCR-Site
    //process.env.REACT_APP_ENVIRONMENT === 'prod' ? initializeGA("G-HRWDVC97KX") : Promise.resolve(), // HCR Google Analytics for HCR-Site
    getI18nPolyfills(),
])
    .catch(console.error)
    .finally(() => {
        // Loading the app only when the polyfills are injected
        ReactDOM.render(
            <App />, document.getElementById('root'),
        );
    });

setupCustomValidation();
