import Translate from '@spordle/intl-elements';
import { Container } from 'reactstrap';
import { I18nContext } from '../../contexts/I18nContext'
import { useContext, useState, useEffect } from 'react';
import { destroyHCRSlots, initializeAdSlots } from '../../components/advertisement/adsHelper';
import AdSlot from '../../components/advertisement/AdSlot';

const Support = () => {
    const [ adIsReady, setAdIsReady ] = useState(false);
    const i18nContext = useContext(I18nContext);

    useEffect(() => {
        initializeAdSlots(i18nContext.getGenericLocale());
        setAdIsReady(true);

        return () => {
            destroyHCRSlots();
        }
    }, []);

    return (
        <main>
            <Container className="pb-5 pt-4">
                <AdSlot
                    adIsReady={adIsReady}
                    adId={i18nContext.getGenericLocale() == "fr" ? "1699036793050" : "1699036745568"}
                    className="d-flex justify-content-center mb-5"
                    minWidth={320}
                    minHeight={50}
                />
                <div className="clearfix">
                    <AdSlot
                        className="float-right ml-4 mb-4"
                        adIsReady={adIsReady}
                        adId={i18nContext.getGenericLocale() == "fr" ? "1699036608579" : "1699036561080"}
                    />
                    <h1><Translate id="header.support" /></h1>
                    <h2 className="mt-4 h4"><Translate id='support.participant.title' /></h2>
                    <p><Translate id='support.participant.text.1' /></p>
                    <I18nContext.Consumer>
                        {({ getGenericLocale }) => (
                            <ul className="list-unstyled d-flex flex-wrap mb-0">
                                <li className="mr-3 mb-3">
                                    <a
                                        className="btn btn-primary" id='support-register'
                                        href={getGenericLocale() === 'en' ? "https://spordle.atlassian.net/wiki/spaces/HDNE/pages/2426011661/Parents+Guide+-+Online+Registration" : "https://spordle.atlassian.net/wiki/spaces/HDF/pages/2439643179/Guide+du+parent+-+Inscription+en+ligne"}
                                        target='_blank' rel='noopener noreferrer'
                                    >
                                        <Translate id='support.participant.button.play' />
                                    </a>
                                </li>
                                <li className="mr-3 mb-3">
                                    <a
                                        className="btn btn-primary" id='support-stages'
                                        href={getGenericLocale() === 'en' ? "https://spordle.atlassian.net/wiki/spaces/HDNE/pages/2569273348/Registration+for+a+clinics" : "https://spordle.atlassian.net/wiki/spaces/HDF/pages/2569568257/Inscription+un+stage"}
                                        target='_blank' rel='noopener noreferrer'
                                    >
                                        <Translate id='support.participant.button.clinic' />
                                    </a>
                                </li>
                                <li className="mr-3 mb-3">
                                    <a
                                        className="btn btn-primary" id='support-myAccount'
                                        href={getGenericLocale() === 'en' ? "https://spordle.atlassian.net/wiki/spaces/HDNE/pages/2419949575/My+Account" : "https://spordle.atlassian.net/wiki/spaces/HDF/pages/2439282699/Mon+Compte"}
                                        target='_blank' rel='noopener noreferrer'
                                    >
                                        <Translate id='support.participant.button.myAccount' />
                                    </a>
                                </li>
                            </ul>
                        )}
                    </I18nContext.Consumer>
                    <p className="mb-0"><Translate id='support.participant.text.2' /></p>
                    <h2 className="mt-5 h4"><Translate id='support.admin.title' /></h2>
                    <p className="mb-0"><Translate id='support.admin.text' values={{ email: <a href="mailto: hcr@spordle.com" id='mail-support'>hcr@spordle.com</a> }} /></p>
                </div>
            </Container>
        </main>
    );
}

export default Support;