import { useContext, useMemo } from "react";
import { useSpordleTable } from "@spordle/datatables";
import { DisplayPhoneNumber } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { stringifyUrl } from "query-string";
import { Badge } from "reactstrap";
import UserImg from "../../../components/UserImg";
import { AuthContext } from "../../../contexts/AuthContext";
import { I18nContext } from "../../../contexts/I18nContext";
import { DisplayI18n } from "../../../helpers/i18nHelper";

const RegisterCard = ({ org }) => {
    const { generateId } = useSpordleTable();
    const { accessToken, pageUrl } = useContext(AuthContext);
    const { getGenericLocale } = useContext(I18nContext);
    const { isOpen } = org.online_stores.reduce((data, onlineStore) => {
        if(onlineStore.online_store_status.toLowerCase() === "active"){
            data.isOpen = true;
        }

        return data;
    }, { isOpen: false });

    const orgPageUrl = useMemo(() => {
        try {
            // The short name should be the same as the org's Page short name.
            // Might differ on test environments.
            return new URL(`${org?.short_name}`, pageUrl).href;
        } catch(e) {
            console.error(e);
            return "";
        }
    }, [ org?.short_name, pageUrl ]);

    const getLocationName = (org) => {
        const arr = [];

        if(org.address) arr.push(org.address);
        if(org.city) arr.push(org.city);
        if(org.zip_code) arr.push(org.zip_code);
        if(org.province_code) arr.push(org.province_code);
        if(org.country_code) arr.push(org.country_code);

        return arr.join(', ')
    }

    return (
        <section className="register-result h-100">
            <header
                className="h6 register-result-header"
            >
                <span className="d-flex align-items-start register-result-header-inner">
                    <UserImg
                        className="shadow-none mr-2"
                        src={org.logo?.full_path}
                        abbr={org.abbreviation || org.organisation_name}
                        alt={org.organisation_name}
                        filePos={org.logo?.file_position}
                        width={60}
                    />
                    <h3 className="register-result-title ml-2">
                        <DisplayI18n
                            field="name"
                            i18n={org.i18n}
                            defaultValue={org.organisation_name}
                        />
                    </h3>
                </span>
            </header>
            <address className="px-3">
                {!!org.exact_match && <div className="text-truncate small"><i className="mdi mdi-check-circle" /> <Translate id="register.search.result.exactMatch" /></div>}
                <div className="text-truncate small"><i className="mdi mdi-map-marker mr-1" />{getLocationName(org)}</div>
                <div className="text-truncate small"><i className="mdi mdi-map-marker-radius mr-1" />{org.distance === null ? <Translate id="register.search.result.distanceNoInfo" /> : <Translate id="register.search.result.distance" values={{ distance: org.distance }} />}</div>
                {(org.phone) && <div className="text-truncate small"><i className="mdi mdi-phone mr-1" /><DisplayPhoneNumber phoneString={org.phone} format="INTERNATIONAL" /></div>}
                {(org.email) && <div className="text-truncate small"><i className="mdi mdi-email mr-1" />{org.email}</div>}
            </address>
            <footer className="p-3 border-top mt-auto d-flex justify-content-between">
                {isOpen ?
                    <Badge color="success"><Translate id="register.search.result.registrationOpen" /></Badge>
                    :
                    <Badge color="success"><Translate id="register.search.result.registrationClosed" /></Badge>
                }
                <a
                    className="register-result-stretched-link mdi mdi-launch"
                    href={stringifyUrl({
                        url: orgPageUrl,
                        query: {
                            lang: getGenericLocale(),
                            accessToken: accessToken,
                        },
                    }, {
                        skipNull: true,
                        skipEmptyString: true,
                    })}
                    rel="noopener noreferrer"
                    target="_blank"
                    id={generateId('org', org.organisation_id)}
                />
                {isOpen &&
                    <span className="small">
                        {org.min_age && org.max_age ?
                            <Translate id='register.search.result.minMaxAge' values={{ minAge: org.min_age, maxAge: org.max_age }} />
                            : org.min_age ?
                                <Translate id='register.search.result.minAge' values={{ age: org.min_age }} />
                                : org.max_age ?
                                    <Translate id='register.search.result.minAge' values={{ age: org.max_age }} />
                                    : null
                        }
                    </span>
                }
            </footer>
        </section>
    );
}

export default RegisterCard;