export default{
    "register.title.find"         : "Find an",
    "register.title.organisation" : "Organization",

    "register.search.result.exactMatch"         : "Exact match",
    "register.search.result.distance"           : "~{distance}km away",
    "register.search.result.distanceNoInfo"     : "No distance info",
    "register.search.result.registrationClosed" : "Contact us",
    "register.search.result.registrationOpen"   : "Registration open",
    "register.search.result.minAge"             : "{age} years and older",
    "register.search.result.maxAge"             : "{age} years and younger",
    "register.search.result.minMaxAge"          : "{minAge} to {maxAge} y/o",

    "register.search.result.empty.title"    : "No organization found",
    "register.search.result.empty.subtitle" : "We couldn't find any organization.",

    "register.search.postalCode.placeholder"   : "Postal Code",
    "register.search.provinceCode.placeholder" : "Select your province",
    "register.search.result.init.title"        : "Search",
    "register.search.result.init.subtitle"     : "Use the top search to find your organization.",

    "register.search.result.helper" : "If you need help selecting the correct MHA, please contact them directly using the contact information below. Please note the registration information you enter will be confirmed by the selected MHA in order to be finalized.",
}